import React, { useEffect, useState } from "react";
import {
  giveFeedback,
  releasePaymentAction,
  contractGetbyId
} from "./../../actions/proposalAndContract";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PaymentRequest , PaymentRelease} from "../../actions/enterprise";

import PropTypes from "prop-types";
import { Country } from "country-state-city";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactStars from "react-rating-stars-component";
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import ReleasePayment from "./releasePayment";
import { getWallet } from "../../actions/wallet";
import {
  messageAdd,
  proposalGetByClient,
  revokeMilestone,
} from "../../actions/proposalAndContract";
import EndContract from "./endContract";
import { Helmet } from "react-helmet";
import AddMilestone from "./addMilestone";
import MilestoneDetails from "./milestoneDetails";
import { remove } from "react-icons-kit/fa/remove";
import { Icon } from "react-icons-kit";

import { setAlert } from "../../actions/alert";

import DisputeOffcanvas from "./disputecanvas";
import { Offcanvas } from "react-bootstrap";

const ProjectDetails = ({
  auth: { isAuthenticated, user },
  proposalAndContract: { ProposalsGetByClient, ContractData },
  proposalGetByClient,
  revokeMilestone,
}) => {

  let dispatch = useDispatch();
  const homepage_query = useLocation();

  const wallet = useSelector((state) => state.wallet);

  const [formData, setFormData] = useState({
    priceRange: "",
    location: "United Kingdom",
  });

  const [showDisputeOffcanvas, setShowDisputeOffcanvas] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(null);

  const [showReview, setShowReview] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [satisfactionRating, setSatisfactionRating] = useState(0);
  const [projectDeliveryRating, setProjectDeliveryRating] = useState(0);

  const [paymentConfirmation, setPaymentConfirmation] = useState(false);
  const [showEndContract, setShowEndContract] = useState(false);
  const [addMilestone, setAddMilestone] = useState(false);
  const [showMilestoneDetail, setShowMilestoneDetail] = useState(false);

  const [selectedMilestoneId, setSelectedMilestoneId] = useState("");
  const [selectedMilestone, setSelectedMilestone] = useState(null);


  const handleClosePaymentConfirmation = () => {
    setPaymentConfirmation(false);
  };
  const handleOpenPaymentConfirmation = () => {
    setPaymentConfirmation(true);
  };

  const paymentReleaseEnterprise = () => {
    dispatch(PaymentRequest(ContractData._id , user.email));
  };

  const adminPaymentReleaseEnterprise = () => {
    dispatch(PaymentRelease(ContractData._id));
  };



  const handleCloseEndContract = () => {
    setShowEndContract(false);
  };
  const handleOpenEndContract = () => {
    setShowEndContract(true);
  };

  const handleCloseReview = () => {
    setShowReview(false);
  };
  const handleShowReview = () => {
    setShowReview(true);
  };

  const handleOpenAddMilestone = () => {
    setAddMilestone(true);
  };
  const handleCloseAddMilestone = () => {
    setAddMilestone(false);
  };

  const handleOpenMilestoneDetail = (milestone) => {
    setSelectedMilestone(milestone);
    setShowMilestoneDetail(true);
  };
  const handleCloseMilestoneDetail = (milestone) => {
    setSelectedMilestone(null);
    setShowMilestoneDetail(false);
  };

  const handleProjectDeliveryRatingChange = (newRating) => {
    setProjectDeliveryRating(newRating);
  };
  const handleSatisfactionRatingChange = (newRating) => {
    setSatisfactionRating(newRating);
  };

  const handleSubmitFeedback = async () => {
    setLoading(true);
    await dispatch(
      giveFeedback(
        ContractData._id,
        feedbackText,
        satisfactionRating,
        projectDeliveryRating
      )
    );
    dispatch(getWallet());
    handleCloseReview();
    setLoading(false);
  };

  const { priceRange, location } = formData;

  const onChangeVal = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getWallet());
    if (user) {
      proposalGetByClient(user, () => {
        if (ProposalsGetByClient.length > 0) {
          document
            .getElementById(ProposalsGetByClient.reverse()[0]._id)
            ?.click();
        }
      });
    }
  }, [user._id, ProposalsGetByClient, dispatch]);

  const searchParams = new URLSearchParams(homepage_query.search);
  const storedValue = searchParams.get("value");

  useEffect(() => {
    if (storedValue === "release-payment") {
      if (ContractData.contract_status === "Accepted") {
        setSelectedMilestoneId("");
        handleOpenPaymentConfirmation();
      } else {

        const relevantMilestone = [...ContractData?.fixed_contract?.milestones]
          ?.reverse()
          .find((m) => m.status === "Active" && user.type === "Client");

        if (relevantMilestone) {
          setSelectedMilestoneId(relevantMilestone._id);
          handleOpenPaymentConfirmation();
        }
      }
    }
  }, [storedValue, ContractData, user.type]);

  /*if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }*/

  if (!ContractData) {
    return <div>Loading...</div>;
  }


  const jobID = ContractData?.job_id?._id;


  const filteredProposals = Array.isArray(ProposalsGetByClient)
    ? ProposalsGetByClient.filter(
        (proposal) => proposal.job_id && proposal.job_id._id === jobID
      )
    : [];

  
  //console.log("ContractData is :", ContractData);

  const freelancerID = ContractData.freelancer_id._id;

  const filteredProposalsByFreelancer = filteredProposals.filter(
    (proposal) => proposal.freelancer_id && proposal.freelancer_id._id === freelancerID
  );

  //console.log("Filtered Proposals by Freelancer ID:", filteredProposalsByFreelancer);

  const handleRequestPaymentClick = () => {
    const messageData = {
      freelancer_id: ContractData.freelancer_id._id,
      hire_manager_id: ContractData.hire_manager_id._id,
      user_name: user.user_name,
      message_text:
        'Take action regarding "Request payment release from escrow"',
      message_type: "systext",
      message_file_properties: {},
      proposal_id: filteredProposalsByFreelancer[0].proposal_id,
      proposal_catalog_status_id: "6173d8be9b109e2dd8457268",
      user: user,
    };

    dispatch(messageAdd(messageData));
    dispatch(setAlert("Request sent", "success"));
  };

  const handleTerminateContractClick = () => {
    //console.log("filteredProposals is:",filteredProposals)
    const messageData = {
      freelancer_id: ContractData.freelancer_id._id,
      hire_manager_id: ContractData.hire_manager_id._id,
      user_name: user.user_name,
      message_text:
        'Take action regarding "Request payment refund from escrow"',
      message_type: "systext",
      message_file_properties: {},
      proposal_id: filteredProposalsByFreelancer[0].proposal_id,
      proposal_catalog_status_id: "6173d8be9b109e2dd8457268",
      user: user,
    };

    dispatch(messageAdd(messageData));
    dispatch(setAlert("Request sent", "success"));
  };

  return (
    <section className="main-page page-dashboard page-contract-details">
      <Helmet>
        <title>Contract Details</title>
      </Helmet>
      <div className="container">
        <ReleasePayment
          contractId={ContractData._id}
          handleClose={handleClosePaymentConfirmation}
          show={paymentConfirmation}
          contractType={ContractData.contract_type}
          contractValue={
            ContractData.contract_type === "Fixed"
              ? ContractData.fixed_contract?.fixed_price
              : ContractData.hourly_contract?.hourly_rates
          }
          milestoneId={selectedMilestoneId}
          walletAmount={wallet?.walletAmount || 0}
        />

        <EndContract
          contractId={ContractData._id}
          handleClose={handleCloseEndContract}
          show={showEndContract}
          contractType={ContractData.contract_type}
        />

        <AddMilestone
          contractId={ContractData._id}
          handleClose={handleCloseAddMilestone}
          show={addMilestone}
          walletAmount={wallet?.walletAmount || 0}
        />

        <MilestoneDetails
          milestone={selectedMilestone}
          handleClose={handleCloseMilestoneDetail}
          show={showMilestoneDetail}
        />

        <div className="row">
          <div className="col-lg-4 left">
            <div className="profile_info bg-white rounded shadow-sm">
              <div className="seller-card">
                <div className="clearfix">
                  <div
                    className="user-online-indicator is-online "
                    data-user-id="1152855"
                  >
                    <i className="fa fa-circle"></i>online
                  </div>
                  <Link to="" className="ambassadors-badge">
                    {user.type}
                  </Link>
                </div>
                <div className="user-profile-info">
                  <div className="user-profile-image">
                    <label className="user-pict rounded-circle">
                      <Link to="" className="user-badge">
                        <h3 className="text-uppercase">
                          {user.first_name.slice(0, 1)}
                          {user.last_name.slice(0, 1)}
                        </h3>
                      </Link>
                    </label>
                  </div>

                  <div className="user-profile-label">
                    <div className="username-line">
                      <Link to="" className="seller-link">
                        {user.first_name} {user.last_name}
                      </Link>
                    </div>
                    <div className="oneliner-wrapper">
                      <div className="ratings-wrapper">
                        {/* <p className="rating-text"><strong>5.0</strong> (1k+ reviews)</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                    <div className="p-4">
                        <div className="border-bottom pb-4">
                            <div className="d-flex align-items-center py-1">
                                Response Rate
                                <div className="font-weight-bold ml-auto d-flex align-items-center">
                                    <span className="btn btn-primary border-0 py-1 px-4 mr-2"></span> 100%
                                </div>
                            </div>
                            <div className="d-flex align-items-center py-1">
                                Delivered on Time
                                <div className="font-weight-bold ml-auto d-flex align-items-center">
                                    <span className="btn btn-primary border-0 py-1 px-4 mr-2"></span> 100%
                                </div>
                            </div>
                            <div className="d-flex align-items-center py-1">
                                Order Completion
                                <div className="font-weight-bold ml-auto d-flex align-items-center">
                                    <span className="btn btn-primary border-0 py-1 px-4 mr-2"></span> 100%
                                </div>
                            </div>
                        </div>

                        <div className="pt-3">
                            <div className="d-flex align-items-center py-1 h6">
                                Earned in March
                                <div className="font-weight-bold ml-auto d-flex align-items-center">
                                    $2334
                                </div>
                            </div>
                            <div className="d-flex align-items-center py-1 h6">
                                Response Time
                                <div className="font-weight-bold ml-auto d-flex align-items-center">
                                    N/A
                                </div>
                            </div>
                        </div>
                    </div>
                      */}
              </div>
            </div>

            <Modal
              show={showReview}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={handleCloseReview}
              size="lg"
            >
              <Modal.Header>
                <Modal.Title>Review Form</Modal.Title>
                <Icon
                  className="mx-2 icon-hover"
                  icon={remove}
                  onClick={handleCloseReview}
                />
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        <span className="font-weight-bold">
                          Was the project delivered on time:
                        </span>
                      </p>
                      <p>
                        <ReactStars
                          count={5}
                          onChange={handleProjectDeliveryRatingChange} // Update the callback function to handle the rating change
                          size={24}
                          activeColor="#ffd700"
                        />
                      </p>
                      <p>
                        <span className="font-weight-bold">
                          How satisfied were you with the end product:
                        </span>
                      </p>
                      <p>
                        <ReactStars
                          count={5}
                          onChange={handleSatisfactionRatingChange} // Update the callback function to handle the rating change
                          size={24}
                          activeColor="#ffd700"
                        />
                      </p>
                      <p>
                        <span className="font-weight-bold">
                          Enter your Feedback:
                        </span>
                      </p>
                      <br></br>
                      <p>
                        <textarea
                          className="p-2 form-control"
                          name=""
                          rows="10"
                          placeholder="Enter your Feedback here"
                          value={feedbackText} // Update the value of the textarea to capture the feedback text
                          onChange={(e) => setFeedbackText(e.target.value)} // Update the callback function to handle the feedback text change
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleSubmitFeedback}
                  disabled={loading}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="col-lg-8 right">
            <section className="panel-box mb-4">
              <header className="panel-box--header">
                <h1 className="panel-box--title">Contract Details</h1>
              </header>
              <main className="panel-box--body p-4">
                {/* <h5 className="h5">Contract Details</h5>

                            <h5 className="h5">Freelancer Details</h5>

                            <h5 className="h5">Client Details</h5>

                            <h5 className="h5">Job Details</h5>

                            <h5 className="h5">Contract Progress</h5> */}

                <div className="row mb-3">
                  <div className="col-sm-2">
                    <label htmlFor="contractType" className="col-form-label">
                      Type
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="contractType"
                      value={ContractData.contract_type}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-2">
                    <label
                      htmlFor="contractInprogress"
                      className="col-form-label"
                    >
                      Status
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="contractInprogress"
                      value={ContractData.contract_status}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-2">
                    <label
                      htmlFor="contractStartingDate"
                      className="col-form-label"
                    >
                      Starting Date
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="contractStartingDate"
                      value={ContractData.createdAt}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2">
                    <label htmlFor="contractDueDate" className="col-form-label">
                      Due Date
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="contractDueDate"
                      value={
                        ContractData?.fixed_contract
                          ? ContractData?.fixed_contract?.due_date
                          : ContractData?.hourly_contract?.due_date
                      }
                      disabled
                    />
                  </div>
                </div>
              </main>
            </section>

            <section className="panel-box mb-4">
              <header className="panel-box--header">
                <h1 className="panel-box--title">Hiring Manager</h1>
              </header>
              <main className="panel-box--body p-4">
                <div className="row mb-3">
                  <div className="col-sm-2">
                    <label
                      htmlFor="contractManagerName"
                      className="col-form-label"
                    >
                      Name
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="contractManagerName"
                      value={`${ContractData?.hire_manager_id?.first_name} ${ContractData?.hire_manager_id?.last_name}`}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-2">
                    <label
                      htmlFor="contractManagerVerified"
                      className="col-form-label"
                    >
                      Approval
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="contractManagerVerified"
                      value={ContractData?.hire_manager_id?.approval}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-2">
                    <label
                      htmlFor="contractSpending"
                      className="col-form-label"
                    >
                      Email
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="contractSpending"
                      value={ContractData?.hire_manager_id?.email}
                      disabled
                    />
                  </div>
                </div>
              </main>
            </section>
           
            {ContractData?.hire_manager_id ? (
              <section className="panel-box mb-4">
                <header className="panel-box--header">
                  <h1 className="panel-box--title">Expert</h1>
                </header>
                <main className="panel-box--body p-4">
                  <div className="row mb-3">
                    <div className="col-sm-2">
                      <label
                        htmlFor="contractFreelancerName"
                        className="col-form-label"
                      >
                        Name
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="contractFreelancerName"
                        value={`${ContractData?.freelancer_id?.user_id?.first_name} ${ContractData?.freelancer_id?.user_id?.last_name}`}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-2">
                      <label
                        htmlFor="contractFreelancerVerified"
                        className="col-form-label"
                      >
                        Approval
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="contractFreelancerVerified"
                        value={ContractData?.freelancer_id?.user_id?.approval}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-2">
                      <label
                        htmlFor="contractFreelancerEarning"
                        className="col-form-label"
                      >
                        Email
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="contractFreelancerEarning"
                        value={ContractData?.freelancer_id?.user_id?.email}
                        readOnly
                      />
                    </div>
                  </div>
                </main>
              </section>
            ) : (
              <p>Loading...</p>
            )}
            {user.type !== "Enterprise Client" ? 
            <section className="panel-box mb-4">
              <header className="panel-box--header">
                <h1 className="panel-box--title">Payment Details</h1>
              </header>
              <main className="panel-box--body p-4">
                <table className="table mb-4">
                  <thead>
                    <tr>
                      <th colSpan={3}>
                        {ContractData.contract_status === "Finished" ||
                        ContractData.contract_status === "Active"
                          ? "Payment Submitted"
                          : "Pending"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ContractData.contract_type === "Fixed" && (
                      <>
                        <tr key="initial">
                          <td colSpan={2}>
                            <label
                              htmlFor="pending1"
                              className="col-form-label"
                            >
                              ${ContractData.fixed_contract?.fixed_price}
                            </label>
                          </td>
                          <td>
                            {ContractData.contract_status === "Finished" ||
                            ContractData.contract_status === "Active"
                              ? "Payment released to expert"
                              : "Pending"}
                          </td>
                        </tr>
                        {ContractData?.fixed_contract?.milestones?.map(
                          (milestone, i) => (
                            <tr key={i}>
                              <td>
                                <label
                                  htmlFor="pending1"
                                  className="col-form-label"
                                >
                                  ${milestone.value}
                                </label>
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    handleOpenMilestoneDetail(milestone)
                                  }
                                  className="btn btn-primary btn-sn mx-1"
                                  disabled={loading}
                                >
                                  Details
                                </button>
                              </td>
                              <td>
                                {milestone.status === "Completed" ? (
                                  "Payment released to expert"
                                ) : milestone.status === "Revoked" ? (
                                  "Payment refunded back to client wallet"
                                ) : user.type === "Client" ? (
                                  <>
                                    <button
                                      onClick={() => {
                                        setSelectedMilestoneId(milestone._id);
                                        handleOpenPaymentConfirmation();
                                      }}
                                      className="btn btn-primary btn-sn mx-1"
                                      disabled={loading}
                                    >
                                      Payment Release
                                    </button>

                                    <button
                                      onClick={() =>
                                        revokeMilestone(ContractData._id)
                                      }
                                      className="btn btn-danger btn-sn mx-1"
                                      disabled={
                                        !ContractData.fixed_contract.milestones[
                                          ContractData.fixed_contract.milestones
                                            .length - 1
                                        ].isAllowed
                                      }
                                    >
                                      Get Refund
                                    </button>
                                  </>
                                ) : (
                                  "Pending"
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    )}

                    {ContractData.contract_type === "Hourly" &&
                      ContractData?.hourly_contract?.payments?.map(
                        (payment, i) => (
                          <tr key={i}>
                            <td colSpan={2}>
                              <label
                                htmlFor="pending1"
                                className="col-form-label"
                              >
                                ${payment.value}
                              </label>
                            </td>
                            <td>Payment released to expert</td>
                          </tr>
                        )
                      )}

                    <tr>
                      <td>    
                      <label htmlFor="pending1" className="col-form-label">
                      {ContractData.contract_type === "Fixed" ? (
                        <span>
                          Amount paid: ${ContractData?.fixed_contract?.total_payment || 0}
                        </span>
                      ) : (
                        ContractData.contract_type === "Enterprise" ? (
                          <span>
                            Payment amount: ${ContractData?.admin_enterprised_amount}
                          </span>
                        ) : (
                          <span>
                            Payment amount: ${ContractData?.hourly_contract?.hourly_rates}/hr
                          </span>
                        )
                      )}
                      </label>
                      </td>
                      <td>
                        {user.type === "Client" &&
                          ["Active", "Finished"].indexOf(
                            ContractData?.contract_status
                          ) >= 0 &&
                          ContractData?.review?.length < 1 && (
                            <button
                              onClick={handleShowReview}
                              className="btn btn-primary btn-sn mx-1"
                            >
                              Give Feedback
                            </button>
                          )}

                        {user.type === "Client" &&
                          (ContractData.contract_type === "Fixed" ? (
                            ContractData.contract_status === "Active" ? (
                              <>
                                <button
                                  onClick={handleOpenAddMilestone}
                                  className="btn btn-primary btn-sn mx-1 mt-2"
                                  disabled={loading}
                                >
                                  Add Milestone
                                </button>
                                <tr>
                                  <button
                                    onClick={handleOpenEndContract}
                                    className="btn btn-secondary btn-sn mx-1 mb-2"
                                    disabled={loading}
                                    style={{ marginTop: "10px" }}
                                  >
                                    End Contract
                                  </button>
                                </tr>
                              </>
                            ) : ContractData.contract_status === "Accepted" ? (
                              <button
                                onClick={() => {
                                  setSelectedMilestoneId("");
                                  handleOpenPaymentConfirmation();
                                }}
                                className="btn btn-primary btn-sn mx-1"
                                disabled={loading}
                              >
                                Payment Release
                              </button>
                            ) : (
                              ""
                            )
                          ) : ContractData.contract_status === "Active" ? (
                            <>
                              <button
                                onClick={handleOpenPaymentConfirmation}
                                className="btn btn-primary btn-sn mx-1"
                                disabled={loading}
                              >
                                Payment Release
                              </button>
                              <button
                                onClick={handleOpenEndContract}
                                className="btn btn-secondary btn-sn mx-1"
                                disabled={loading}
                              >
                                End Contract
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={handleOpenPaymentConfirmation}
                              className="btn btn-primary btn-sn mx-1"
                              disabled={
                                loading ||
                                ContractData.contract_status !== "Accepted"
                              }
                            >
                              Payment Release
                            </button>
                          ))}
                        {/*
                                    <button className="btn btn-primary btn-sm mr-2">Normal</button>
                                    <button className="btn btn-primary btn-sm mr-2">Max</button>
                                    */}
                      </td>
                      <td>
                        {ContractData.contract_type === "Fixed" && (
                          <>
                            {ContractData?.contract_status === "Finished" &&
                              "Payment released to expert"}
                            {(ContractData?.contract_status === "Accepted" ||
                              ContractData?.contract_status === "Pending") &&
                              "Payment in Escrow"}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/*
                            <table className="table table-bordered mb-4">
                                <tr>
                                    <th colSpan={2}>
                                        Approved
                                    </th>
                                </tr>
                                <tr>
                                    <td><label htmlFor="pending1" className="col-form-label">$ 200</label></td>
                                    <td>
                                        <button className="btn btn-primary btn-sm mr-2">Min</button>
                                        <button className="btn btn-primary btn-sm mr-2">Normal</button>
                                        <button className="btn btn-primary btn-sm mr-2">Max</button>
                                    </td>
                                </tr>
                        </table> */}

                {/* <table className="table table-bordered mb-4">
                                <tr>
                                    <th colSpan={2}>
                                        Disputed
                                    </th>
                                </tr>
                                <tr>
                                    <td><label htmlFor="pending1" className="col-form-label">$ 200</label></td>
                                    <td>
                                        <button className="btn btn-primary btn-sm mr-2">Min</button>
                                        <button className="btn btn-primary btn-sm mr-2">Normal</button>
                                        <button className="btn btn-primary btn-sm mr-2">Max</button>
                                    </td>
                                </tr>
                        </table>*/}
              </main>
            </section> 
            :
            <button
              onClick={paymentReleaseEnterprise}
              className="btn btn-primary btn-sn mx-1 mb-4"
              disabled={
                loading ||
                ContractData.contract_status !== "Accepted"
              }
            >
              Payment Release
            </button>}

            {user.type === "Admin" ? <button
              onClick={adminPaymentReleaseEnterprise}
              className="btn btn-primary btn-sn mx-1 mb-4"
            >
              Payment Release
            </button> : null}
            
            
            {ContractData.contract_type !== "Enterprise" && (
              <div className="text-right">
                {user.type === "Client" && (
                  <button
                    type="submit"
                    className="btn btn-primary btn-sn mx-1 custom-button"
                    onClick={handleTerminateContractClick}
                    disabled={
                      // If it's a fixed contract
                      (ContractData.fixed_contract &&
                        (!ContractData.fixed_contract.milestones.length ||
                          ContractData.fixed_contract.milestones[
                            ContractData.fixed_contract.milestones.length - 1
                          ].status !== "Active")) ||
                      // If it's an hourly contract
                      (ContractData.hourly_contract &&
                        ContractData.hourly_contract.isAllowed === false)
                    }
                  >
                    Request payment refund from escrow
                  </button>
                )}

                {user.type === "Freelancer" && (
                  <button
                    type="submit"
                    className="btn btn-primary btn-sn mx-1 custom-button"
                    disabled={
                      // If it's a fixed contract
                      (ContractData.fixed_contract &&
                        (ContractData.fixed_contract.milestones.length === 0 ||
                          ContractData.fixed_contract.milestones[
                            ContractData.fixed_contract.milestones.length - 1
                          ].status !== "Active")) ||
                      // If it's an hourly contract
                      (ContractData.hourly_contract &&
                        ContractData.hourly_contract.isAllowed === false)
                    }
                    onClick={handleRequestPaymentClick}
                  >
                    Request payment release from escrow
                  </button>
                )}

                {(user.type === "Client" || user.type === "Freelancer") && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-sn mx-1 custom-button"
                      onClick={() => setShowDisputeOffcanvas(true)}
                    >
                      Disputes
                    </button>

                    <DisputeOffcanvas
                      show={showDisputeOffcanvas}
                      onHide={() => setShowDisputeOffcanvas(false)}
                      contractData={ContractData}
                      user={user}
                      filteredProposals={filteredProposals}
                    />
                  </>
                )}
              </div>
)}

            <section className="panel-box mb-4">
              <header className="panel-box--header">
                <h1 className="panel-box--title">Job Details</h1>
              </header>
              <main className="panel-box--body p-4">
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label className="col-form-label">Job Headline</label>
                  </div>
                  <div className="col-sm-8">
                    <p>{ContractData?.job_id?.headline}</p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label className="col-form-label">Description</label>
                  </div>
                  <div className="col-sm-8">
                    <p>{ContractData?.job_id?.description}</p>
                  </div>
                </div>
                {/*
                        <div className="row mb-3">
                            <div className="col-sm-4">
                                <label className="col-form-label">Hourly Work Log</label>
                            </div>
                            <div className="col-sm-8">
                                <p>12H 26M</p>
                            </div>
                    </div>
                            <div className="table-responsive">
                                <table className="table table-bordered mb-4">
                                    <tr>
                                        <th>Description</th>
                                        <th>$</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Status</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        </td>
                                        <td>
                                            456
                                        </td>
                                        <td>
                                            Tuesday 2/01/2022 - 12:00
                                        </td>
                                        <td>
                                            Monday 15/02/2022 - 16:00
                                        </td>
                                        <td>
                                            Completed
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            */}
              </main>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};
ProjectDetails.propTypes = {
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  proposalAndContract: state.proposalAndContract,
});

export default connect(mapStateToProps, {
  proposalGetByClient,
  revokeMilestone,
})(ProjectDetails);
