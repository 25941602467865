import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Alert from '../../components/alert/Alert';
import { addProfileAndPhoto } from "../../actions/freelancerProfile";
import axios from 'axios';
import { uploadFile } from "../../actions/file-crud";
import { Helmet } from "react-helmet";
import { KeyboardBackspaceOutlined } from "@material-ui/icons";




const ProfileAndPhoto = ({ freelancerProfile: { profileAndPhoto, freelancerprofile }, setAlert, addProfileAndPhoto, isAuthenticated }) => {

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();


  const [formData, setFormData] = useState({
    photo_link: ""
  });

  const { photo_link } = formData;
  //const [photo, setPhoto] = useState(null); 


  useEffect(() => {
    let check = JSON.parse(localStorage.getItem('profileAndPhoto'));
    //console.log(check)
    if (check) {
      setFormData(profileAndPhoto);
    }

  }, []);


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = async (e) => {

    //setPhoto(e.target.files[0]); // set the photo state variable when the file input is changed
    debugger;
    //console.log(e.target.files[0]);
    const formData = new FormData();
    if (e.target.files[0].name.toLowerCase().endsWith('.png') || e.target.files[0].name.toLowerCase().endsWith('.jpg') || e.target.files[0].name.toLowerCase().endsWith('.jpeg')) {

      formData.append('file', e.target.files[0]);
      Promise.all([setLoading(true), dispatch(uploadFile(formData))]).then(async (res) => {
        //console.log(res[1].location);
        localStorage.setItem('photo_link', JSON.stringify(res[1].location));

      })

    } else {
      setAlert("File is not a PNG, JPG, or JPEG", "danger", 3000);
      //console.log('File is not a PNG, JPG, or JPEG');
    }

  }

  let history = useHistory();


  const onSubmit = async (e) => {
    e.preventDefault();
    addProfileAndPhoto({
      photo_link: JSON.parse(localStorage.getItem('photo_link'))
    });

    history.push("/education");
  };


  if (!isAuthenticated) {
    return <Redirect to="/login-register?role=client&type=login" />;
  }
  if (typeof freelancerprofile === "string") {
    return <Redirect to="/expert-email-verify" />;
  }

  return (
    <section className="main-page page-dashboard">
      <Helmet>
        <title>Profile Picture</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
            <div className="bg-white rounded shadow-sm sidebar-page-right">
            
              <div className="p-5">
              <Link to={`/titleandoverview`} className=" text-dark d-flex mb-2"
                style={{ alignItems: "center", gap: "3px" }}
              >
                <KeyboardBackspaceOutlined />  Back

              </Link>
                <h3 className="text-primary">Profile & Photos</h3>
                <p className="font-weight-bold">07 of 10</p>
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="scrollable">

                    <p className="mb-4 mt-4">Please upload a professional portrait that clearly shows your face.</p>
                    <div className="row d-flex align-items-center form-group">
                      {/*<div className="col-md-12 col-12">
                                      <p className="text-muted font-weight-bold">Add Profile Photo Link </p>
                                      
                                  </div>
                                  
                                   <div className="col-md-8 col-12">
                                      <input type="file" className="form-control" />
                                      <input type="text" className="form-control"
                                      name="photo_link"
                                      value={photo_link}
                                      onChange={(e) => onChange(e)} />
                                  </div>
                                  <div className="col-md-12 col-12">
                                      <p className="text-muted font-weight-bold mb-2"></p>
                                  </div>
                                  <div className="col-md-12 col-12">
                                      <p className="text-muted mb-2">OR</p>
                                  </div> */}
                      <div className="col-md-12 col-12">
                        <p className="text-muted font-weight-bold">Upload Profile Photo</p>
                        <Alert />
                      </div>

                      <div className="col-md-12 col-12">
                        <input type="file" className="form-control" onChange={handleFileChange} />
                      </div>

                    </div>
                  </div>

                

                  <div className="row justify-content-center p-2">
                    <div className="col-md">
                      {/*<Link to="/certificate" className="btn btn-normal">Skip this step</Link>*/}
                    </div>
                    <div className="text-right wm-100">
                      <button type="submit" className="btn btn-primary wm-100" >Save & Next</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProfileAndPhoto.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile
});


export default connect(mapStateToProp, { setAlert, addProfileAndPhoto })(ProfileAndPhoto);