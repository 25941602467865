import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { filter } from "react-icons-kit/fa/filter";
import { Country } from "country-state-city";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputRange from "react-input-range";
import {
  freelancerAllGet_ver,
  freelancerGetbyId,
  verifyFreelancer,
} from "../../actions/freelancerProfile";
import { Helmet } from "react-helmet";
import { remove } from "react-icons-kit/fa/remove";

import Skeleton from "./../../components/skeleton";

const Verification = ({
  auth: { isAuthenticated, user },
  freelancerProfile: { freelancerList, freelancerprofile, isLoaded },
  freelancerGetbyId,
  verifyFreelancer,
}) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [profileTalentData, setProfileTalentData] = useState(null);
  const [inviteModal, setInviteModal] = useState(false);
  const [hireModal, setHireModal] = useState(false);
  const [selectedfreelancer, setSelectedfreelancer] = useState(null);
  const [talentdetails, setTalentdetails] = useState(null);
  const [error, setError] = useState("");

  const [hrate, setHourlyRate] = useState("10");
  const [feedbackText, setFeedbackText] = useState("");

  const handleAcceptClick = (profileTalentDataItem) => {
    verifyFreelancer(profileTalentDataItem._id, feedbackText, "Approved");
    //console.log(profileTalentDataItem._id);
    //console.log("entered");
  };

  useEffect(() => {
    document.title = "Verification";
  }, []);

  const [showtalent, setShowTalent] = useState(false);
  const handleClosetalent = () => {
    setShowTalent(false);
    setTalentdetails();
  };

  const [formData, setFormData] = useState({
    priceRange: "",
    location: "United Kingdom",
  });

  const { priceRange, location } = formData;

  const onChangeVal = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [state, setstate] = useState({
    value: { min: 2, max: 1000 },
  });

  const [value, setValue] = useState("");

  const [show, setShow] = useState(false);
  const [Hirecheck, setcheck] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showReview, setShowReview] = useState(false);
  const handleShowReview = () => setShowReview(true);
  

  const handleCloseReview = (profileTalentDataItem) => {
    //console.log(feedbackText);
    //verifyFreelancer(profileTalentDataItem._id, feedbackText, "Rejected");
    setShowReview(false);
    setFeedbackText("");
  };

  const handleSubmitReview = (profileTalentDataItem) => {
    //console.log(feedbackText);
    verifyFreelancer(profileTalentDataItem._id, feedbackText, "Rejected");
    setShowReview(false);
    setFeedbackText("");
  };


  const storedValue = JSON.parse(localStorage.getItem("search"));

  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const totalPages = profileTalentData
    ? Math.ceil(profileTalentData.length / resultsPerPage)
    : 0;

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = profileTalentData
    ? [...profileTalentData].slice(startIndex, endIndex)
    : [];

  useEffect(() => {
    dispatch(freelancerAllGet_ver());
  }, []);

  //console.log(freelancerList);
  useEffect(() => {
    if (freelancerList !== null) {
      setProfileTalentData(
        freelancerList?.map((item) => ({
          imageLink: item?.profilePhoto?.photo_link,
          name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
          title: item?.expertise?.category,
          location: item?.location?.country,
          hourlyRate: item?.hourlyRate?.hourly_rate,
          skills: [item?.expertise?.skills],
          _id: item?._id,
          verification: item?.verification,
          submissionDate: item.user_id?.date,
        }))
      );
    }
  }, [freelancerList?.length]);

  useEffect(() => {
    if (freelancerList?.length > 0) {
      //console.log(freelancerList);
    }
  }, [freelancerList]);

  const countries = Country.getAllCountries();

  const profileview = async (e) => {
    //console.log(e);
    //console.log(e._id);
    freelancerGetbyId(e._id).then((res) => {
      //console.log(res.data);
      if (res.data) {
        setShowTalent(true);
      }
    });
    //console.log(freelancerprofile);
    //setTalentdetails(freelancerprofile);

    //history.push("/startcontract");
  };

  const handleSearch = () => {
    //console.log("Running");
    // filter the freelancerList array based on the value entered in the search bar
    try {
      //console.log(freelancerList);
      //const filteredList = freelancerList.filter( freelancer => freelancer.user_id.first_name.toLowerCase().includes(value.toLowerCase()));
      const filteredList = freelancerList?.filter((freelancer) => {
        return (
          freelancer.verification === false &&
          freelancer.user_id.first_name
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      });

      //console.log("Running");
      //console.log(value);
      //console.log(filteredList);

      // update the profileTalentData with the filtered list
      setProfileTalentData(filteredList);
      //console.log(filteredList);

      if (filteredList.length > 0) {
        setProfileTalentData(
          filteredList.map((item) => ({
            imageLink: item?.profilePhoto?.photo_link,
            name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
            title: item?.expertise?.category,
            location: item?.location?.country,
            hourlyRate: item?.hourlyRate?.hourly_rate,
            skills: [item?.expertise?.skills],
            _id: item?._id,
            verification: item?.verification,
          }))
        );
      }
    } catch (err) {
      //console.log(err);
    }
    //localStorage.removeItem('search');
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    // Check if the input value is a valid number
    if (value && isNaN(Number(value))) {
      // If not, set the error message
      setError("Hourly rate can only be in numbers");
    } else {
      // If it's a valid number, clear the error message
      setError("");
    }
    setHourlyRate(e.target.value);
  };

  const filtersearch = () => {
    try {
      //console.log(freelancerList);
      //console.log(location);
      //const filteredList = freelancerList.filter( freelancer => freelancer.hourlyRate.hourly_rate <= parseFloat(hrate)
      //&& freelancer.location.country.toLowerCase().includes(location.toLowerCase()));
      const filteredList = freelancerList?.filter((freelancer) => {
        return (
          freelancer.verification === false &&
          freelancer.user_id.first_name
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      });

      // update the profileTalentData with the filtered list
      setProfileTalentData(filteredList);

      if (filteredList.length > 0) {
        setProfileTalentData(
          filteredList.map((item) => ({
            imageLink: item?.profilePhoto?.photo_link,
            name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
            title: item?.expertise?.category,
            location: item?.location?.country,
            hourlyRate: item?.hourlyRate?.hourly_rate,
            skills: [item?.expertise?.skills],
            _id: item?._id,
            verification: item?.verification,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterAndClose = () => {
    filtersearch();
    setShow(false);
  };

  // console.log(profileTalentData);

  /*

    useEffect(() => {
        const storedValue = JSON.parse(localStorage.getItem('search'));
        if (storedValue) {
          setValue(storedValue);
          //handleSearch();
          //localStorage.removeItem('search');
        }
      }, []);

    
    useEffect(() => {
        console.log(profileTalentData);
    }, [profileTalentData]);
   
    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }
     */

  return (
    <div className="main-page page-dashboard page-talent">
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <div className="container-fluid">
        <section className="bg-white rounded shadow-sm">
          {showtalent == true ? (
            <Modal
              show={showtalent}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={handleClosetalent}
              //</section>className={showtalent ? 'xl' : ''}
            >
              <Modal.Header>
                <Modal.Title>
                  Expert Details -{" "}
                  {freelancerprofile.profile.user_id.first_name +
                    " " +
                    freelancerprofile.profile.user_id.last_name}
                </Modal.Title>
                <Icon
                  className="mx-2 icon-hover"
                  icon={remove}
                  onClick={handleClosetalent}
                />
              </Modal.Header>

              <Modal.Body className="talent-modal">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="rounded-circle overflow-hidden"
                        style={{
                          height: "150px",
                          width: "150px",
                          margin: "0 auto",
                        }}
                      >
                        <img
                          className="img-fluid"
                          src={
                            freelancerprofile.profile.profilePhoto.photo_link
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </div>
                     
                      <h6 className="text-primary">Title</h6>
                      <p className="text-muted text-justify">
                        {freelancerprofile.profile.titleAndOverview.title}
                      </p>
                     
                      <h6 className="text-primary">Expert Level</h6>
                      <p className="text-muted">
                        {freelancerprofile.profile.expertLevel.expert_level}
                      </p>
                     
                      <h6 className="text-primary">Hourly Rate</h6>
                      <p className="text-muted">
                        {freelancerprofile.profile.hourlyRate.hourly_rate + "$"}
                      </p>
                      
                      <h6 className="text-primary">Education</h6>
                      <p className="text-muted">
                        {freelancerprofile.profile.education.degree +
                          " - " +
                          freelancerprofile.profile.education.specialization +
                          " - " +
                          freelancerprofile.profile.education.provider}
                      </p>
                     
                      <h6 className="text-primary">Certification</h6>
                      <p className="text-muted">
                        {freelancerprofile.profile.certification
                          .certification_name +
                          " Provided by " +
                          freelancerprofile.profile.certification.provider}
                      </p>
                      
                      <h6 className="text-primary">Employment</h6>
                      <p className="text-muted">
                        {freelancerprofile.profile.employment.title +
                          " At " +
                          freelancerprofile.profile.employment.company_name +
                          " -- From: " +
                          freelancerprofile.profile.employment.from +
                          " - Till: " +
                          freelancerprofile.profile.employment.to}
                      </p>
                      
                      <h6 className="text-primary">Location</h6>
                      <p className="text-muted">
                        {freelancerprofile.profile.location.country}
                      </p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosetalent}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            console.log("Null")
          )}

          {profileTalentData === null &&
            isLoaded === false &&
            [0, 1, 2, 3, 4].map((i) => (
              <div key={i} className="border-bottom pb-2 py-4">
                <div className="row">
                  <div
                    className="col-md-6 pt-1"
                    style={{
                      marginLeft: "5%",
                      maxWidth: "40%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Skeleton width={180} height={30} />
                    </div>
                    <p className="my-1">
                      <Skeleton width={150} height={12} />
                    </p>
                    <p className="my-1">
                      <Skeleton width={110} height={12} />
                    </p>
                  </div>
                </div>
              </div>
            ))}

          {currentResults.length > 0 && profileTalentData !== null ? (
            currentResults.length > 0 &&
            [...currentResults].reverse().map((profileTalentDataItem) => (
              <div className="freelancer-list talent-block">
                <div className="row">
                  <div
                    className="col-md-9 pt-1"
                    style={{ marginLeft: "5%", maxWidth: "65%" }}
                    onClick={() => {
                      profileview(profileTalentDataItem);
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h3 className="mb-0">
                        {profileTalentDataItem.name}
                      </h3>
                      
                    </div>
                    {/** <p className="font-weight-bold mb-0">
                      {profileTalentDataItem.title}
                    </p>*/}
                    {/*}<p><span className="font-weight-bold">${profileTalentDataItem.hourlyRate}.00</span> / hr</p>{*/}

                    {/*<p className="text-primary font-weight-bold"><Icon icon={fileO} /> Completed 13 SEO jobs on CyberToCyber</p>*/}
                    <div className="tags-row mb-0">
                      {profileTalentDataItem.skills.length > 0 &&
                        profileTalentDataItem.skills.map(
                          (profileTalentDataItemSkills, index) => {
                            if (profileTalentDataItemSkills?.includes(",")) {
                              const splitSkills =
                                profileTalentDataItemSkills.split(",");
                              return splitSkills.map((skill, i) => (
                                <span
                                  key={`${index}-${i}`}
                                  className="badge"
                                >
                                  {skill}
                                </span>
                              ));
                            } else {
                              return (
                                <span
                                  key={index}
                                  className="badge bg-secondary mr-2 mt-1"
                                >
                                  {profileTalentDataItemSkills}
                                </span>
                              );
                            }
                          }
                        )}
                      
                    </div>
                    <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.75rem", paddingTop: "10px", marginBottom: "1.2%" }}
                      >
                        Date:{" "}
                        {new Date(
                          profileTalentDataItem.submissionDate
                        ).toLocaleDateString()}
                      </p>
                  </div>
                  <div className="col-md-2 col-lg-3 mt-0 button-group d-flex flex-column justify-content-center">
                    {isAuthenticated && user.type == "Admin" ? (
                      <>
                        <div className="mb-2">
                          <button
                            onClick={() => {
                              handleAcceptClick(profileTalentDataItem);
                              setSelectedfreelancer(profileTalentDataItem);
                            }}
                            className="btn text-nowrap btn-default btn-sn mt-3 mt-md-auto col-sm-12 col-md-auto mb-0 direct-hire-button"
                          >
                            Accept
                          </button>
                        </div>

                        {/*}<div className="mb-2"><button onClick={() => {setInviteModal(true); setSelectedfreelancer(profileTalentDataItem)}} className="btn btn-primary btn-sm col-md-8 mb-2">Reject</button></div>{*/}
                        <div className="mb-2">
                          <Button
                            onClick={() => {
                              handleShowReview();
                            }}
                            className="btn text-nowrap btn-primary btn-sn mt-3 mt-md-auto col-sm-12 col-md-auto mb-0 direct-hire-button"
                          >
                            Reject
                          </Button>
                        </div>
                        <Modal
                          show={showReview}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          onHide={handleCloseReview}
                          size="lg"
                        >
                          <Modal.Header>
                            <Modal.Title>Reject Form</Modal.Title>
                            <Icon
                              className="mx-2 icon-hover"
                              icon={remove}
                              onClick={handleCloseReview}
                            />
                          </Modal.Header>
                          <Modal.Body>
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                  <p>
                                    <span className="font-weight-bold">
                                      Reason for rejection:
                                    </span>
                                  </p>
                                  <br></br>
                                  <p>
                                    <textarea
                                      className="p-2 form-control"
                                      name=""
                                      rows="10"
                                      placeholder="Enter your Feedback here"
                                      value={feedbackText} // Update the value of the textarea to capture the feedback text
                                      onChange={(e) =>
                                        setFeedbackText(e.target.value)
                                      } // Update the callback function to handle the feedback text change
                                    ></textarea>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => {
                                handleSubmitReview(profileTalentDataItem);
                              }}
                            >
                              Submit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    ) : (
                      console.log("sign in required")
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : null}
        </section>
        {/* Display the navigation buttons */}
        {totalPages > 1 && (
          //FIXED PAGINATION

          <nav>
            <ul className="talent-pagination pagination justify-content-center">
              <div
                className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() =>
                  handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                }
              >
                <Icon icon={arrowLeft} />
              </div>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
              <div
                className={`icon ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                onClick={() =>
                  handlePageChange(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
              >
                <Icon icon={arrowRight} />
              </div>
            </ul>
          </nav>

          /* 
                               OLD PAGINATION
                               <nav>
                                <ul className="pagination justify-content-center">
                                    {[...Array(totalPages)].map((_, index) => {
                                    const pageNumber = index + 1;
                                    return (
                                        <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(pageNumber)}>
                                            {pageNumber}
                                        </button>
                                        </li>
                                    );
                                    })}
                                </ul>
                                </nav> */
        )}
        {currentResults.length > 0 && profileTalentData !== null? null 
        : 
        (
          <div className="mt-5 p-5">
           {/** <h6 className="text-primary text-center mb-0">
             No Search Result Found
           </h6>
           */}
           <div className="p-5">
           <div className="message-not-available w-100">
           <div className="text-center">
             <img src="assets/images/no-data-found.png" width={'200px'} alt="message" className="mb-4"/>
             <h1>Sorry! No Data Found</h1>
             <p className="land">There is no account found for verification</p>
           </div>
           </div>
           </div>
         </div>
       )
        }
      </div>
    </div>
  );
};

Verification.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  auth: state.auth,
  freelancerProfile: state.freelancerProfile,
});

export default connect(mapStateToProp, { freelancerGetbyId, verifyFreelancer })(
  Verification
);
