import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, NavLink } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit';
import { graduationCap } from 'react-icons-kit/fa/graduationCap';
import Table from 'react-bootstrap/Table';
import Alert from '../../components/alert/Alert';
import { edit } from 'react-icons-kit/fa/edit';
import { trash } from 'react-icons-kit/fa/trash';
import { addEducation, updateEducation, deleteEducation } from "../../actions/freelancerProfile";
import SweetAlert from 'react-bootstrap-sweetalert';
import { remove } from 'react-icons-kit/fa/remove'
import { KeyboardBackspaceOutlined } from "@material-ui/icons";

const Education = ({ freelancerProfile: { education, freelancerprofile }, setAlert, addEducation, updateEducation, deleteEducation, isAuthenticated }) => {

  const [DataOfEducation, setDataOfEducation] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [alertBox, setAlertBox] = useState(false);

  const [formData, setFormData] = useState({
    provider: "",
    from: "",
    to: "",
    current: "",
    degree: "",
    specialization: "",
    description: "",
    _id: ""
  });

  const { provider, from, to, current, degree, specialization, description, _id } = formData;

  useEffect(() => {
    document.title = 'Education';
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const regex = /^[a-zA-Z0-9\s.,!?%^&*()_+-=<>:;"'\/\\[\]{}|~]+$/;

  const providerRegex = /^[a-zA-Z0-9\s.'&-]+$/;
  // Allows alphanumeric characters, spaces, periods, ampersands, single quotes, and hyphens.

  const degreeRegex = /^[a-zA-Z\s.'-]+$/;
  // Allows alphabets, spaces, periods, apostrophes, and hyphens.

  const specializationRegex = /^[a-zA-Z0-9\s.&'-]+$/;
  // Allows alphanumeric characters, spaces, ampersands, periods, apostrophes, and hyphens.

  const descriptionRegex = /^[a-zA-Z0-9\s.,!?@#%^&*()_+-=<>:;"'/\[\]{}|~]+$/;
  // Allows alphanumeric characters, spaces, and most common punctuation.

  const onSubmit = async (e) => {
    e.preventDefault();
    //console.log(from)
    //console.log(to)
    // Check that Title and Professional Overview fields contain at least one letter
    //if (!regex.test(provider) || !regex.test(degree) || !regex.test(specialization) || !regex.test(description)){
    //  setAlert("All fields must contain at least one letter", "danger");
    //  console.log("All fields must contain at least one letter")
    //  return;
    //}

    if (!providerRegex.test(provider)) {
      setAlert("Please enter a valid name for the School/College/University.", "danger");
      return;
    }

    if (degree !== "" && !degreeRegex.test(degree)) {
      setAlert("Please enter a valid degree name.", "danger");
      return;
    }

    if (specialization != "" && !specializationRegex.test(specialization)) {
      setAlert("Please enter a valid specialization.", "danger");
      return;
    }

    if (!descriptionRegex.test(description)) {
      setAlert("Please use valid characters in the description.", "danger");
      return;
    }

    if (!validateDateRange()) {
      setAlert("The 'From' date must be earlier than the 'To' date.", "danger");
      return;
    }

    if (showUpdate == true) {
      updateEducation(_id, {
        provider,
        from,
        to,
        current,
        degree,
        specialization,
        description
      });
    } else {
      addEducation({
        provider,
        from,
        to,
        current,
        degree,
        specialization,
        description
      });
    }
    // setDataOfEducation(DataOfEducation => [...DataOfEducation, formData]);
    setFormData({});
    setShow(false);
    setShowUpdate(false);
  };

  const validateDateRange = () => {
    const fromYear = parseInt(from);
    const toYear = parseInt(to);

    return fromYear <= toYear;
  };

  const generateFromYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1940; year--) {
      years.push(<option value={year.toString()}>{year}</option>);
    }
    return years;
  };


  const generateToYears = () => {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 4; // Include next 4 years
    const years = [];
    for (let year = endYear; year >= 1940; year--) {
      years.push(<option value={year.toString()}>{year}</option>);
    }
    return years;
  };



  const updateEducationFunc = async (e) => {
    setShowUpdate(true);
    setShow(true);
    e.from = new Date(e.from).getFullYear();
    e.to = new Date(e.to).getFullYear();
    setFormData(e);
  }

  const deleteEducationFunc = async (e) => {
    setDeleteItem(e);
    setAlertBox(true);
  }

  const confirmDelete = () => {
    setAlertBox(false);
    deleteEducation(deleteItem);
  }

  useEffect(() => {
    let check = JSON.parse(localStorage.getItem('education'));
    if (check) {
      setDataOfEducation(education);
    }

  }, [education]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShowUpdate(false);
    setShow(true);
    setFormData({});
  }

  if (!isAuthenticated) {
    return <Redirect to="/login-register?role=pro&type=login" />;
  }
  if (typeof freelancerprofile === "string") {
    return <Redirect to="/expert-email-verify" />;
  }

  return (
    <section className="main-page page-dashboard">
      <SweetAlert
        warning
        showCancel
        show={alertBox}
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="secondary"
        cancelBtnCssClass="btn-cancel-popup btn-primary"
        title="Are you sure?"
        onConfirm={() => confirmDelete()}
        onCancel={() => setAlertBox(false)}
      >
        You will not be able to recover this data!
      </SweetAlert>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
            <div className="bg-white rounded shadow-sm sidebar-page-right">
              <div className="p-5">

              <Link to={`/profileandphoto`} className=" text-dark d-flex mb-2"
                  style={{ alignItems: "center", gap: "3px" }}
                >
                  <KeyboardBackspaceOutlined />  Back

                </Link>
                <Alert />
                <h3 className="text-primary">Education</h3>
                <p className="font-weight-bold">08 of 10</p>
              <div className="scrollable">

                <div className="row form-group">
                  <div className="col-md-8">
                    <h6 className="font-weight-bold mb-4 mt-4">Update your academic education profile</h6>
                  </div>
                  <div className="col-md-4 text-right mt-4">
                    <Button onClick={handleShow} className="c-btn c-fill-color-btn pull-right">{/** <Icon icon={graduationCap} />*/} Add Education</Button>
                  </div>
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>College Name</th>
                      <th>Degree Name</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DataOfEducation.length > 0 && DataOfEducation.map((itemofEducation, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{itemofEducation.provider}</td>
                        <td>{itemofEducation.degree}</td>
                        <td>{itemofEducation.description}</td>
                        <td>
                          <div className="d-flex align-items-center">

                            <Icon icon={edit} onClick={() => updateEducationFunc(itemofEducation)} style={{ marginLeft: '10px' }} />
                            <Icon icon={trash} onClick={() => deleteEducationFunc(itemofEducation._id)} style={{ display: 'flex' }} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Modal className="modal-education" show={show} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Modal.Header>
                      <Modal.Title>Add Education</Modal.Title>
                      <Icon className="mx-2 icon-hover" icon={remove} onClick={handleClose} />
                    </Modal.Header>
                    <Modal.Body>
                      <Alert />
                      <div className="row d-flex align-items-center form-group">
                        <div className="col-md-12 col-12">
                          <p className="text-muted font-weight-bold">School/College/University <span className="required-field">*</span></p>
                        </div>
                        <div className="col-md-12 col-12">
                          <input type="text"
                            className="form-control"
                            placeholder="Ex: Northwestern University"
                            name="provider"
                            value={provider}
                            title="Allows alphanumeric characters, spaces, periods, ampersands, single quotes, and hyphens."
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                        <div className="col-md-12 col-12 mt-4">
                          <p className="font-weight-bold">Duration From <span className="required-field">*</span> - to</p>
                        </div>
                        <div className="col-md-6 col-12">
                          <select className="form-control"
                            name="from"
                            value={from}
                            onChange={(e) => onChange(e)}>
                            <option value="">From</option>
                            {generateFromYears()}

                          </select>
                        </div>
                        <div className="col-md-6 col-12">
                          <select className="form-control mt-1"
                            name="to"
                            value={to}
                            onChange={(e) => onChange(e)}>
                            <option value="">To (or expected graduation year)</option>
                            {generateToYears()}

                          </select>
                        </div>
                        <div className="col-md-12 col-12 mt-4">
                          <p className="font-weight-bold">Degree (Optional)</p>
                        </div>
                        <div className="col-md-12 col-12">
                          <input type="text" className="form-control" placeholder="Ex: Bachelor's"
                            name="degree"
                            value={degree}
                            title="Allows alphabets, spaces, periods, apostrophes, and hyphens."
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                        <div className="col-md-12 col-12 mt-4">
                          <p className="font-weight-bold">Specialization (Optional)</p>
                        </div>
                        <div className="col-md-12 col-12">
                          <input type="text" className="form-control" placeholder="Ex: Computer Science"
                            name="specialization"
                            value={specialization}
                            title="Allows alphanumeric characters, spaces, ampersands, periods, apostrophes, and hyphens."
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                        <div className="col-md-12 col-12 mt-4">
                          <p className="font-weight-bold">Description (Optional)</p>
                        </div>
                        <div className="col-md-12 col-12">
                          <textarea className="form-control" rows="6"
                            name="description"
                            value={description}
                            title="Allows alphanumeric characters, spaces, and most common punctuation."
                            onChange={(e) => onChange(e)}
                          ></textarea>
                          {(showUpdate == true) ?
                            <input type="hidden" className="form-control"
                              name="_id"
                              value={_id}
                              onChange={(e) => onChange(e)}
                            /> : ""
                          }
                        </div>
                      </div>


                    </Modal.Body>
                    <Modal.Footer>
                      <p className="text-right m-0">
                        <button type="submit" className="btn btn-primary">
                          {(showUpdate == true) ? "Update" : "Add"}
                        </button>
                      </p>
                    </Modal.Footer>
                  </form>
                </Modal>
                {/* <div className="row justify-content-center complete_profile_buttons"> 
                                <div className="text-righ">
                                    <Link to="/profileandphoto" className="btn btn-default mr-3">Back</Link>
                                    <Link to="/certificate" className="btn btn-primary">Next</Link>
                                </div>
                              </div> */}
                              </div>
                <div className="row justify-content-center p-2">
                  <div className="col-md">
                    {/*<Link to="/certificate" className="btn btn-normal">Skip this step</Link>*/}
                  </div>
                  <div className="text-right wm-100">
                    <button type="submit" className="btn btn-primary wm-100" >
                    <Link to="/certificate" className="">Next</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Education.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile,
});


export default connect(mapStateToProp, { setAlert, addEducation, updateEducation, deleteEducation })(Education, Alert);