import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useHistory, Link } from "react-router-dom";
import {
  messageRead,
  proposalGetByClient,
  messageGetbyId,
  proposalRead,
  messageSummary,
} from "../../actions/proposalAndContract";
import { getAdminMessages } from "../../actions/manageUsers";
import "./chat.css";
import "../../App.css";
import io from "socket.io-client";
import Alert from "../../components/alert/Alert";
import { setAlert } from "../../actions/alert";
import Chat from "./chat";
import { SETCONTRACTINFORMATION } from "../../actions/types";
import { toast } from "react-toastify";
import useWindowSize from "../../customHooks/windowResize";
import { Icon } from "react-icons-kit";
import { arrowLeft2 } from "react-icons-kit/icomoon/arrowLeft2";
import { getAuth } from "../../actions/auth";
import Message from "../../img/message.png";
import "./chat.css";

import Skeleton from "./../../components/skeleton";
import useChat from "../../hooks/useChat";
import AdminChat from "./AdminChat";

const socket = io.connect("https://cybertocyber.com");

const getDateBasedOnTime = (messageDateStr, messageTimeStr) => {
  if (messageTimeStr) {
    const [hours, minutes] = messageTimeStr.split(':').map(Number);
    const messageDate = new Date(messageDateStr);
    messageDate.setHours(hours, minutes);

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Check if the message is from today
    if (messageDate.toDateString() === today.toDateString()) {
      return messageTimeStr; // Return time as it is
    }

    // Check if the message is from yesterday
    if (messageDate.toDateString() === yesterday.toDateString()) {
      return 'yesterday';
    }

    // Otherwise, return the full date string
    return messageDate.toDateString();
  }
};


const Messages = ({
  auth: { isAuthenticated, user },
  proposalAndContract: {
    ProposalsGetByClient,
    MessagesItemData,
    AdminMessagesItemData,
  },
  proposalGetByClient,
  messageGetbyId,
  messageSummary
}) => {
  //console.log("This is user data", user);

  const { users: onlineUsers } = useChat({
    user_id: user?.type === "Freelancer" ? user?.freelancer_id : user?._id,
    username: user?.user_name,
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [username, setUsername] = useState("");
  const [room, setRoom] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [count, setCount] = useState(0);
  const [proposal_id, setProposalId] = useState("");
  const [freelancer_id, setfreelancer_id] = useState("");
  const [hire_manager_id, sethire_manager_id] = useState("");
  const [search, setSearch] = useState(false);
  const [activeProposal, setActiveProposal] = useState(null);
  const [viewAdminMessages, setViewAdminMessages] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const winSize = useWindowSize();
  const [filteredpropdata, setFilteredpropdata] = useState(null);
  const [isUnread, setisUnread] = useState(false);
  const [adminMessages, setAdminMessages] = useState({});
  //const [loading, setLoading] = useState(false);

  // Search Messages Funtionalty
  const [searchString, setSearchString] = useState("");
  const [founds, setFounds] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [toggleChat, setToggleChat] = useState(false);

  useEffect(() => {
    setAdminMessages(AdminMessagesItemData);
  }, [AdminMessagesItemData]);

  useEffect(() => {
    document.title = "Messages";
    dispatch(getAdminMessages());
  }, []);

  useEffect(() => {
    async function load() {
      await dispatch(getAuth());
      setIsLoaded(true);
    }
    load();
  }, []);

  useEffect(() => {
    if (user) {
      proposalGetByClient(user, () => {
        if (ProposalsGetByClient.length > 0) {
          document
            .getElementById(ProposalsGetByClient.reverse()[0]._id)
            ?.click();
        }
      });
    }
  }, [user._id, ProposalsGetByClient]);

  useEffect(() => {
    if (ProposalsGetByClient.length > 0) {
      const sortedProposals = ProposalsGetByClient.sort((a, b) => {
        let aTime, bTime;
        if (
          a?.messages?.length > 0 &&
          a?.messages[a.messages.length - 1]?.message_date
        ) {
          aTime = new Date(
            a.messages[a.messages.length - 1].message_date
          ).getTime();
        } else {
          aTime = new Date(a.proposal_date).getTime();
        }
        //console.log(aTime);

        if (
          b?.messages?.length > 0 &&
          b?.messages[b.messages.length - 1]?.message_date
        ) {
          bTime = new Date(
            b.messages[b.messages.length - 1].message_date
          ).getTime();
        } else {
          bTime = new Date(b.proposal_date).getTime();
        }
        //console.log(bTime);

        return bTime - aTime;
      });
      //setFilteredpropdata(sortedProposals);
      //console.log(sortedProposals)

      //console.log("This is ProposalsGetByClient data", ProposalsGetByClient);
      //console.log("This is sortedProposals data", sortedProposals);

      setFilteredpropdata(
        sortedProposals.map((item) => ({
          username: item?.username,
          proposaltime: item?.proposal_time,
          freelancerid: item?.freelancer_id,
          manager: item?.user,
          job_id: item?.job_id,
          other_user_msgs: item?.messages?.filter(
            (msg) => msg.user_name !== user.user_name
          ),
          _id: item?.proposal_id,
          Item: item,
          photo: item?.freelancer_id?.profilePhoto?.photo_link,
          first_name: item?.freelancer_id?.user_id?.first_name,
          last_name: item?.freelancer_id?.user_id?.last_name,
          client_name: item?.username,
          lastmsg: item?.messages[item.messages.length - 1],
          lastmsg_text: item?.messages[item.messages.length - 1]?.message_text,
          lastmsg_name: item?.messages[item.messages.length - 1]?.user_name,
          // lastmsg_time: item?.messages[item.messages.length - 1]?.message_time,
          lastmsg_time: getDateBasedOnTime(
            item?.messages[item.messages.length - 1]?.message_date,
            item?.messages[item.messages.length - 1]?.message_time
          ),
          lastmsg_type: item?.messages[item.messages.length - 1]?.message_type,
          prop_read: item?.read,
          sender_type: item?.sender_type,
          unread_message_count: item.unread_message_count ?? 0,
        }))
      );
    }
  }, [ProposalsGetByClient]);

  // useEffect(() => {
  //   if (winSize.width <= 767) {
  //     setToggleChat(false);
  //   } else {
  //     setToggleChat(true);
  //   }
  // }, [winSize]);

  useEffect(() => {
    if (founds.length > 0) {
      document?.getElementById(founds[pagination - 1]._id)?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [pagination]);

  useEffect(() => {
    setPagination(1);
    if (founds.length > 0) {
      const regex = new RegExp(searchString, "gi");
      let element = document.getElementById(founds[0]._id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [founds]);

  /*useEffect(() => {
    messageSummary();
  }, []);*/

  const joinRoom = async (e) => {
    //debugger;
    if (e.read === false && user.type !== e.sender_type) {
      dispatch(proposalRead(user, e.proposal_id));
    }
    dispatch(messageRead(user, e.proposal_id));
    setActiveProposal(e);
    // setShowChat(false);
    setUsername(user.user_name);
    var RoomName = e.freelancer_id + e.hire_manager_id;
    setRoom(RoomName);
    if (e.sender_type !== "Admin") {
      setProposalId(e.proposal_id);
      setfreelancer_id(e.freelancer_id);
      sethire_manager_id(e.hire_manager_id);
      setCount(1);
    }
  };

  useEffect(() => {
    socket.emit("join_room", room);
    if (count == 1) {
      setShowChat(true);
    }
  }, [username, room]);

  if (!isAuthenticated && isLoaded) {
    return <Redirect to="/login-register?role=client&type=login" />;
  }

  function Incremnent() {
    if (pagination < founds.length) {
      setPagination(pagination + 1);
    }
  }

  function Decrement() {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  }

  const hireFunction = () => {
    if (
      !activeProposal.proposal_id ||
      !activeProposal.freelancer_id._id ||
      !activeProposal.hire_manager_id ||
      !activeProposal.job_id
    ) {
      //toast.error("Something went wrong, please try again later");
      dispatch(
        setAlert("Something went wrong, please try again later", "danger", 3000)
      );
    }
    let data = {
      proposal_id: activeProposal.proposal_id,
      freelancer_id: activeProposal.freelancer_id._id,
      hire_manager_id: activeProposal.hire_manager_id,
      job_id: activeProposal.job_id._id,
    };
    //console.log("JOB ID Checking: ", activeProposal.job_id._id);
    dispatch({
      type: SETCONTRACTINFORMATION,
      payload: data,
    });
    history.push("/startcontract");
  };

  const formatDate = (dateString, timeString) => {
    // debugger
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) {
      return 'Today';
    } else if (isYesterday) {
      return 'Yesterday';
    } else {
      return date.toDateString();
    }
  };

  return (
    <>
      <div className="main-page page-dashboard">
        <div className="container-fluid">
          <main className="panel-box">
            <div className="panel-box--wrap">
              <section className="messages-box">
                <main className="messages-box--body ">
                  {ProposalsGetByClient.length == 0 &&
                  adminMessages?.adminMessages == null ? (
                    <div className="message-not-available p-5 w-100">
                      <div className="text-center">
                        <img src={Message} width={"100px"} alt="message" />
                      </div>
                      <h2 className="text-primary mt-2">Welcome to Messages</h2>
                      {user.type !== "Enterprise Client" ? (
                        <>
                          <h6 className="my-4">
                            Once you connect with a{" "}
                            {user.type === "Client" ? "freelancer" : "Client"},
                            you'll be able to chat and collaborate here.
                          </h6>
                          <Link
                            to={`/${user.type === "Client" ? "talent" : "job"}`}
                            className="btn btn-primary"
                          >
                            Search for {user.type === "Client" ? "talent" : "work"}
                          </Link>
                        </>
                      ) : null}

                    </div>
                  ) : (
                    <>
                      <aside className="messages-box--body-left w-100">
                        <header className="messages-box--search-area">
                          <h3 className="title">
                            {/* {(winSize.width <= 767) && <button className="btn btn-sm btn-back">{'<<'}</button>} */}
                            Messaging
                          </h3>
                        </header>
                        <main className="messages-box--list-area">
                          {filteredpropdata === null &&
                            adminMessages?.adminMessages == null &&
                            [0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
                              <div
                                key={i}
                                className="messages-box--chat-list-item"
                              >
                                <div className="p-3 d-flex align-items-center overflow-hidden">
                                  <div className="dropdown-list-image mr-3">
                                    <Skeleton
                                      width={40}
                                      height={40}
                                      borderRadius={40}
                                    />
                                  </div>
                                  <Skeleton width={120} height={10} count={2} />
                                </div>
                              </div>
                            ))}
                          {adminMessages?.adminMessages && (
                            <div
                              className="messages-box--chat-list-item"
                              onClick={() => {
                                setToggleChat(true);
                                setViewAdminMessages(true);
                                // joinRoom(adminData);
                              }}
                            >
                              <div className="p-3 d-flex align-items-center overflow-hidden">
                                <div className="dropdown-list-image mr-3">
                                  {adminMessages?.photo ? (
                                    <div
                                      className="rounded-circle"
                                      style={{
                                        position: "relative",
                                        height: "40px",
                                        width: "40px",
                                        backgroundColor: "#D3D3D3",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        className="rounded-circle"
                                        src={adminMessages?.photo}
                                        alt=""
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="rounded-circle"
                                      style={{
                                        position: "relative",
                                        height: "40px",
                                        width: "40px",
                                        backgroundColor: "#D3D3D3",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {adminMessages?.type === "Admin" && (
                                        <h4
                                          className="text-uppercase profile-avatar"
                                          style={{ margin: 0 }}
                                        >
                                          AD
                                        </h4>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="font-weight-bold mr-1 overflow-hidden">
                                  {adminMessages?.adminMessages[
                                    adminMessages?.adminMessages?.length - 1
                                  ] &&
                                    adminMessages?.adminMessages[
                                      adminMessages?.adminMessages?.length - 1
                                    ].read === false ? (
                                    <h6 className="text-truncate mb-0">
                                      <strong>{adminMessages?.username}</strong>
                                    </h6>
                                  ) : (
                                    <h6 className="text-truncate mb-0">
                                      {adminMessages?.username}
                                    </h6>
                                  )}
                                  <p className="text-truncate mb-0 font-weight-normal">
                                    {adminMessages?.lastmsg_text?.length > 35
                                      ? adminMessages?.lastmsg_text?.slice(
                                        0,
                                        35
                                      ) + "..."
                                      : adminMessages?.lastmsg_text}
                                  </p>
                                </div>
                                <div className="ml-auto">
                                  {adminMessages?.lastmsg_time && (
                                    <div className="text-right text-muted small">
                                      {/* {adminMessages?.lastmsg_time} */}
                                      {formatDate(adminMessages?.lastmsg_date, adminMessages?.lastmsg_time)}
                                    </div>
                                  )}

                                  {(adminMessages?.adminMessages[
                                    adminMessages?.adminMessages?.length - 1
                                  ] &&
                                    adminMessages?.adminMessages[
                                      adminMessages?.adminMessages?.length - 1
                                    ].read === false) ||
                                    (adminMessages?.type == "Admin" &&
                                      adminMessages?.prop_read === false) ? (
                                    <div className="new-message-notification red-dot d-flex align-items-center justify-content-center text-white">
                                      {adminMessages?.unread_message_count > 9
                                        ? "9+"
                                        : adminMessages?.unread_message_count}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                          {filteredpropdata &&
                            filteredpropdata.length > 0 &&
                            [...filteredpropdata].map((Item) => (
                              <div
                                key={Item._id}
                                className="messages-box--chat-list-item"
                              >
                                <div
                                  className="p-3 d-flex align-items-center overflow-hidden"
                                  onClick={() => {
                                    joinRoom(Item.Item);
                                    setToggleChat(true);
                                    setViewAdminMessages(false);
                                  }}
                                >
                                  <div className="dropdown-list-image mr-3">
                                    {Item.photo ? (
                                      <div
                                        className="rounded-circle"
                                        style={{
                                          position: "relative",
                                          height: "40px",
                                          width: "40px",
                                          backgroundColor: "#D3D3D3",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          className="rounded-circle"
                                          src={Item.photo}
                                          alt=""
                                          style={{
                                            height: "40px",
                                            width: "40px",
                                          }}
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "0",
                                            right: "0",
                                            height: "12px",
                                            width: "12px",
                                            backgroundColor: (
                                              user.type === "Client"
                                                ? onlineUsers?.[
                                                Item?.freelancerid?._id
                                                ]
                                                : onlineUsers?.[
                                                Item?.Item?.hire_manager_id
                                                ]
                                            )
                                              ? "#00ff00"
                                              : "#d3d3d3",
                                            borderRadius: "50%",
                                            border: "2px solid #ffffff",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="rounded-circle"
                                        style={{
                                          position: "relative",
                                          height: "40px",
                                          width: "40px",
                                          backgroundColor: "#D3D3D3",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {user.type === "Client" || user.type === "Enterprise Client" ? (
                                          Item &&
                                            Item.first_name &&
                                            Item.last_name ? (
                                            <h4
                                              className="text-uppercase profile-avatar"
                                              style={{ margin: 0 }}
                                            >
                                              {Item.first_name.slice(0, 1)}
                                              {Item.last_name.slice(0, 1)}
                                            </h4>
                                          ) : (
                                            <h4
                                              className="text-uppercase profile-avatar"
                                              style={{ margin: 0 }}
                                            >
                                              N/A
                                            </h4>
                                          )
                                        ) : (
                                          <h4
                                            className="text-uppercase profile-avatar"
                                            style={{ margin: 0 }}
                                          >
                                            {Item.client_name.slice(0, 1)}
                                            {Item.client_name.slice(0, 1)}
                                          </h4>
                                        )}
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "0",
                                            right: "0",
                                            height: "12px",
                                            width: "12px",
                                            backgroundColor: (
                                              user.type === "Client"
                                                ? onlineUsers?.[
                                                Item?.freelancerid?._id
                                                ]
                                                : onlineUsers?.[
                                                Item?.Item?.hire_manager_id
                                                ]
                                            )
                                              ? "#00ff00"
                                              : "#d3d3d3",
                                            borderRadius: "50%",
                                            border: "2px solid #ffffff",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {/*console.log("Itemmmmm ----", Item)*/}
                                  <div className="font-weight-bold mr-1 overflow-hidden">
                                    {Item.other_user_msgs[
                                      Item.other_user_msgs.length - 1
                                    ] &&
                                      Item.other_user_msgs[
                                        Item.other_user_msgs.length - 1
                                      ].read === false ? (
                                      <h6 className="text-truncate mb-0">
                                        {user.type === "Client" || user.type === "Enterprise Client" ? (
                                          <strong>
                                            {Item?.first_name} {Item?.last_name}
                                          </strong>
                                        ) : (
                                          <strong>
                                            {Item?.manager?.first_name}{" "}
                                            {Item?.manager?.last_name}
                                          </strong>
                                        )}{" "}
                                        {Item?.job_id?.headline && (
                                          <span className="font-weight-normal">
                                            ({Item?.job_id?.headline})
                                          </span>
                                        )}
                                      </h6>
                                    ) : (
                                      <h6 className="text-truncate mb-0">
                                        {user.type === "Client" || user.type === "Enterprise Client" ? (
                                          <>
                                            {Item?.first_name} {Item?.last_name}
                                          </>
                                        ) : (
                                          <>
                                            {Item?.manager?.first_name}{" "}
                                            {Item?.manager?.last_name}
                                          </>
                                        )}{" "}
                                        {Item?.job_id?.headline && (
                                          <span className="font-weight-normal">
                                            ({Item?.job_id?.headline})
                                          </span>
                                        )}
                                      </h6>
                                    )}

                                    {Item.lastmsg_name !== Item.username ? (
                                      Item.lastmsg ? (
                                        (Item.lastmsg_type === "text" ||
                                          Item.lastmsg_type === "systext") &&
                                          Item.lastmsg_text ? (
                                          <p
                                            className="text-truncate mb-0 font-weight-normal"
                                            style={{ maxWidth: "35ch" }}
                                          >
                                            {Item.lastmsg_type === "systext"
                                              ? "System Message"
                                              : "You: " +
                                              (Item.lastmsg_text.length > 35
                                                ? Item.lastmsg_text.slice(
                                                  0,
                                                  35
                                                ) + "..."
                                                : Item.lastmsg_text)}
                                          </p>
                                        ) : (
                                          <p
                                            className="text-truncate mb-0 w-100 font-weight-normal"
                                            style={{ maxWidth: "35ch" }}
                                          >
                                            {"You: Sent an attachment"}
                                          </p>
                                        )
                                      ) : user.type !== Item.sender_type &&
                                        Item.prop_read === false ? (
                                        Item.sender_type === "Freelancer" ? (
                                          <p
                                            className="text-truncate mb-0 w-100 "
                                            style={{ maxWidth: "35ch" }}
                                          >
                                            {Item.username +
                                              ": Sent a Proposal"}
                                          </p>
                                        ) : (
                                          <p
                                            className="text-truncate mb-0 w-100 "
                                            style={{ maxWidth: "35ch" }}
                                          >
                                            {Item.username + ": Sent an Invite"}
                                          </p>
                                        )
                                      ) : user.type === "Freelancer" ? (
                                        Item.prop_read === true ? null : (
                                          <p
                                            className="text-truncate mb-0 w-100 font-weight-normal"
                                            style={{ maxWidth: "35ch" }}
                                          >
                                            {"You: Sent a Proposal"}
                                          </p>
                                        )
                                      ) : Item.prop_read === true ? null : (
                                        <p
                                          className="text-truncate mb-0 w-100 font-weight-normal"
                                          style={{ maxWidth: "35ch" }}
                                        >
                                          {"You: Sent an Invite"}
                                        </p>
                                      )
                                    ) : Item.other_user_msgs[
                                      Item.other_user_msgs.length - 1
                                    ] &&
                                      Item.other_user_msgs[
                                        Item.other_user_msgs.length - 1
                                      ].read === false ? (
                                      Item.lastmsg_type === "text" ||
                                        Item.lastmsg_type === "systext" ? (
                                        <p
                                          className={`text-truncate mb-0 w-100 ${Item.lastmsg_type === "systext"
                                              ? "text-center"
                                              : ""
                                            }`}
                                          style={{ maxWidth: "35ch" }}
                                        >
                                          {Item.lastmsg_name +
                                            ": " +
                                            Item.lastmsg_text}
                                        </p>
                                      ) : (
                                        <p
                                          className="text-truncate mb-0 w-100"
                                          style={{ maxWidth: "35ch" }}
                                        >
                                          {Item.lastmsg_name +
                                            ": Sent an attachment"}
                                        </p>
                                      )
                                    ) : Item.lastmsg_type === "text" ||
                                      Item.lastmsg_type === "systext" ? (
                                      <p
                                        className={`text-truncate mb-0 w-100 font-weight-normal ${Item.lastmsg_type === "systext"
                                            ? "text-center"
                                            : ""
                                          }`}
                                        style={{ maxWidth: "35ch" }}
                                      >
                                        {Item.lastmsg_name +
                                          ": " +
                                          Item.lastmsg_text}
                                      </p>
                                    ) : (
                                      <p
                                        className="text-truncate mb-0 w-100 font-weight-normal"
                                        style={{ maxWidth: "35ch" }}
                                      >
                                        {Item.lastmsg_name +
                                          ": Sent an attachment"}
                                      </p>
                                    )}
                                  </div>
                                  <div className="ml-auto">
                                    {Item.lastmsg_time ? (
                                      <div className="text-right text-muted small">
                                        {Item.lastmsg_time}
                                      </div>
                                    ) : (
                                      <div className="text-right text-muted small">
                                        {Item.proposaltime}
                                      </div>
                                    )}

                                    {(Item.other_user_msgs[
                                      Item.other_user_msgs.length - 1
                                    ] &&
                                      Item.other_user_msgs[
                                        Item.other_user_msgs.length - 1
                                      ].read === false) ||
                                      (user.type !== Item.sender_type &&
                                        Item.prop_read === false) ? (
                                      <div className="new-message-notification red-dot d-flex align-items-center justify-content-center text-white">
                                        {Item.unread_message_count > 9
                                          ? "9+"
                                          : Item.unread_message_count}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </main>
                      </aside>

                      {toggleChat && (
                        <aside className="messages-box--body-right">
                          <header className="messages-box--selected-header">
                            <div className="row justify-content-md-center">
                              <div className="col mobile-row-header">
                                {winSize.width <= 767 && (
                                  <div onClick={() => setToggleChat(false)}>
                                    <Icon icon={arrowLeft2} />
                                  </div>
                                )}
                                <h5>
                                  {!viewAdminMessages ? (
                                    <>
                                      {activeProposal?.username}
                                      {activeProposal?.job_id?.headline && (
                                        <span className="font-weight-normal chat-header-span">
                                          - {activeProposal?.job_id?.headline}
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    AdminMessagesItemData?.username
                                  )}
                                </h5>
                              </div>
                              {showChat && !viewAdminMessages && (
                                <>
                                  <div className="col text-right">
                                    <div className="d-flex">
                                      {user.type === "Client" ? (
                                        <button
                                          className="btn btn-primary mr-1"
                                          type="button"
                                          onClick={hireFunction}
                                        >
                                          Hire
                                        </button>
                                      ) : null}

                                      <div className="input-group justify-content-end">
                                        <input
                                          type="text"
                                          value={searchString}
                                          onChange={(e) =>
                                            setSearchString(e.target.value)
                                          }
                                          className={`form-control searchInput ${search ? "active" : "active"
                                            }`}
                                          placeholder="Search..."
                                        />
                                        <div className="input-group-append">
                                          <button
                                            className="btn btn-secondary"
                                            onClick={() => setSearch(!search)}
                                            type="button"
                                          >
                                            <i className="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {viewAdminMessages && (
                                <>
                                  <div className="col text-right">
                                    <div className="d-flex">
                                      <div className="input-group justify-content-end">
                                        <input
                                          type="text"
                                          value={searchString}
                                          onChange={(e) =>
                                            setSearchString(e.target.value)
                                          }
                                          className={`form-control searchInput ${search ? "active" : "active"
                                            }`}
                                          placeholder="Search..."
                                        />
                                        <div className="input-group-append">
                                          <button
                                            className="btn btn-secondary"
                                            onClick={() => setSearch(!search)}
                                            type="button"
                                          >
                                            <i className="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </header>
                          <main
                            className={`messages-box--chat-area ${viewAdminMessages && "h-100"
                              }`}
                          >
                            {showChat && !viewAdminMessages && (
                              <Chat
                                socket={socket}
                                pagination={pagination}
                                setFounds={setFounds}
                                founds={founds}
                                searchString={searchString}
                                username={username}
                                room={room}
                                proposal_id={proposal_id}
                                freelancer_id={freelancer_id}
                                hire_manager_id={hire_manager_id}
                              />
                            )}
                            {viewAdminMessages && (
                              <AdminChat
                                MessagesItems={
                                  AdminMessagesItemData?.adminMessages
                                }
                                searchString={searchString}
                                username={"Admin"}
                              />
                            )}
                          </main>
                        </aside>
                      )}
                    </>
                  )}
                </main>
              </section>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const mapStateToProp = (state) => ({
  auth: state.auth,
  proposalAndContract: state.proposalAndContract,
});

export default connect(mapStateToProp, {
  proposalGetByClient,
  messageGetbyId,
  getAdminMessages,
  messageSummary
})(Messages);
