import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { setAlert } from "../../actions/alert";
import { useDispatch } from "react-redux";
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import Logo from '../../components/logo/Logo';
import useSweetAlert from '../../hooks/useSweetAlert';

const Question4 = () => {
  const { formType } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useSweetAlert();


  // Define the storage key based on the form type
  const storageKey = `answers4-${formType}`;

  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem(storageKey);
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const fieldRefs = useRef({});

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    scrollToTop();
  }, []);


  useEffect(() => {
    // Store answers with the appropriate storage key
    localStorage.setItem(storageKey, JSON.stringify(answers));
  }, [answers, storageKey]); // Include storageKey in the dependency array


  useEffect(() => {
    // Retrieve answers with the appropriate storage key
    const savedAnswers = localStorage.getItem(storageKey);
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, [storageKey]); // Include storageKey in the dependency array


  /*const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const unansweredQuestions = questions[formType].filter(question => !answers.hasOwnProperty(question.id) || answers[question.id].trim() === '');

    if (unansweredQuestions.length === 0) {
      history.push(`/question5/${formType}`);
    } else {
      const errorMessage = unansweredQuestions.map(question => {
        const fieldName = question.question;
        return `${fieldName} is required.`;
      }).join(' ');

      //dispatch(setAlert(errorMessage, 'danger'));
    }
  };*/

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const unansweredQuestions = questions[formType].filter(question => !answers.hasOwnProperty(question.id) || answers[question.id].trim() === '');

    if (unansweredQuestions.length === 0) {
      history.push(`/question5/${formType}`);
    } else {
      // showToast('Please fill all the required fields!', 'error');
      const errorMessage = unansweredQuestions.map(question => {
        const fieldName = question.question;
        return `${fieldName} is required.`;
      }).join(' ');

      const firstUnansweredQuestionId = unansweredQuestions[0].id;
      const firstErrorField = fieldRefs.current[firstUnansweredQuestionId];
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' });
      }

      //dispatch(setAlert(errorMessage, 'danger'));
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };



  const placeholders = {
    '4.1': {
      individual: "Level of Expertise",
      company: "Level of experience"
    },
    '4.2': {
      individual: "Specific Certifications or Qualifications",
      company: "Specific Certifications or Qualifications"
    },
    '4.3': {
      company: "Industry-Specific Experience"
    }
  };



  const questions = {
    individual: [
      {
        id: 4.1,
        question: "What level of expertise are you seeking in a cybersecurity expert?",
        key: 'expertiseLevel',
        options: [
          'Entry Level',
          'Intermediate',
          'Expert'
        ]
      },
      {
        id: 4.2,
        question: "Are there any specific certifications or qualifications that you expect the cybersecurity expert to have?",
        key: 'certifications'
      }
    ],
    company: [
      {
        id: 4.1,
        question: "What level of experience are you looking for in cybersecurity experts?",
        key: 'experienceLevel',
        options: ['Entry Level', 'Intermediate', 'Expert']
      },
      {
        id: 4.2,
        question: "Are there any specific certifications or qualifications that you require from cybersecurity experts? If so, please specify.",
        key: 'certifications'
      },
      {
        id: 4.3,
        question: "Do you prefer candidates with industry-specific experience (e.g., finance, healthcare, government)?",
        key: 'industryExperience'
      }
    ]
  };

  const currentQuestions = questions[formType] || [];

  return (
    <section className="main-page page-dashboard" style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      height: '100vh', backgroundColor: '#f4f4f4', maxWidth: "100vw", overflowX: "hidden"
    }}>
      <div className="container" style={{ maxWidth: '1200px' }}>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className='w-100 row row justify-content-center d-flex d-sm-none'>


              <Logo />
            </div>
            <div className="bg-white rounded shadow-sm sidebar-page-right" style={{ padding: '2rem' }}>
              <Link to={`/question3/${formType}`} className=" text-dark d-flex mb-2"
              style={{alignItems : "center" , gap: "3px"}}
              >
                <KeyboardBackspaceOutlined />  Back

              
              </Link>
              <div className="p-3">
                <h4> {formType === 'individual' ? 'Individual' : 'Company'}</h4>
                <h3 className="text-primary">Experience and Qualifications</h3>
                <p >
                  <span /*style={{ textDecoration: "underline" }}*/ className="font-weight-bold">04</span> of 10
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="scrollable">
                    {currentQuestions.map((question) => (
                      <div key={question.id} ref={el => fieldRefs.current[question.id] = el}>
                        <h6 className="font-weight-normal mt-4">{question.question}</h6>
                        {question.options ? (
                          <div className="form-group" style={{ marginBottom: "30px" }}>
                            <select
                              className="form-control"
                              value={answers[question.id] || ''}
                              onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                            >
                              <option>Select</option>
                              {question.options.map((option, optionIndex) => (
                                <option key={optionIndex} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            { // Conditionally render error message if form submitted and answer is empty
                              formSubmitted && !answers[question.id]?.trim() && (
                                <div className="text-danger mt-2">
                                  {`${question.question} is required.`}
                                </div>
                              )
                            }
                          </div>
                        ) : (
                          <div className="form-group" style={{ marginBottom: "30px" }} >
                            <input type="text" className="form-control" placeholder={"Enter " + placeholders[question.id][formType]} value={answers[question.id] || ''}  onChange={(e) => { 
                                const value = e.target.value;
                                const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                                handleAnswerChange(question.id, capitalizedValue);}} />
                            { // Add conditional rendering for error message
                              formSubmitted && !answers[question.id]?.trim() && (
                                <div className="text-danger mt-2">
                                  {question.question} is required.
                                  {/* Please fill the required field. */}
                                </div>
                              )
                            }
                          </div>
                        )}

                      </div>
                    ))}
                  </div>
                  {/** <div className="d-flex justify-content-end pb-3">
                    <div className="text-right">
                      <Link to={`/question3/${formType}`} className="btn btn-default mr-3">Back</Link>
                      <button type="submit" className="btn btn-primary">Save & Next</button>
                    </div>
                  </div>
                  
                  <div className="row justify-content-center forms_buttons">
                    <div className="text-right mm-2">
                        <Link to={`/question3/${formType}`}  className="btn btn-default mr-3">Back</Link>
                        {/*<Link to="/education" className="c-btn c-fill-color-btn ">Next: Education</Link>
                        
                          <button type="submit" className="btn btn-primary">
                            Save & Next
                          </button>
                    </div>
                    </div>*/}
                    <div className='w-100 bg-white d-flex justify-content-end' style={{  }}>
                    <button type="submit" className="btn btn-primary wm-100 mr-2" style={{ width: '95% !important' }}>
                        Save & Next
                      </button>
                    </div>

                  {/* <div className="row forms_buttons w-100 w-sm-96" style={{ width: '96%' }}>
                    <div className="col-md-5 text-left d-none d-sm-block">

                      {/* <Link to={`/question3/${formType}`} className="btn btn-default  ">
                        Back

                      </Link> */}
                    {/* </div>
                    <div className="col-sm-11 col-md-6 col-11 text-right forms_buttons_SaveNext mr-4 mr-md-0" >

                      <button type="submit" className="btn btn-primary wm-100 " style={{ width: '95% !important' }}>
                        Save & Next
                      </button>
                    </div>
                  </div> */} 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question4;
