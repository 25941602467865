import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { remove } from "react-icons-kit/fa/remove";
import { connect } from "react-redux";
import { Recomendations, Hire, get_user_check, AddtoAdminWallet, clearRecommendations, getChat } from "../../actions/enterprise";
import { EnterprseIcon1, EnterprseIcon2, EnterprseIcon3, EnterprseIcon4, EnterprseIcon5, EnterprseIcon6 } from "../../components/SVG";
import form from "./form.png"
import client from "./client.png"
import expert from "./expert.png"
import hiring from "./hiring.png"
import chat from "./chat.png"
import budget from "./budget.png"

import { register } from "../../actions/auth";

import Input from "../../components/input/input"
import {
  contractGetbyId,
} from "../../actions/proposalAndContract";

import { CircularProgress } from "@material-ui/core";
import { ArrowForward, KeyboardArrowLeftSharp, KeyboardBackspaceOutlined } from "@material-ui/icons";


const individualQuestionsMapping = {
  "Personal Information": {
    "1_1": "What is your full name?",
    "1_2": "What is your current occupation or role?",
    "1_3": "Can you provide a brief overview of your cybersecurity knowledge and experience?"
  },
  "Cybersecurity Needs": {
    "2_1": "What specific areas of cybersecurity assistance do you require?"
  },
  "Goals and Objectives": {
    "3_1": "What are your primary goals or objectives in seeking cybersecurity assistance?",
    "3_2": "Are there any specific cybersecurity challenges or concerns that you are currently facing?"
  },
  "Experience and Qualifications": {
    "4_1": "What level of expertise are you seeking in a cybersecurity expert?",
    "4_2": "Are there any specific certifications or qualifications that you expect the cybersecurity expert to have?"
  },
  "Project Details": {
    "5_1": "Can you provide more information about the cybersecurity project(s) or tasks that you need assistance with?",
    "5_2": "What is the anticipated duration or timeline for the cybersecurity assistance?"
  },
  "Budget and Compensation": {
    "6_1": "What is your budget for hiring a cybersecurity expert or obtaining cybersecurity services?",
    "6_2": "Are you willing to pay for ongoing cybersecurity maintenance or support services?"
  },
  "Remote Work and Flexibility": {
    "7_1": "Are you open to working with a cybersecurity expert who can provide remote assistance?",
    "7_2": "Do you require any flexibility in terms of scheduling or availability?"
  },
  "Preferred Communication and Collaboration": {
    "8_1": "How do you prefer to communicate with the cybersecurity expert?",
    "8_2": "Are you comfortable using collaboration tools or platforms for sharing documents and information securely?"
  },
  "Additional Requirements": {
    "9_1": "Are there any specific tools, software, or technologies that you expect the cybersecurity expert to use or be familiar with?",
    "9_2": "Do you have any specific preferences or requirements regarding the cybersecurity expert's approach or methodology?"
  },
  "Contact Information": {
    "10_1": "What is the best way to reach you for further discussion or follow-up?",
    "10_2": "What is your email address?",
    "10_3": "What is your phone number?"
  }
};

const companyQuestionsMapping = {
  "Company Information": {
    "1_1": "What is the name of your company?",
    "1_2": "Describe your company's core business and industry focus.",
    "1_3": "How many employees does your company currently have?"
  },
  "Cybersecurity Needs": {
    "2_1": "What specific areas of cybersecurity expertise are you seeking?"
  },
  "Goals and Objectives": {
    "3_1": "What level of experience are you looking for in cybersecurity experts?",
    "3_2": "Are there any specific certifications or qualifications that you require?"
  },
  "Experience and Qualifications": {
    "4_1": "What level of experience are you looking for in cybersecurity experts?",
    "4_2": "Are there any specific certifications or qualifications that you require from cybersecurity experts? If so, please specify.",
    "4_3": "Do you prefer candidates with industry-specific experience (e.g., finance, healthcare, government)?"
  },
  "Project Details": {
    "5_1": "Can you provide more information about the cybersecurity project(s) that the expert will be working on?",
    "5_2": "What is the anticipated duration of the project(s)?",
    "5_3": "Will the cybersecurity expert work independently or as part of a team?",
    "5_4": "Are there any specific tools or technologies that the cybersecurity expert should be proficient in?"
  },
  "Budget and Compensation": {
    "6_1": "What is your budget for hiring cybersecurity experts?",
    "6_2": "Are you offering a competitive salary and benefits package?",
    "6_3": "Do you offer additional perks or incentives?"
  },
  "Remote Work and Flexibility": {
    "7_1": "Are you open to hiring cybersecurity experts who work remotely?",
    "7_2": "Do you offer flexible work arrangements or remote work options?"
  },
  "Recruitment Process": {
    "8_1": "What is the timeline for the project?",
    "8_2": "Can you outline the steps involved in the selection process?",
    "8_3": "Will there be any technical assessments or interviews as part of the selection process?"
  },
  "Additional Services": {
    "9_1": "Are there any additional services or support that you require?",
    "9_2": "Do you have any specific expectations or requirements for ongoing collaboration?"
  },
  "Contact Information": {
    "10_1": "What is the name of the primary contact person?",
    "10_2": "What is the email address of the primary contact person?",
    "10_3": "What is the phone number of the primary contact person?"
  }
};

const DisputeOffcanvas = ({
  show,
  onHide,
  enterpriseData,
  enterprise: { recomendations, Chat },
  register,
  Hire,
  get_user_check,
  getChat,
  AddtoAdminWallet,
  clearRecommendations,
  contractGetbyId
}) => {
  const [disputeSubmitted, setDisputeSubmitted] = useState(false);

  const [showBudget, setshowBudget] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showInviteButton, setshowInviteButton] = useState(true);

  const [aiLoading, setAiLoading] = useState(false);

  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(null);
  const [showContractButton, setShowContractButton] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [freelancerData, setFreelancerData] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (show && enterpriseData) {
      const companyAnswers = enterpriseData?.answers?.[0]?.["answers10-company"] ?? {};
      const individualAnswers = enterpriseData?.answers?.[0]?.["answers10-individual"] ?? {};

      let email = companyAnswers['10_2'];
      if (!email) {
        email = individualAnswers['10_2'];
      }
      if (email) {
        getChat(email);
        get_user_check(email).then((res) => {
          if (res === 'Already exist') {
            setshowInviteButton(false);
          }
        });
      }
    }
  }, [show, enterpriseData]);


  useEffect(() => {
    if (show) {
      if (enterpriseData.contract_id) {
        const id = enterpriseData.contract_id;
        contractGetbyId(id).then((res) => {
          if (res) {
            //console.log(res.data)
            setFreelancerData(res.data)
            if (res.data.contract_status === "Finished") {
              setShowContractButton(false);
            }
          }
        });
      }

    }
  }, [show, enterpriseData]);


  const [formData, setFormData] = useState({
    hourly_rate: 0,
    wallet_amount: 0
  });

  const { hourly_rate, wallet_amount } = formData;


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setshowInviteButton(true);
    setFreelancerData("");
    setSelectedId(null);
    setShowContractButton(true);
    dispatch(clearRecommendations()); // Clear recommendations on modal close
    onHide();
  };

  const handler = () => {
    if (showBudget) {
      setshowBudget(false)
    }
    else {
      setshowBudget(true)
    }

  }

  const handler2 = () => {
    if (showAdd) {
      setShowAdd(false)
    }
    else {
      setShowAdd(true)
    }

  }


  const viewContract = (id) => {
    contractGetbyId(id).then((res) => {
      if (res) {
        history.push("/contractDetails");
      }
    });
  }


  const generatePassword = () => {
    const length = 12; // Length of the password
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };

  const handleRowClick = (id) => {
    setSelectedId(id);
  };

  const add_to_wallet = async (enterpriseData) => {
    const companyAnswers = enterpriseData?.answers?.[0]?.["answers10-company"] ?? {};
    const individualAnswers = enterpriseData?.answers?.[0]?.["answers10-individual"] ?? {};

    let email = companyAnswers['10_2'];
    if (!email) {
      email = individualAnswers['10_2'];
    }
    if (email) {
      AddtoAdminWallet(wallet_amount, email)
    }

  }

  const hireContract = async (enterpriseData) => {
    const companyData = enterpriseData.answers[0]["answers10-company"];
    const individualData = enterpriseData.answers[0]["answers10-individual"];

    const email = companyData?.['10_2'] ?? individualData?.['10_2'];
    const description = enterpriseData.answers[0]["answers3-company"]?.['3_1'] ?? enterpriseData.answers[0]["answers3-individual"]?.['3_1'];
    const headline = enterpriseData.answers[0]["answers3-company"]?.['3_2'] ?? enterpriseData.answers[0]["answers3-individual"]?.['3_2'];
    const level = enterpriseData.answers[0]["answers4-company"]?.['4_1'] ?? enterpriseData.answers[0]["answers4-individual"]?.['4_1'];
    const duration = enterpriseData.answers[0]["answers5-company"]?.['5_2'] ?? enterpriseData.answers[0]["answers5-individual"]?.['5_2'];
    const skills = enterpriseData.answers[0]["answers2-company"]?.['2_1'] ?? enterpriseData.answers[0]["answers2-individual"]?.['2_1'];
    const amount = hourly_rate;
    const freelancer_id = selectedId;

    Hire(email, description, headline, level, duration, skills, amount, freelancer_id);
  }


  const userRegistration = async (enterpriseData) => {
    const password = generatePassword();
    const companyData = enterpriseData.answers[0]["answers10-company"];
    const individualData = enterpriseData.answers[0]["answers10-individual"];
    const userData = companyData ?? individualData;

    if (userData) {
      const email = userData['10_2'];
      const username = email.split('@')[0];
      const firstName = userData['10_1'];
      //const lastName = (companyData ?? enterpriseData.answers[0]["answers1-individual"])['1_1'];
      const lastName = enterpriseData.answers[0]["answers1-company"]?.['1_1'] ?? enterpriseData.answers[0]["answers1-individual"]?.['1_1'];
      const type = 'Enterprise Client';
      const location = 'Not defined';


      if (email && username && firstName && lastName) {
        register({
          user_name: username,
          email: email,
          first_name: firstName,
          last_name: lastName,
          password: password,
          type: type,
          location: location,
        });
      } else {
        console.error("Missing required fields");
      }
    } else {
      console.error("No user data found");
    }

  };


  const handleResolveDispute = async (enterpriseData) => {
    try {
      const companyData = enterpriseData.answers[0]["answers2-company"];
      const individualData = enterpriseData.answers[0]["answers2-individual"];

      const cyberNeedsArray = Object.values(companyData?.["2_1"] ?? individualData?.["2_1"]);

      // Extract and structure the enterprise data
      setAiLoading(true);
      const recommendationPayload = {
        cyber_needs: cyberNeedsArray,
        experience_level: enterpriseData.answers[0]["answers4-company"]?.['4_1'] ?? enterpriseData.answers[0]["answers4-individual"]?.['4_1'],
        budget: enterpriseData.answers[0]["answers6-company"]?.['6_1'] ?? enterpriseData.answers[0]["answers6-individual"]?.['6_1'],
        certifications: enterpriseData.answers[0]["answers4-company"]?.['4_2'] ?? enterpriseData.answers[0]["answers4-individual"]?.['4_2'],
        location: enterpriseData.answers[0]["answers9-company"]?.['9_1'] ?? enterpriseData.answers[0]["answers9-individual"]?.['9_1'],
        language: "English"
      };

      await dispatch(Recomendations(recommendationPayload, companyData ? "company" : "individual"));
      //console.log("data of client", recommendationPayload);

      //console.log("Recommendations fetched successfully");
      setDisputeSubmitted(true);
      setAiLoading(false);
    } catch (error) {
      console.error("Error fetching recommendations:", error);
      setAiLoading(false);
    }
  };


  const renderRecommendationsTable = () => {

    if (aiLoading) return <CircularProgress />;


    if (!recomendations || recomendations.length === 0) return <p>No Recommendations Found</p>;

    return (
      <table className="table rec-table" >
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Score</th>
            <th>Skills</th>
            <th>Rate</th>
            <th>Experience</th>
            <th>Location</th>
            <th>Certifications</th>
            <th>Language</th>
          </tr>
        </thead>
        <tbody>
          {recomendations.map((rec, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(rec.id)}
              style={{ backgroundColor: rec.id === selectedId ? '#d3f9d8' : 'transparent' }} // Highlight selected row
            >
              <td>{rec.id === selectedId && '✓'}</td> {/* Display tick sign if the row is selected */}
              <td>{rec.name}</td>
              <td>{rec.score}</td>
              <td style={{ width: "36%" }}>{rec.skills}</td>
              <td>{rec.rate}</td>
              <td>{rec.experience}</td>
              <td>{rec.location}</td>
              <td>{rec.certifications}</td>
              <td>{rec.language}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderQuestionsAndAnswers = (answers, questionsMapping, type) => {
    const sectionKeyMapping = {
      company: {
        "Company Information": "answers1-company",
        "Cybersecurity Needs": "answers2-company",
        "Goals and Objectives": "answers3-company",
        "Experience and Qualifications": "answers4-company",
        "Project Details": "answers5-company",
        "Budget and Compensation": "answers6-company",
        "Remote Work and Flexibility": "answers7-company",
        "Recruitment Process": "answers8-company",
        "Additional Services": "answers9-company",
        "Contact Information": "answers10-company"
      },
      individual: {
        "Personal Information": "answers1-individual",
        "Cybersecurity Needs": "answers2-individual",
        "Goals and Objectives": "answers3-individual",
        "Experience and Qualifications": "answers4-individual",
        "Project Details": "answers5-individual",
        "Budget and Compensation": "answers6-individual",
        "Remote Work and Flexibility": "answers7-individual",
        "Preferred Communication and Collaboration": "answers8-individual",
        "Additional Requirements": "answers9-individual",
        "Contact Information": "answers10-individual"
      }
    };

    return Object.entries(questionsMapping).map(([sectionKey, sectionQuestions]) => {
      const sectionAnswerKey = sectionKeyMapping[type][sectionKey];
      const sectionAnswers = answers[sectionAnswerKey];
      if (!sectionAnswers) return null;

      return (
        <div key={sectionKey} style={{ marginBottom: "1rem" }}>
          <h4>{sectionKey}</h4>
          {Object.entries(sectionQuestions).map(([questionKey, question]) => {
            let answer = sectionAnswers[questionKey];

            // Convert objects to comma-separated string of values
            if (typeof answer === 'object') {
              answer = Object.values(answer).join(', ');
            } else if (answer === undefined || answer === null) {
              answer = "N/A"; // Handle undefined or null values
            }

            return (
              <div key={questionKey} style={{ marginBottom: "0.5rem" }}>
                <strong>Question:</strong> {question}: {answer}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderClientInfo = (answers, questionsMapping) => {
    const sectionKeyMapping = {
      "Company Information": "answers1-company",

      "Contact Information": "answers10-company",

      "Personal Information": "answers1-individual",

      "Contact Information (Individual)": "answers10-individual"
    };



    return Object.entries(questionsMapping).map(([sectionKey, sectionQuestions]) => {
      const sectionAnswerKey = sectionKeyMapping[sectionKey];
      const sectionAnswers = answers[sectionAnswerKey];
      if (!sectionAnswers) return null;

      return (
        <div key={sectionKey} style={{ marginBottom: "1rem" }}>
          <h4>{sectionKey}</h4>
          {Object.entries(sectionQuestions).map(([questionKey, question]) => {
            let answer = sectionAnswers[questionKey];

            // Convert objects to comma-separated string of values
            if (typeof answer === 'object') {
              answer = Object.values(answer).join(', ');
            } else if (answer === undefined || answer === null) {
              answer = "N/A"; // Handle undefined or null values
            }

            return (
              <div key={questionKey} style={{ marginBottom: "0.5rem" }}>
                <strong>Question:</strong> {question}: {answer}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderAllQuestionsAndAnswers = () => {
    if (!enterpriseData || !enterpriseData.answers || enterpriseData.answers.length === 0) {
      return <p>No data available</p>;
    }

    const answers = enterpriseData.answers[0];

    return (
      <>
        {renderQuestionsAndAnswers(answers, companyQuestionsMapping, 'company')}
        {renderQuestionsAndAnswers(answers, individualQuestionsMapping, 'individual')}
      </>
    );
  };

  const renderAllClientInfo = () => {
    if (!enterpriseData || !enterpriseData.answers || enterpriseData.answers.length === 0) {
      return <p>No data available</p>;
    }
    const answers = enterpriseData.answers[0];

    return (
      <>
        {renderClientInfo(answers, individualQuestionsMapping)}
      </>
    );
  };



  const chatHistoryStyles = {
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "15px",
  };

  const chatHistorySection = {
    maxHeight: "400px",
    overflowY: "auto",
  };


  const messageStyles = {
    display: "flex",
    marginBottom: "10px",
  };

  const messageBoxStyles = {
    maxWidth: "80%",
    padding: "10px",
    borderRadius: "10px",
    margin: "5px",
  };

  const hireManagerMessageStyles = {
    ...messageBoxStyles,
    backgroundColor: "#e0e0e0",
    marginLeft: "auto",
    textAlign: "right",
  };

  const freelancerMessageStyles = {
    ...messageBoxStyles,
    backgroundColor: "#f0f0f0",
    marginRight: "auto",
    textAlign: "left",
  };


  const sectionTitleStyle = {
    marginBottom: "10px",
  };

  const uniqueUsernames =
    Chat
      ? [...new Set(Chat.map((msg) => msg.user_name))]
      : [];

  const ChatMessage = ({ message }) => {
    // Determine if the message is from the first unique user. If so, align it to one side; otherwise, align to the other.
    const isFirstUserMessage = message.user_name === uniqueUsernames[0];

    // Use dynamic styling based on the sender
    const messageStyle = isFirstUserMessage
      ? freelancerMessageStyles
      : hireManagerMessageStyles;

    return (
      <div style={{ ...messageStyles }}>
        <div style={messageStyle}>
          <p>{message.message_text}</p>
          <small>{new Date(message.message_date).toLocaleString()}</small>
        </div>
      </div>
    );
  };


  return (
    <>
      <Modal show={show} size="xl" onHide={onHide} centered>
        <Modal.Header className="pb-0 d-flex justify-content-between align-items-center">
          
            <div className="mx-2 my-2 d-flex">
            {selectedOption !== "" ? <KeyboardBackspaceOutlined onClick={() => setSelectedOption("")} /> : null} 
            <Modal.Title className="ml-2"> Enterprise Form</Modal.Title>

            </div>
          <Icon className="mx-2 icon-hover" icon={remove} onClick={handleClose} />
        </Modal.Header>

        <Modal.Body className="dispute-modal">
          <>
            {selectedOption === "Budget and Billing" ?
              <>
                {enterpriseData?.contract_id && (
                  <div>
                    <h3>Payment Release Request</h3>
                    {showContractButton ? (
                      <Button className="btn-secondary mb-4" onClick={() => viewContract(enterpriseData.contract_id)}>
                        View Details
                      </Button>
                    ) : (
                      <p>Status: Payment Released</p>
                    )}
                  </div>
                )}

                {enterpriseData?.budget_paid ? (
                  <div>
                    <h3>Budget</h3>
                    <h4>{enterpriseData.budget + "$"}</h4>
                    <p>{"Status: Paid"}</p>
                    <br />
                  </div>
                ) : (
                  <div>
                    <h3>Budget</h3>
                    <p>{"Status: Unpaid"}</p>
                    <br />
                  </div>
                )}

                {enterpriseData?.photo_link && (
                  <>
                    <a href={enterpriseData.photo_link} target="_blank" rel="noopener noreferrer">
                      <div style={{ width: '300px', height: '300px', overflow: 'hidden' }}>
                        <img
                          src={enterpriseData.photo_link}
                          alt="Profile picture"
                          style={{ width: '100%', height: 'auto' }}
                        />
                      </div>
                    </a>
                    {!enterpriseData.budget_paid && (
                      <Button className="btn-secondary mb-4" onClick={() => handler2()}>
                        Add to wallet
                      </Button>
                    )}
                  </>
                )}
              </>
              : selectedOption === "Form Details" ?
                <>
                  <div>
                    <h3>Details</h3>
                    <div>{renderAllQuestionsAndAnswers()}</div>
                  </div>
                </>
                : selectedOption === "Expert Hiring" ?
                  <>
                    <div className="d-flex justify-content-end mb-4" style={{ gap: "3px" }}>
                      {selectedId && (

                        <Button className="btn-secondary" onClick={() => handler()}>
                          Hire Expert
                        </Button>
                      )}
                      <Button className="btn-primary" onClick={() => handleResolveDispute(enterpriseData)}>
                        AI Algorithm
                      </Button>

                    </div>

                    <h3>Recommendations</h3>
                    <div className="rec-milestone-table">{renderRecommendationsTable()}</div>
                  </>

                  : selectedOption === "Client Details" ?
                    <>
                      {showInviteButton ? (
                        <Button className="btn-secondary" onClick={() => userRegistration(enterpriseData)}>
                          Invite Client
                        </Button>
                      )
                        :
                        <div>{renderAllClientInfo()}</div>

                      }
                    </>
                    : selectedOption === "Chat history" ? (
                      <>{/*console.log("chats", Chat)*/}
                        <div style={{ ...chatHistoryStyles }}>
                          <h3 style={{ ...sectionTitleStyle }}>Chat History</h3>
                          <div style={{ ...chatHistorySection }}>
                            {Chat && Chat.length > 0 ? (
                              <div>
                                {Chat.map((message, index) => (
                                  <ChatMessage key={index} message={message} />
                                ))}
                              </div>
                            ) : (
                              <p>No messages available.</p>
                            )}
                          </div>
                        </div>
                      </>
                    )
                      : selectedOption === "Expert Details" ? (
                        <>
                          {freelancerData !== "" ?
                            <div className="talent-modal">
                              <div className="container">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="title">
                                      Talent Details -{" "}
                                      <span>
                                        {freelancerData?.freelancer_id?.user_id?.first_name + " " + freelancerData?.freelancer_id?.user_id?.last_name}
                                      </span>
                                    </div>
                                    <br></br>
                                    <h6 className="text-primary">Title</h6>
                                    <p className="text-muted text-justify">
                                      {freelancerData?.freelancer_id?.titleAndOverview?.title}
                                    </p>
                                    <h6 className="text-primary">Expertise category</h6>
                                    <p className="text-muted">
                                      { }
                                    </p>
                                    <h6 className="text-primary">Expertise</h6>
                                    <p className="text-muted">
                                      {freelancerData?.freelancer_id?.expertise?.skills}
                                    </p>
                                    <h6 className="text-primary">Expert Level</h6>
                                    <p className="text-muted">
                                      {freelancerData?.freelancer_id?.expertLevel?.expert_level}
                                    </p>
                                    <h6 className="text-primary">Hourly Rate</h6>
                                    <p className="text-muted">
                                      {freelancerData?.freelancer_id?.hourlyRate.hourly_rate + "$"}
                                    </p>
                                    {freelancerData?.freelancer_id?.education ? (
                                      <>
                                        <h6 className="text-primary">Education</h6>
                                        <p className="text-muted">
                                          {`
                                  ${freelancerData?.freelancer_id?.education?.degree
                                              ? freelancerData?.freelancer_id?.education
                                                ?.degree + " - "
                                              : ""
                                            }
                                  ${freelancerData?.freelancer_id?.education
                                              ?.specialization
                                              ? freelancerData?.freelancer_id?.education
                                                ?.specialization + " - "
                                              : ""
                                            }
                                  ${freelancerData?.freelancer_id?.education
                                              ?.provider
                                              ? freelancerData?.freelancer_id?.education
                                                ?.provider
                                              : ""
                                            }
                                  `}
                                        </p>
                                      </>
                                    ) : (
                                      <p>Not provided</p>
                                    )}
                                    <h6 className="text-primary">Location</h6>
                                    <p className="text-muted">
                                      {freelancerData?.freelancer_id?.location.country}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : <h6 className="text-primary">No Data Found</h6>}
                        </>
                      )
                        :
                        (
                          <div className="d-flex justify-content-center align-items-center ">
                            <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ height: '70%' }}>
                              <div className="col-md-4 d-flex justify-content-center align-items-center mb-3 mt-1 ">
                                <div className="enterprise-box">
                                  {/** <img src={form} width={60} className="mb-2" />*/}
                                  <div className="enterprise-icon"><EnterprseIcon2 /></div>
                                  <h5 className="location-text" style={{fontWeight:"800"}}>Form Details</h5>
                                  <p className="mb-3 location-text" style={{fontWeight:"400" , color:"#000000"}}>We help identify the best ways to improve your business</p>
                                  <Button className="btn-primary d-flex align-items-center p-2" onClick={() => setSelectedOption("Form Details")}>
                                    <span className="location-text" style={{fontWeight:"400" , color:"#000000"}}>Explore Now</span> <ArrowForward />
                                  </Button>
                                </div>
                              </div>

                              <div className="col-md-4 d-flex justify-content-center align-items-center mb-3 mt-1">
                                <div className="enterprise-box grey-background rounded-corners">
                                  {/** <img src={client} width={60} className="mb-2" />*/}
                                  <div className="enterprise-icon"><EnterprseIcon1 /></div>
                                  <h5 className="location-text" style={{fontWeight:"800"}}>Client Details</h5>
                                  <p className="mb-3 location-text" style={{fontWeight:"400" , color:"#000000"}}>We help identify the best ways to improve your business</p>
                                  <Button className="btn-primary d-flex align-items-center p-2" onClick={() => setSelectedOption("Client Details")}>
                                  <span className="location-text" style={{fontWeight:"400" , color:"#000000"}}>Explore Now</span> <ArrowForward />
                                  </Button>
                                </div>
                              </div>

                              <div className="col-md-4 d-flex justify-content-center align-items-center mb-3 mt-1">
                                <div className="enterprise-box">
                                  {/** <img src={hiring} width={60} className="mb-2" />*/}
                                  <div className="enterprise-icon"><EnterprseIcon4 /></div>
                                  <h5 className="location-text" style={{fontWeight:"800"}}>Expert Hiring</h5>
                                  <p className="mb-3 location-text" style={{fontWeight:"400" , color:"#000000"}}>We help identify the best ways to improve your business</p>
                                  <Button className="btn-primary d-flex align-items-center p-2" onClick={() => setSelectedOption("Expert Hiring")}>
                                  <span className="location-text" style={{fontWeight:"400" , color:"#000000"}}>Explore Now</span><ArrowForward />
                                  </Button>
                                </div>
                              </div>

                              <div className="col-md-4 d-flex justify-content-center align-items-center mb-3 mt-1">
                                <div className="enterprise-box grey-background rounded-corners">
                                  {/** <img src={expert} width={60} className="mb-2" />*/}
                                  <div className="enterprise-icon"><EnterprseIcon6 /></div>
                                  <h5 className="location-text" style={{fontWeight:"800"}}>Expert Details</h5>
                                  <p className="mb-3 location-text" style={{fontWeight:"400" , color:"#000000"}}>We help identify the best ways to improve your business</p>
                                  <Button className="btn-primary d-flex align-items-center p-2" onClick={() => setSelectedOption("Expert Details")}>
                                  <span className="location-text" style={{fontWeight:"400" , color:"#000000"}}>Explore Now</span> <ArrowForward />
                                  </Button>
                                </div>
                              </div>

                              <div className="col-md-4 d-flex justify-content-center align-items-center mb-3 mt-1">
                                <div className="enterprise-box">
                                  {/** <img src={chat} width={60} className="mb-2" />*/}
                                  <div className="enterprise-icon"><EnterprseIcon5 /></div>
                                  <h5 className="location-text" style={{fontWeight:"800"}}>Chat History</h5>
                                  <p className="mb-3 location-text" style={{fontWeight:"400" , color:"#000000"}}>We help identify the best ways to improve your business</p>
                                  <Button className="btn-primary d-flex align-items-center p-2" onClick={() => setSelectedOption("Chat history")}>
                                  <span className="location-text" style={{fontWeight:"400" , color:"#000000"}}>Explore Now</span> <ArrowForward />
                                  </Button>
                                </div>
                              </div>

                              <div className="col-md-4 d-flex justify-content-center align-items-center mb-3 mt-1">
                                <div className="enterprise-box grey-background rounded-corners">
                                  {/**<img src={budget} width={60} className="mb-2" /> */}
                                  <div className="enterprise-icon"><EnterprseIcon3 /></div>
                                  <h5 className="location-text" style={{fontWeight:"800"}}>Budget & Billing</h5>
                                  <p className="mb-3 location-text" style={{fontWeight:"400" , color:"#000000"}}>We help identify the best ways to improve your business</p>
                                  <Button className="btn-primary d-flex align-items-center p-2" onClick={() => setSelectedOption("Budget and Billing")}>
                                  <span className="location-text" style={{fontWeight:"400" , color:"#000000"}}>Explore Now</span> <ArrowForward />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>

                        )



            }
          </>

        </Modal.Body>
      </Modal>
      <Modal
        show={showBudget}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mt-1">
          <Modal.Title>Select Budget</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-0">
          <div
            className="row mx-3"
            style={{ marginLeft: "0px", marginTop: "15px" }}
          >

          </div>
          <input
            type="number"
            className="form-control"
            placeholder="0$"
            name="hourly_rate"
            value={hourly_rate}
            onChange={(e) => onChange(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hireContract(enterpriseData)}>
            Send Contract
          </Button>
          <Button variant="secondary" onClick={handler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAdd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mt-1">
          <Modal.Title>Add Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-0">
          <div
            style={{ marginLeft: "0px", marginBottom: "15px" }}
          >
            <p>Enter the exact amount mentioned in the slip/reciept.</p>
          </div>
          <input
            type="number"
            className="form-control"
            placeholder="0$"
            name="wallet_amount"
            value={wallet_amount}
            onChange={(e) => onChange(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => add_to_wallet(enterpriseData)}>
            Add
          </Button>
          <Button variant="secondary" onClick={handler2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  enterprise: state.enterprise
});

export default connect(mapStateToProps, { Recomendations, register, Hire, get_user_check, AddtoAdminWallet, contractGetbyId, clearRecommendations, getChat })(DisputeOffcanvas);
