import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Alert from '../../components/alert/Alert';
import { addExpertise } from "../../actions/freelancerProfile";
import { skillsGet } from "../../actions/clientProfile";
import Select from 'react-select';
import { KeyboardBackspaceOutlined } from "@material-ui/icons";


const Expertise = ({ freelancerProfile: { expertise, freelancerprofile }, setAlert, addExpertise, isAuthenticated, clientProfile: { skills }, skillsGet }) => {


  const [formData, setFormData] = useState({
    category: "",
    skillsSelected: expertise.skills && expertise.skills.length > 0
      ? expertise.skills.split(',').map(skill => ({ value: skill, label: skill }))
      : []
  });

  useEffect(() => {
    //console.log(expertise)
    if (expertise && expertise.skills) {
      const initialSkills = expertise.skills.split(',').map(skill => {
        const trimmedSkill = skill.trim();
        return { value: trimmedSkill, label: trimmedSkill };
      });
      setFormData({ ...formData, skillsSelected: initialSkills });
    }
  }, [expertise]);

  useEffect(() => {
    //console.log(skills);
    //console.log(expertise);
    skillsGet();
  }, []);


  useEffect(() => {
    document.title = 'Expertise';
  }, []);

  useEffect(() => {
    debugger;
    let check = JSON.parse(localStorage.getItem('expertise'));
    if (check) {
      setFormData(expertise);
    }

  }, []);

  const { category, skillsSelected1 } = formData;

  const [skillsSelected, setSkillsSelected] = useState([]);

  let history = useHistory();

  const onChange = (e) => {
    if (e.target.name === "skills") {
      const selectedSkills = Array.from(e.target.selectedOptions, (option) => option.value);
      setFormData({ ...formData, skillsSelected: selectedSkills });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [lastModifiedSkill, setLastModifiedSkill] = useState(null);

  const onChangelist = (e) => {
    const selectedSkill = e.target.value;

    setSkillsSelected(prevSkillsSelected => {
      if (prevSkillsSelected.includes(selectedSkill)) {
        return prevSkillsSelected.filter(skill => skill !== selectedSkill);
      } else {
        return [...prevSkillsSelected, selectedSkill];
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Check if formData.skillsSelected is an array before using map
    const skillsString = Array.isArray(formData.skillsSelected)
      ? formData.skillsSelected.map(skill => skill.value).join(', ')
      : "";

    addExpertise({
      category: formData.category,
      skills: skillsString
    });

    history.push("/expertiselevel");
    //console.log("Submitting with skillsSelected:", skillsString);
    //console.log("Submitting with skillsSelected:", formData.skillsSelected);
  };



  if (!isAuthenticated) {
    return <Redirect to="/login-register?role=pro&type=login" />;
  }

  if (freelancerprofile?.empty) {
    return <Redirect to="/login-register?role=pro&type=login" />;
  }
  /*if(typeof freelancerprofile === "string"){
    return <Redirect to="/expert-email-verify" />;
  }*/

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 'auto',
      minHeight: '40px',
      maxHeight: '200px', // Adjust height here
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : null,
      color: state.isSelected ? 'white' : 'black',
    }),
  };

  const handleSelectChange = (selectedOptions) => {
    // Update the state with the selected options
    setFormData({ ...formData, skillsSelected: selectedOptions });
  };

  const options = skills && Array.isArray(skills) ? skills.map(skill => ({ value: skill.skill_name, label: skill.skill_name })) : [];

  return (

    <section className="main-page page-dashboard">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
            <div className="bg-white rounded shadow-sm sidebar-page-right">
              <div className="p-5">
                <Link to={`/personaldetails`} className=" text-dark d-flex mb-2"
                  style={{ alignItems: "center", gap: "3px" }}
                >
                  <KeyboardBackspaceOutlined />  Back

                </Link>
                <h3 className="text-primary">Expertise</h3>
                <p className="font-weight-bold">02 of 10</p>
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="scrollable">
                    <h6 className="font-weight-bold mb-4 mt-4">Tell us a little more about your area of core expertise</h6>
                    <div className="row d-flex align-items-center form-group">
                      <div className="col-md-12 col-12">
                        <p className="text-muted font-weight-bold">What is the main service you offer?</p>
                      </div>
                      <div className="col-md-12 col-12">
                        <select className="form-control"
                          name="category"
                          value={category}
                          required={true}
                          onChange={(e) => onChange(e)}>
                          <option value="Application security">Application security</option>
                          <option value="Network and infrastructure security">Network and infrastructure security</option>
                          <option value="Intrusion detection and penetration testing">Intrusion detection and penetration testing</option>
                          <option value="Digital forensics and incident response">Digital forensics and incident response</option>
                          <option value="Endpoint protection and mobile security">Endpoint protection and mobile security</option>
                          <option value="Data governance, risk and compliance">Data governance, risk and compliance</option>
                        </select>
                      </div>
                      <div className="col-md-12 col-12 mt-4">
                        <p className="text-muted font-weight-bold">What skills do you offer clients?</p>
                      </div>
                      <div className="col-md-12 col-12">
                        <Select
                          isMulti
                          name="skills"
                          options={options}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleSelectChange}
                          styles={customStyles}
                          value={formData.skillsSelected}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row justify-content-center complete_profile_buttons"> 
                                    <div className="text-right">
                                      <Link to="/personaldetails" className="btn btn-default mr-3">Back</Link>
                                      <button type="submit" className="btn btn-primary" >Save & Next</button>
                                    </div>
                                    </div> */}
                  <div className="row justify-content-center p-2">
                    <div className="col-md">
                      {/*<Link to="/certificate" className="btn btn-normal">Skip this step</Link>*/}
                    </div>
                    <div className="text-right wm-100">
                      <button type="submit" className="btn btn-primary wm-100" >Save & Next</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Expertise.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile,
  clientProfile: state.clientProfile,
});


export default connect(mapStateToProp, { setAlert, addExpertise, skillsGet })(Expertise);