import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Link,
  Redirect,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { Country } from "country-state-city";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputRange from "react-input-range";
import {
  freelancerAllGet,
  freelancerGetbyId,
} from "../../actions/freelancerProfile";
import InvitationModal from "./invitationModal";
import { Input2 } from "../../components/input/input";

import Skeleton from "./../../components/skeleton";
import { Helmet } from "react-helmet";
import Search from "../job/search";
import { skillsGet } from "../../actions/clientProfile";
import { remove } from "react-icons-kit/fa/remove";
import SearchIcon from "../../img/search.svg";
import MapIcon from "../../img/map-pin.svg";
import FilterIcon from "../../img/filter-square.svg";
import LocationIcon from "../../img/location-icon.svg";
import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";
import Flag from 'react-world-flags';
import { getCode } from 'country-list';
import { Help } from "@material-ui/icons";
import { driver } from "driver.js";
import { QuestionLg } from "react-bootstrap-icons";
import { FilterList } from "@material-ui/icons";

const Talent = ({
  auth: { isAuthenticated, user },
  freelancerProfile: { freelancerList, freelancerprofile },
  freelancerGetbyId,
  clientProfile: { skills },
  skillsGet,
}) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [profileTalentData, setProfileTalentData] = useState(null);
  const [inviteModal, setInviteModal] = useState(false);
  const [hireModal, setHireModal] = useState(false);
  const [selectedfreelancer, setSelectedfreelancer] = useState(null);
  const [talentdetails, setTalentdetails] = useState(null);
  const [error, setError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const filterRef = useRef(null);
  useOutsideAlerter(filterRef, () => setFilterOpen(false));

  const [locationInput, setLocationInput] = useState("");
  const [buttonClass, serButtonClass] = useState("");

  const [showtalent, setShowTalent] = useState(false);
  const [loadingTest, setLoadingTest] = useState(true);
  const handleClosetalent = () => {
    setShowTalent(false);
    setTalentdetails();
  };



  const [hrate, setHourlyRate] = useState("");
  //const [hrate, setHourlyRate] = useState("10");

  const [formData, setFormData] = useState({
    priceRange: "",
    location: "Please Select",
    //location: "United Kingdom",
    category: "",
  });

  const { priceRange, location, category } = formData;

  const onChangeVal = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [state, setstate] = useState({
    value: { min: 2, max: 1000 },
  });

  const searchInput = useRef("");

  const [value, setValue] = useState("");

  const [show, setShow] = useState(false);
  const [Hirecheck, setcheck] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //const storedValue = JSON.parse(localStorage.getItem('search'));
  /*const homepage_query = useLocation();
  const searchParams = new URLSearchParams(homepage_query.search);
  let storedValue = searchParams.get("value");*/

  const homepage_query = useLocation();
  const searchParams = new URLSearchParams(homepage_query.search);
  const [storedValue, setStoredValue] = useState(searchParams.get("value"));

  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const totalPages = Math.ceil(profileTalentData?.length / resultsPerPage);

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onOutsideClick]);
  }

  useEffect(() => {
    //debugger;
    dispatch(freelancerAllGet());
    skillsGet();
    if (storedValue) {
      setValue(storedValue);
      localStorage.removeItem("search");
    }
  }, []);

  useEffect(() => {
    if (storedValue) {
      handleSearch(storedValue);
    } else {
      if (freelancerList !== null) {
        const mappedFreelancers = freelancerList && freelancerList.freelancers?.map((item) => ({
          imageLink: item?.profilePhoto?.photo_link,
          name: item?.anonymousMode
            ? "Anonymous Expert"
            : item?.user_id?.first_name + " " + item?.user_id?.last_name,
          title: item?.expertise?.category,
          location: item?.location?.country,
          hourlyRate: item?.hourlyRate?.hourly_rate,
          skills: [item?.expertise?.skills],
          _id: item?._id,
          verification: item?.verification,
          anonymousMode: item?.anonymousMode,
          description: item?.titleAndOverview?.professional_overview ?? "",
        }));

        setProfileTalentData(mappedFreelancers);
        // Also set the originalSearchResults with the full list
        setOriginalSearchResults(mappedFreelancers);
      }
    }
  }, [freelancerList]);

  const handleSortChange = (event) => {
    const sortValue = event.target.value;
    let sortedArray = [...originalSearchResults.reverse()];

    if (sortValue === "alphabetically") {
      sortedArray.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
    } else if (sortValue === "highestRate") {
      // This will sort from highest to lowest, so no reverse is needed here
      sortedArray.sort((a, b) => b.hourlyRate - a.hourlyRate);
    } else if (sortValue === "lowestRate") {
      // This will sort from lowest to highest, so no reverse is needed here
      sortedArray.sort((a, b) => a.hourlyRate - b.hourlyRate);
    }

    // Set the reversed sorted array to state
    setProfileTalentData(sortedArray);
  };

  const countries = Country.getAllCountries();
  // 

  const profileview = async (e) => {
    /*if (!isAuthenticated) {
      //dispatch(setAlert("Sign In to see expert profile","danger", 3000));

      return;
    }*/

    //console.log(e);
    freelancerGetbyId(e._id).then((res) => {
      //console.log(res.data);
      if (res.data) {
        setShowTalent(true);
      }
    });
    //console.log(freelancerprofile);
  };

  const [originalSearchResults, setOriginalSearchResults] = useState([]);

 const handleSearch = (search) => {
    //debugger
    const formattedSearchQuery = search.toLowerCase().replace(/\s/g, "");
    try {
      var filteredList = freelancerList.freelancers?.filter(
        (freelancer) =>
          freelancer?.user_id?.first_name
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          freelancer?.user_id?.last_name
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          (
            freelancer?.user_id?.first_name?.toLowerCase() +
            freelancer?.user_id?.last_name?.toLowerCase()
          )?.includes(formattedSearchQuery) ||
          freelancer?.expertise?.skills
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          freelancer?.location?.country
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          (freelancer?.expertise?.category &&
            freelancer?.expertise?.category
              ?.toLowerCase()
              .includes(search.toLowerCase()))
      );

      if (locationInput !== "") {
        filteredList = freelancerList.freelancers?.filter((freelancer) =>
          freelancer?.location?.country
            ?.toLowerCase()
            .includes(locationInput.toLowerCase())
        );
      }

      const mappedResults = filteredList.map((item) => ({
        imageLink: item?.profilePhoto?.photo_link,
        name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
        title: item?.expertise?.category,
        location: item?.location?.country,
        hourlyRate: item?.hourlyRate?.hourly_rate,
        skills: [item?.expertise?.skills],
        _id: item?._id,
        verification: item?.verification,
        description: item?.titleAndOverview?.professional_overview ?? "",
      }));

      // Update profileTalentData for display
      if (mappedResults.length > 0) {
        setProfileTalentData(mappedResults);
      } else {
        setProfileTalentData([]);
      }

      // Store the original search results for future filtering
      setOriginalSearchResults(mappedResults);

      //setFilteredData(filteredList);
      setCurrentPage(1);
    } catch (err) {
      console.log(err);
    }
    //localStorage.removeItem('search');
  };
  

  const onChange = (e) => {
    const { value } = e.target;
    // Validate the input to ensure it's either empty or a valid number
    if (value === "" || !isNaN(Number(value))) {
      setHourlyRate(value); // Update the state with the new value
      setError(""); // Clear any existing error
    } else {
      // If the input is not a valid number, set an error message
      setError("Hourly rate must be a number.");
    }
  };

  const clearFilter = () => {
    // Reset the form data for location and category to their default state
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: "",
      category: "",
    }));

    // Clear the hourly rate filter
    setHourlyRate("");

    setProfileTalentData(originalSearchResults);
    setCurrentPage(1);
  };

  // USE THIS FOR CLEAR SEARCH, ADD BUTTON BELOW FIND TALENT
  const clearSearch = () => {
    //setFormData({ ...formData, ["location"]: "" });
    //setFormData({ ...formData, ["category"]: "" });
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: "",
      category: "",
    }));
    setHourlyRate("");
    setProfileTalentData(
      freelancerList?.map((item) => ({
        imageLink: item?.profilePhoto?.photo_link,
        name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
        title: item?.expertise?.category,
        location: item?.location?.country,
        hourlyRate: item?.hourlyRate?.hourly_rate,
        skills: [item?.expertise?.skills],
        _id: item?._id,
        verification: item?.verification,
        description: item?.titleAndOverview?.professional_overview ?? "",
      }))
    );
  };

  //console.log(profileTalentData)

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = profileTalentData
    ? [...profileTalentData].slice(startIndex, endIndex)
    : null;

  const filtersearch = () => {
    const filteredList = originalSearchResults.filter((freelancer) => {
      const freelancerHourlyRate = parseFloat(freelancer.hourlyRate);
      const filterRate = hrate ? parseFloat(hrate) : null;

      const meetsHourlyRateCriteria = filterRate
        ? freelancerHourlyRate <= filterRate
        : true;
      const meetsLocationCriteria =
        formData.location && formData.location !== "Please Select"
          ? freelancer.location
            .toLowerCase()
            .includes(formData.location.toLowerCase())
          : true;

      return meetsHourlyRateCriteria && meetsLocationCriteria;
    });

    // Update state with the filtered data
    if (filteredList.length > 0) {
      setProfileTalentData(filteredList);
    } else {
      setProfileTalentData([]);
    }
    setCurrentPage(1);
  };

  const handleFilterAndClose = () => {
    if (hrate == "" && location == "") {
      setCategoryError("Hourly rate or location is required");
    }
    setLocationError("");
    filtersearch();
    setShow(false); // Close the modal
    setFilterOpen(false); // Close the filter toggle menu
  };

  const toggleFilter = () => {
    if (filterOpen) {
      setFilterOpen(false);
    } else {
      setFilterOpen(true);
    }
  };

  const clearSearchAndCallHandleSearch = () => {

    const currentUrl = new URL(window.location);
    currentUrl.searchParams.delete("value");
    window.history.pushState({}, '', currentUrl);

    setStoredValue(null);

    setValue("");
    setLocationInput("");
    handleSearch("");
    dispatch(freelancerAllGet());
  };

  //console.log(storedValue);

  const handleHiretalent = (profileTalentDataItem) => {
    if (!isAuthenticated) {
      localStorage.setItem("comingfromTalent", true);
      history.push("/login-register?role=client&type=login");
    }
    setHireModal(true);
    setInviteModal(true);
    setSelectedfreelancer(profileTalentDataItem);
  };

  useEffect(() => {
    if (isAuthenticated && user.type === "Client") {
      serButtonClass('mb-2');
    }
  }, [isAuthenticated]);

  const handleSkillClick = (value) => {
    setValue(value);
    handleSearch(value)
    //console.log("done")
  }
  
  const startTour = () => {
    const steps = [];
  
    // Add the first step only if the user is a freelancer
    // if (user.type === "Freelancer") {
    //   steps.push({
    //     element: '#edit',
    //     popover: {
    //       title: 'Edit Profile',
    //       description: 'As an expert, you can go to edit profile.',
    //       side: "left",
    //       align: 'start',
    //     },
    //   });
    // }

    if (isAuthenticated && user.type === "Client" ){
      steps.push({
                element: '#invite',
        popover: {
          title: 'Invite Freelancer ',
          description: 'From here you can Invite a Freelancer to a job.',
          // description: user.type === "Client" ? 'If no contracts are here you can find talents from this button':'If no contracts are here you can find job from this button.',
          side: "left",
          align: 'start',
        },
      });
    }
  
    // Add the remaining steps with descriptions based on user type
    steps.push(
   
      {
        element: '#location',
        popover: {
          title: 'Location Search',
          description: 'From here you can search based on Location.',
          side: "left",
          align: 'start',
        },
       
      },
      {
        element: '#suggestion',
        popover: {
          title: 'Find Suggestions ',
          description: 'From here you can Just Click on the Suggestion and you will get related data.',
          // description: user.type === "Client" ? 'If no contracts are here you can find talents from this button':'If no contracts are here you can find job from this button.',
          side: "left",
          align: 'start',
        },
      },
      {
        element: '#filters',
        popover: {
          title: 'Filters ',
          description: 'From here you can Apply Filters to filter out the results.',
          // description: user.type === "Client" ? 'If no contracts are here you can find talents from this button':'If no contracts are here you can find job from this button.',
          side: "left",
          align: 'start',
        },
      },
    
    );
  
    const driverObj = new driver({
      showProgress: true,
      steps: steps, // Use the dynamically created steps
    });
  
    driverObj.drive();
  };

  return (
    <div className="main-page page-dashboard page-talent">
      <Helmet>
        <title>Find Talent</title>
      </Helmet>
      <div 
        onClick={startTour}
          className="tour" >
        <QuestionLg />
        </div>
      <div className="container-fluid">
        <section className="bg-white rounded shadow-sm p-1 mb-3">
          <div className="row advanced-search talent-search">
            <div className="col-md-5">
              <img src={SearchIcon} alt="search" />
              <Search
                suggestions={
                  freelancerList?.uniqueSkills?.length > 0 ? freelancerList?.uniqueSkills?.map((item) => item) : []
                }
                setInput={setValue}
                handleSearch={() => handleSearch(value)}
                pre_value={value}
                icon={false}
              />
            </div>
            <div id="location" className="col-md-5">
              <img src={MapIcon} alt="search" />
              <Search
                // suggestions={countries.map((item) => item.name)}
                suggestions={countries.map((item) => (
                  <div className="flex items-center">
                    <Flag code={getCode(item.name) || 'UN'} className='pr-2' style={{ width: "35px" }} />
                    {item.name}
                  </div>
                ))}
                setInput={setLocationInput}
                handleSearch={() => handleSearch(value)}
                pre_value={locationInput}
                placeholder={"Your Location"}
                icon={false}
              />
            </div>
            <div className="col-md-2 p-0 text-right text-nowrap">
              <Button className="px-3" onClick={() => handleSearch(value)}>
                Find Talent
              </Button>
            </div>
          </div>
          {value !== "" || locationInput !== "" ?
            <button
              className="Clear "
              onClick={clearSearchAndCallHandleSearch}
              style={{
                all: "unset",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Icon className="mx-2 icon-hover" icon={remove}></Icon>
              <span
                style={{
                  fontSize: "14px",
                  paddingTop: "3.4px",
                  fontWeight: "bold",
                  paddingLeft: "0",
                }}
              >
                Clear Search
              </span>
            </button> : null}

          <div id="suggestion" className="suggestion-talent">
            <div className="title">Suggestions:</div>
            <div className="suggestions-list">
              {/* Network Security, Cyber Risk Management, Malware Protection, Data
              Security, Penetration Testing */}
              {freelancerList?.uniqueSkills?.length > 0
                ? freelancerList?.uniqueSkills?.slice(0, 7).map((s, index, array) => (
                   <span
                    value={s}
                    key={s}
                    onClick={() => handleSkillClick(s)}
                    style={{ cursor: "pointer" , textDecoration: 'underline' }} 
                    
                  >
                    <span className="sugesstionlinks">{s}</span>
                    {index < array.length - 1 && ", "}
                    &nbsp;
                  </span>
                ))
                : ""}
            </div>
          </div>
        </section>
        {showtalent == true ? (
          <Modal
            show={showtalent}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClosetalent}
          //className={showtalent ? 'xl' : ''}
          >
            {/** <Modal.Header className="justify-content-end">
              <Icon
                className="mx-2 icon-hover"
                icon={remove}
                onClick={handleClosetalent}
              />
            </Modal.Header>*/}

            <Modal.Body className="talent-modal">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="justify-content-end text-right">
                      <Icon
                        className="mx-2 icon-hover"
                        icon={remove}
                        onClick={handleClosetalent}
                      />
                    </div>
                    <div className="title">
                      Talent Details   <span style={{ marginLeft: "6px", marginRight: "6px" }}>
                        {"  "}- {"  "}
                      </span>
                      <span>
                        {freelancerprofile.profile.user_id.first_name +
                          " " +
                          freelancerprofile.profile.user_id.last_name}
                      </span>
                    </div>
                    <br></br>
                    <h6 className="text-primary">Title</h6>
                    <p className="text-muted text-justify">
                      {freelancerprofile.profile.titleAndOverview.title}
                    </p>
                    <h6 className="text-primary">Expertise category</h6>
                    <p className="text-muted">
                      {freelancerprofile.profile.expertise.category}
                    </p>
                    <h6 className="text-primary">Expertise</h6>
                    <p className="text-muted">
                      {freelancerprofile.profile.expertise.skills}
                    </p>
                    <h6 className="text-primary">Expert Level</h6>
                    <p className="text-muted">
                      {freelancerprofile.profile.expertLevel.expert_level}
                    </p>
                    <h6 className="text-primary">Hourly Rate</h6>
                    <p className="text-muted">
                      {"$" + freelancerprofile.profile.hourlyRate.hourly_rate} <span className="location-text-Bold">USD</span>
                    </p>
                    {freelancerprofile?.profile?.education ? (
                      <>
                        <h6 className="text-primary">Education</h6>
                        <p className="text-muted">
                          {`
                                  ${freelancerprofile.profile?.education?.degree
                              ? freelancerprofile.profile?.education
                                ?.degree + " - "
                              : ""
                            }
                                  ${freelancerprofile.profile?.education
                              ?.specialization
                              ? freelancerprofile.profile?.education
                                ?.specialization + " - "
                              : ""
                            }
                                  ${freelancerprofile.profile?.education
                              ?.provider
                              ? freelancerprofile.profile?.education
                                ?.provider
                              : ""
                            }
                                  `}
                        </p>
                      </>
                    ) : (
                      <p>Not provided</p>
                    )}
                    <h6 className="text-primary">Location</h6>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "4px" }}>
                      <img src={LocationIcon} alt="location" style={{ marginRight: '8px' }} />
                      <span style={{ margin: 0 }}>
                        <Flag code={getCode(freelancerprofile.profile.location.country) || 'UN'} className='pr-2' style={{ width: "35px" }} />

                        {freelancerprofile.profile.location.country}
                      </span>
                    </div>
                    <p className="text-muted" style={{ margin: 0 }}>

                    </p>

                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => handleHiretalent(freelancerprofile.profile)}
              >
                Hire
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleClosetalent}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        ) : (
          console.log("")
        )}
        <div className="talent-page-header">
          <div className="title">
            All Searches (
            <strong>{profileTalentData ? profileTalentData.length : 0}</strong>{" "}
            Experts found)
          </div>
          
          <div id="filters" className="block wm-100 jcm-space">
            <div className="block sort-block">
              <div>Sort by:</div>
              <select onChange={handleSortChange}>
                <option>Most relevant</option>
                <option value="alphabetically">Alphabetically</option>
                <option value="highestRate">Highest rate</option>
                <option value="lowestRate">Lowest rate</option>
              </select>
            </div>
            <div className="block">
              <div>Filter:</div>
              <div className="filter-block" ref={filterRef}>
                <div
                  className={`toggle ${filterOpen ? "active" : ""}`}
                  onClick={toggleFilter}
                >
                  <img src={FilterIcon} alt="filter-icon" />
                </div>
                <div
                  className={`dropdown-box ${filterOpen ? "show" : ""}`}
                  ref={filterRef}
                >
                  <div className="d-flex justify-content-end">
                    <div
                      className="text-primary cursor-pointer"
                      onClick={clearFilter}
                    >
                      Clear Filters
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group text-left">
                        <label className="form-label font14">Hourly Rate Range</label>
                        <div className="mt-2 font14">
                          <Input2
                            labelfor={`Hourly Rate`}
                            // label={`Username`}
                            placeholder={`Hourly Rate`}
                            required={true}
                            type={"text"}
                            handlerOnChange={(e) => onChange(e)}
                            value={hrate}
                            parentclass={"mb-2"}
                            name="hourly_rate"
                          >
                            {/* <span className="error-text">Error</span> */}
                          </Input2>
                          {error && <div style={{ color: "red" }}>{error}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group text-left">
                        <label className="form-label font14">Country/Location</label>
                        <div className="input-group mt-2 mb-2 font14">
                          <select
                            className="form-control"
                            name="location"
                            value={formData.location}
                            onChange={(e) => onChangeVal(e)}
                          >
                            <option value="">Please Select</option>
                            {countries.map((item) => (
                              <option
                                key={item.name}
                                value={item.name}
                                data-isoCode={item.isoCode}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {locationError && (
                          <div style={{ color: "red" }}>{locationError}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-left">
                    <Button
                      variant="primary"
                      onClick={handleFilterAndClose}
                      disabled={error}
                      className="wm-100"
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="talent-list">
          {/* <div className="d-flex justify-content-end">
            <div
              className="pt-4 px-4 text-primary cursor-pointer"
              onClick={clearFilter}
            >
              Clear Filters
            </div>
          </div> */}
          {currentResults === null && (
            <div className="bg-white rounded shadow-sm">
              {[0, 1, 2, 3, 4].map((i) => (
                <div key={i} className="border-bottom pb-2">
                  <div className="row">
                    <div className="col-md-3 pr-0 d-flex justify-content-center align-items-center">
                      <Skeleton width={135} height={135} borderRadius={130} />
                    </div>
                    <div className="col-md-7 pl-0 pt-1">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton width={180} height={30} />
                      </div>
                      <p className="font-weight-bold mb-0">
                        <Skeleton width={120} height={12} />
                      </p>
                      <p className="text-muted mb-0">
                        <Skeleton width={100} height={10} />
                      </p>

                      <p>
                        <Skeleton width={70} height={12} />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {currentResults && currentResults.length > 0 ? (
            currentResults.length > 0 &&
            [...currentResults].map((profileTalentDataItem, index) => (
              <div className="freelancer-list talent-block" key={index}>
                <div className="row align-items-center">
                  <div
                    className="col-md-3 pr-md-0 col-lg-2 mt-0" //col-md-3 col-lg-2 mt-0
                    onClick={() => {
                      profileview(profileTalentDataItem);
                    }}
                  >
                    <div
                      className="rounded-circle border border-dark overflow-hidden"
                      style={{
                        height: "135px",
                        width: "135px",
                        margin: "0 auto",
                      }}
                    >
                      <img
                        className="img-fluid"
                        src={profileTalentDataItem.imageLink}
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                        alt={profileTalentDataItem.name}
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-7 px-1 pl-md-0 mt-0" //col-md-9 col-lg-10 mt-0
                    onClick={() => {
                      profileview(profileTalentDataItem);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // gap: "10px",
                        marginRight: profileTalentDataItem.verification === true ? "-20px" : "-5px"

                      }}
                      className="profile-display mt-3 mt-md-0"
                    >
                      <div className="d-flex profile-display ">
                        <h3 className="mb-0">{profileTalentDataItem.name}</h3>
                      </div>

                      {profileTalentDataItem.verification === true && (
                        <img
                          src="assets/images/checkmark.png"
                          className="img-fluid verification-image"
                          alt="Cyber2Cyber"
                          title="Cyber to Cyber Verified Expert"
                        />
                      )}
                    </div>
                    {/* <p className="font-weight-bold mb-0">
                        {profileTalentDataItem.title}
                      </p> */}
                    <p className="description">
                      {profileTalentDataItem.description?.substr(0, 100)}
                      {profileTalentDataItem.description.length > 100
                        ? "..."
                        : ""}
                    </p>

                    <div className="location-hourly">
                      <div className="location">
                        <img src={LocationIcon} alt="location" />
                        <span className="pl-1 location-text">
                          <Flag code={getCode(profileTalentDataItem.location) || 'UN'} className='pr-2' style={{ width: "35px" }} />
                          {profileTalentDataItem.location}
                        </span>
                      </div>
                      <div className="hourly">
                        <span className="location-text" style={{ fontWeight: "100" }}>|
                        </span>
                      </div>
                      <div className="hourly">
                        <span>
                          {profileTalentDataItem.hourlyRate !== "N/A"
                            ? `$${profileTalentDataItem.hourlyRate} `
                            : "N/A"}
                        </span>{" "}
                        / hr
                      </div>
                    </div>
                    {/*}<p><span className="font-weight-bold">${profileTalentDataItem.hourlyRate}.00</span> / hr</p>{*/}

                    {/*<p className="text-primary font-weight-bold"><Icon icon={fileO} /> Completed 13 SEO jobs on CyberToCyber</p>*/}
                    {/* {profileTalentDataItem.skills.length > 0 && <p>Has <span className="font-weight-bold">{profileTalentDataItem.skills.length} relevant skills</span> to your job</p>} */}
                    <div className="tags-row m-0">
                      {profileTalentDataItem.skills.length > 0 &&
                        profileTalentDataItem.skills.map(
                          (profileTalentDataItemSkills, index) => {
                            if (profileTalentDataItemSkills.includes(",")) {
                              const splitSkills =
                                profileTalentDataItemSkills.split(",");
                              return splitSkills.map((skill, i) => (
                                <>
                                  {skill.trim().length > 0 && (
                                    <span
                                      key={`${index}-${i}`}
                                      className="badge"
                                    >
                                      {skill}
                                    </span>
                                  )}
                                </>
                              ));
                            } else {
                              return (
                                <span
                                  key={index}
                                  className="badge"
                                >
                                  {profileTalentDataItemSkills}
                                </span>
                              );
                            }
                          }
                        )}

                      {/*<span className="border rounded pr-1 pl-1 pt-2 pb-2 ml-2 mt-1"><Link to="#" className="text-danger font-weight-bold">5 More</Link></span>*/}
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-3 mt-0 button-group d-flex flex-column justify-content-center">
                    {!isAuthenticated || user.type == "Client" ? (
                      <>
                        <div className={buttonClass}>
                          <button
                            onClick={() => handleHiretalent(profileTalentDataItem)}
                            className="btn text-nowrap btn-default btn-sn mt-3 mt-md-auto col-sm-12 col-md-auto mb-0 direct-hire-button"
                          >
                            Hire
                          </button>
                        </div>

                        <div className="mb-0">
                          {isAuthenticated && user.type === "Client" ? (
                            <>
                              <button
                              id="invite"
                                onClick={() => {
                                  setInviteModal(true);
                                  setHireModal(false);
                                  setSelectedfreelancer(profileTalentDataItem);
                                }}
                                className="btn text-nowrap btn-default btn-sn mt-3 mt-md-auto col-sm-12 col-md-auto mb-0 direct-hire-button "
                              >
                                Invite to Job
                              </button>
                              <InvitationModal
                                show={inviteModal}
                                Hirecheck={hireModal}
                                freelancer={selectedfreelancer}
                                handleClose={() => {
                                  setInviteModal(false);
                                  setHireModal(false);
                                }}
                              />
                            </>
                          ) : null}

                        </div>

                      </>
                    ) : (
                      console.log("sign in required")
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-1">
              {/** <h6 className="text-primary text-center mb-0">
                No Search Result Found
              </h6>
              */}
              <div className="p-1">
                <div className="message-not-available w-100">
                  <div className="text-center">
                    <img
                      src="assets/images/no-data-found.png"
                      width={"100px"}
                      alt="message"
                      className="mb-4"
                    />
                    <h1 className='location-text-Bold' style={{ fontSize: "30px" }} >Sorry! No Record Found</h1>
                    <p className="land location-text">
                      Try adjusting your search to find what you are looking for.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <br />
        {/* Display the navigation buttons */}
        {totalPages > 1 && (
          <nav>
            <ul className="talent-pagination pagination justify-content-center">
              <div
                className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() =>
                  handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                }
              >
                {/** <Icon icon={arrowLeft} />*/}

                <PaginationArrowLeft />
              </div>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${pageNumber === currentPage ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
              <div
                className={`icon ${currentPage === totalPages ? "disabled" : ""
                  }`}
                onClick={() =>
                  handlePageChange(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
              >
                {/** <Icon icon={arrowRight} />*/}
                <PaginationArrowRight />
              </div>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

Talent.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  auth: state.auth,
  freelancerProfile: state.freelancerProfile,
  clientProfile: state.clientProfile,
});

export default connect(mapStateToProp, { freelancerGetbyId, skillsGet })(
  Talent
);
