import axios from 'axios';
import { setAlert } from './alert';

import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	PHONENUMBER_GET,
	OTP_ID_INSERT,
	TOTAL_USERS,
	BY_DATE_USERS,
	BY_COUNTRY_USERS,
	BY_COUNTRY_USERS_CHARTS,
	TOTAL_USERS_CHARTS,
	GOOGLE_ANALYTICS_REPORT
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { expertiseGet, expertLevelGet, EducationGet, CertificateGet, EmploymentGet, LanguageGet, HourlyRateGet, ProfileAndPhotoGet, locationGet, TitleAndOverviewGet , getFreelancer, getReviews,} from "./freelancerProfile";
import { hireManagerGet } from "./clientProfile";
import { postJobGet } from "./postJob";
import { sendEmailFunc } from "./sendEmail";
import { messageSummary } from './proposalAndContract';
import { getAdminMessages } from "./manageUsers";

// Load User
export const loadUser = () => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/auth');

		//console.log(res.data.type );
		dispatch({
			type: USER_LOADED,
			payload: res.data
		})

		if(res.data.type == "Client")
		{
			//console.log("check");
			//dispatch(hireManagerGet());
			dispatch(postJobGet());
		}
		else if(res.data.type == "Freelancer")
		{
			dispatch(expertiseGet());
			dispatch(expertLevelGet());
			dispatch(EducationGet());
			dispatch(EmploymentGet());
			dispatch(LanguageGet());
			dispatch(HourlyRateGet());
			dispatch(ProfileAndPhotoGet());
			dispatch(PhoneNumberGet());
			dispatch(locationGet());
			dispatch(TitleAndOverviewGet());
			dispatch(CertificateGet());
			dispatch(postJobGet());
			dispatch(getReviews());
			dispatch(getFreelancer());
		}

		//debugger;
		dispatch(messageSummary());
		dispatch(getAdminMessages());
		
		
	} catch (err) {
		/*
		dispatch({
			type: AUTH_ERROR
		})*/
		console.log("");

	}
}

export const getAuth = () => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/auth');

		dispatch({
			type: USER_LOADED,
			payload: res.data
		});
		
	} catch (error) {
		dispatch(setAlert(error.message, 'danger'));
	}
}


// Register User
export const register = ({ user_name, email, first_name, last_name, password, type, location }, cb) => async dispatch => {
	
	
	//console.log("username in action ",user_name);
	
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	
	const body = JSON.stringify({ user_name, email, first_name, last_name, password, type, location });
	try {
		const res = await axios.post('/api/users', body, config);
		
	    //console.log("action",res)
		
		if (res.data) {
			
			if(type === "Admin"){
				cb && cb();	
				dispatch(setAlert("Your profile is sent for approval Kindly wait you will be notified via email", 'success'));
			}
			else{
				
				//const type = arg.type;
				dispatch({
					type: REGISTER_SUCCESS,
					payload:res.data
				})
				
			    //console.log(res.data);
				dispatch(setAlert(res.data.messgae, 'success'))
				dispatch(loadUser());

				return res;  
				
				//dispatch(setAlert("User Registered", 'success'));
			}
		  }


		
	} catch (err) {

		//console.log(err);
		
		const errors = err.response.data.errors 
	
		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
		
		dispatch({
			type: REGISTER_FAIL
		})

		return errors

	}

}

export const updateUserConfig = ({ visibility = true, active = true, anonymousMode = true }) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	try {

		const body = JSON.stringify({
			visibility,
			active,
			anonymousMode
		});

		await axios.post('/api/auth/config', body, config);

		dispatch(getAuth());
		dispatch(setAlert('Configuration updated', 'success'));
		
	} catch (error) {
		dispatch(setAlert(error.message, 'danger'));
	}
}

export const getTotalUsers = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		
		const res = await axios.get('/api/auth/gettotalusers');
		dispatch({
			type: TOTAL_USERS,
			payload: res.data
		});

	} catch (err) {
		
		const errors = err.response.data;

		if (errors) {
			//dispatch(setAlert(errors.msg, 'danger'));
		}
	}
}

export const countUsersfromDate = (data) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		let res = await axios.get('/api/auth/gettotalusersbydate', {params: data,});
		
		// console.log("response checking ---- ", res.data)
		dispatch({
			type: BY_DATE_USERS,
			payload: res.data
		});
		
	}catch(e) {
		const error = e.response.data;
		dispatch(setAlert(error.msg, 'danger'))
	}
};


export const countUsersfromCountry = (data) => async dispatch => {
	
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/auth/getusersbycountry', {params: data,});
		
		//console.log("response checking ---- ", res.data)
		dispatch({
			type: BY_COUNTRY_USERS,
			payload: res.data
		});
		

	}catch(e) {
		const error = e.response.data;
		//toast.error(error.msg);
		dispatch(setAlert(error.msg, 'danger'))
	}
};



// Register User
// export const forgetpassword = ({ email }) => async dispatch => {
// 	const config = {
// 		headers: {
// 			'Content-Type': 'Application/json'
// 		}
// 	}

// 	const body = JSON.stringify({ email });

// 	try {
// 		const res = await axios.post('/api/users', body, config);

// 		dispatch({
// 			type: REGISTER_SUCCESS,
// 			payload: res.data
// 		})
// 		dispatch(loadUser());
// 	} catch (err) {
// 		const errors = err.response.data.errors;
// 		if (errors) {
// 			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
// 		}
// 		dispatch({
// 			type: REGISTER_FAIL
// 		})
// 	}
// }



// Login User
export const otpVerification = (code, id, cb,cbF) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({ code, id });

	try {
		const res = await axios.post('/api/auth/otpverification', body, config);
		if (res.status === 200) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data
			})


			dispatch(expertiseGet());
			dispatch(expertLevelGet());
			dispatch(EducationGet());
			dispatch(EmploymentGet());
			dispatch(LanguageGet());
			dispatch(HourlyRateGet());
			dispatch(ProfileAndPhotoGet());
			dispatch(PhoneNumberGet());
			dispatch(locationGet());
			dispatch(TitleAndOverviewGet());
			dispatch(CertificateGet());
			dispatch(hireManagerGet());
			dispatch(postJobGet());
			dispatch(sendEmailFunc());

			cb && cb();
		}
	} catch (err) {
		const errors = err.response.data.errors;
		cbF && cbF();
		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}

		dispatch({
			type: LOGIN_FAIL
		})
	}

}


export const login = (user_name, password, redirect_2FA) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({ user_name, password });

	try {
		const res = await axios.post('/api/auth', body, config);
		if (res.status === 200) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data
			});

			dispatch(setAlert('Login Successful!', 'success', 5000))

         /*
			dispatch(expertiseGet());
			dispatch(expertLevelGet());
			dispatch(EducationGet());
			dispatch(EmploymentGet());
			dispatch(LanguageGet());
			dispatch(HourlyRateGet());
			dispatch(ProfileAndPhotoGet());
			dispatch(PhoneNumberGet());
			dispatch(locationGet());
			dispatch(TitleAndOverviewGet());
			dispatch(CertificateGet());
			dispatch(getFreelancer());
			if(res.data.user.type == "Client")
			{
				dispatch(hireManagerGet());
			}
			
			dispatch(postJobGet());
		*/
		
		

		if(res.data.user.type== "Client")
		{
			//console.log("check");
			dispatch(hireManagerGet());
			dispatch(postJobGet());
		}
		else if(res.data.user.type == "Freelancer")
		{
			dispatch(expertiseGet());
			dispatch(expertLevelGet());
			dispatch(EducationGet());
			dispatch(EmploymentGet());
			dispatch(LanguageGet());
			dispatch(HourlyRateGet());
			dispatch(ProfileAndPhotoGet());
			dispatch(PhoneNumberGet());
			dispatch(locationGet());
			dispatch(TitleAndOverviewGet());
			dispatch(CertificateGet());
			dispatch(getFreelancer());
			dispatch(postJobGet());
			dispatch(getReviews());
		}
		//debugger;
		dispatch(messageSummary());
		dispatch(getAdminMessages());
	}
	} catch (err) {
		const errors = err.response.data.errors;

		if (err.response.status === 302) {
			dispatch({ type: OTP_ID_INSERT, payload: { id: err.response.data.id } });
			redirect_2FA && redirect_2FA();
		}

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger', 5000)))
		}

		dispatch({
			type: LOGIN_FAIL
		})
	}

}

/*
// Logout / Clear Profile
export const logout = (cb) => dispatch => {
	dispatch({ type: LOGOUT })
	window.location.href = "/";
	cb && cb();
}
*/

export const logout = () => dispatch => {
	try{
		dispatch({ type: LOGOUT })
		//console.log(cb);
		//cb && cb();
	}
	catch(err){
        console.log(err);
	}
	
/*
// Forgot /Password

export const enableMFA = (cb) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	try {
		const res = await axios.get('/api/auth/enablemfa', config);
		if (res.status === 200) {
			cb && cb();
			dispatch(setAlert(res.data.msg, 'success'));
		}
	} catch (err) {
		const errors = err.response.data.errors;
		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}*/
}


export const forgotPassword = (body, setLoading, setSteps, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const APIbody = JSON.stringify({
		...body
	});

	try {
		setLoading(true);
		//debugger;
		const res = await axios.post('/api/auth/forgot-password', APIbody, config);
		setLoading(false);
		

		if (body.reset_type === 'email') {
			setSteps(0);
		} else {
			history.push('/change-password?reset_type=phone')
		}

	} catch (err) {
		setLoading(false);
		const errors = err.response.data.errors;
		
		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}
}


export const support = (body) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const APIbody = JSON.stringify({
		...body
	});

	try {
		//debugger;
		const res = await axios.post('/api/auth/support', APIbody, config);
		

	} catch (err) {
		const errors = err.response.data.errors;
		
		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}
}


export const payment = (body) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const APIbody = JSON.stringify({
		...body
	});

	try {
		//debugger;
		const res = await axios.post('/api/auth/payment', APIbody, config);
		

	} catch (err) {
		const errors = err.response.data.errors;
		
		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}
}


// Reset Password

export const resetPassword = (body) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const APIbody = JSON.stringify({
		...body
	});

	try {
		const res = await axios.post('/api/auth/reset-password', APIbody, config);
		dispatch(setAlert(res.data.message, 'success'))
	} catch (err) {
		const errors = err.response.data.errors;
		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}
}


// Phone number Get
export const PhoneNumberGet = () => async dispatch => {

	//debugger;
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/phone');
		localStorage.setItem('phoneNumber', JSON.stringify(res.data));
		
		if (res.data == null)
			res.data = [];

		dispatch({
			type: PHONENUMBER_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Add Phone number Value
export const addPhoneNumber = ({ phone, codeBody }) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({ phone, codeBody });

	try {
		const res = await axios.post('/api/phone', body, config);

		dispatch(setAlert("Code Sent Successfully", 'success'));

	} catch (err) {

		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}


// Verify Phone number code
export const PhoneNumberVerify = ({ codeBodyTextVal }) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({ codeBodyTextVal });

	try {
		//debugger;
		/*
		const res = await axios.post('/api/phone/checkCode', body, config);

		if (res.status == 200) {
			dispatch(setAlert("Code Verified Successfully", 'success'));
		} 
		*/

		dispatch(PhoneNumberGet());

	} catch (err) {

		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}

//export const countUsersfromCountry_chart = (data) => async dispatch => {
export const countUsersfromCountrychart = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		//const res = await axios.get('/api/auth/getusersbycountry_chart', {params: data,});
		const res = await axios.get('/api/auth/getusersbycountrychart');

		dispatch({
			type: BY_COUNTRY_USERS_CHARTS,
			payload: res.data
		});


	}catch(e) {
		const error = e.response.data;
		//toast.error(error.msg);
		dispatch(setAlert(error.msg, 'danger'))
	}
};
	
export const getTotalUsersCharts = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {

		const res = await axios.get('/api/auth/gettotaluserscharts');

		// Replace 'Freelancer' with 'Expert'
		const updatedData = res.data.map(item => {
			if (item.name === 'Freelancer') {
				return { ...item, name: 'Expert' };
			}
			else if (item.name === "Client"){
				return { ...item, name: 'Client'};
			}
			return item;
		});

		dispatch({
			type: TOTAL_USERS_CHARTS,
			payload: updatedData
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			//dispatch(setAlert(errors.msg, 'danger'));
		}
	}
}



export const getGoogleAnalyticsReport = ()=> async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	try {
		
		const res = await axios.get('/api/admin/googleAnalyticsReport', config);
		
		if (res.data == null)
			res.data = [];

		dispatch({
			type: GOOGLE_ANALYTICS_REPORT,
			payload: res.data?.googleAnalyticsReport
		})
		// console.log("check")++


	} catch (err) {
		const errors = err.response.data;

		//console.log(errors);
	}

}