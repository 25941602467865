import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import Logo from '../../components/logo/Logo';
import useSweetAlert from '../../hooks/useSweetAlert';

const Question2 = () => {
  const { formType } = useParams();
  const storageKey = `answers2-${formType}`;
  const [formData, setFormData] = useState({});
  const { showToast } = useSweetAlert();

  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const history = useHistory();
  const dispatch = useDispatch();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const savedAnswers = localStorage.getItem(storageKey);
    if (savedAnswers) {
      setFormData(JSON.parse(savedAnswers));
    }
  }, [storageKey]);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(formData));
  }, [formData, storageKey]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true); // Set formSubmitted to true when form is submitted

    const currentQuestionId = currentQuestions.length > 0 ? currentQuestions[0].id : null;

    if (currentQuestionId) {
      const selectedOptions = formData[currentQuestionId];
      if (!selectedOptions || selectedOptions.length === 0) {
        // Display error message for unanswered question
        //dispatch(setAlert("Please select at least one option.", 'danger'));
        return;
      }
    }

    history.push(`/question3/${formType}`);
  };

  const handleSelectChange = (selectedOptions) => {
    const newFormData = { ...formData, [currentQuestions[0].id]: selectedOptions.map(option => option.value) };
    setFormData(newFormData);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 'auto',
      minHeight: '40px',
      maxHeight: '200px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : null,
      color: state.isSelected ? 'white' : 'black',
    }),
  };

  const questions = {
    individual: [
      {
        id: 2.1,
        question: "What specific areas of cybersecurity assistance do you require?",
        "options": [
          "Malware Analysis",
          "Penetration Testing - Network & Appliances",
          "Vulnerability Assessments",
          "Endpoint Protection",
          "Cyber Security",
          "Information security/Cybersecurity documentation",
          "Security Baseline Analysis",
          "Firewall",
          "Patch Management",
          "Update/Upgrade",
          "SIEM/SOAR",
          "Password Recovery",
          "XDR/MDR",
          "Penetration Testing - Application",
          "Forensic Services",
          "Data Recovery Services",
          "Configuration Management",
          "Incident Response",
          "Cloud Security",
          "IDS/IPS",
          "Information Security",
          "Cyber Mental Health",
          "Cybersecurity Consultation",
          "Cybersecurity Awareness",
          "Cybersecurity Training",
          "Secure Frontend",
          "Secure Backend",
          "Secure Coding",
          "Cybersecurity Education",
        ]
      }
    ],
    company: [
      {
        id: 2.1,
        question: "What specific areas of cybersecurity expertise are you seeking?",
        "options": [
          "Malware Analysis",
          "Penetration Testing - Network & Appliances",
          "Vulnerability Assessments",
          "Endpoint Protection",
          "Cyber Security",
          "Information security/Cybersecurity documentation",
          "Security Baseline Analysis",
          "Firewall",
          "Patch Management",
          "Update/Upgrade",
          "SIEM/SOAR",
          "Password Recovery",
          "XDR/MDR",
          "Penetration Testing - Application",
          "Forensic Services",
          "Data Recovery Services",
          "Configuration Management",
          "Incident Response",
          "Cloud Security",
          "IDS/IPS",
          "Information Security",
          "Cyber Mental Health",
          "Cybersecurity Consultation",
          "Cybersecurity Awareness",
          "Cybersecurity Training",
          "Secure Frontend",
          "Secure Backend",
          "Secure Coding",
          "Cybersecurity Education",
        ]
      }
    ]
  };

  const currentQuestions = questions[formType] || [];
  const options = currentQuestions.length > 0 ? currentQuestions[0].options.map(option => ({ value: option, label: option })) : [];

  return (
    <section className="main-page page-dashboard" style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      height: '100vh', backgroundColor: '#f4f4f4', maxWidth: "100vw", overflowX: "hidden"
    }}>
      <div className="container" style={{ maxWidth: '1200px' }}>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className='w-100 row row justify-content-center d-flex d-sm-none'>


              <Logo />
            </div>
            <div className="bg-white rounded shadow-sm sidebar-page-right" style={{ padding: '2rem' }}>
              <Link to={`/question1/${formType}`} className=" text-dark d-flex mb-2"
                style={{ alignItems: "center", gap: "3px" }}
              >
                <KeyboardBackspaceOutlined />  Back

              </Link>

              <div className="p-3">
                <h4> {formType === 'individual' ? 'Individual' : 'Company'}</h4>
                <h3 className="text-primary">Cybersecurity Needs</h3>
                <p >
                  <span /*style={{ textDecoration: "underline" }}*/ className="font-weight-bold">02</span> of 10
                </p>
                <form onSubmit={handleSubmit}>
                <div className="scrollable">
                  <div className="col-md-12 col-12 mt-4 p-0" style={{ padding: '0%' }}>
                    <h6 className="font-weight-normal mt-4">{currentQuestions.length > 0 && currentQuestions[0].question}</h6>
                  </div>
                  <div className="col-md-12 col-12 p-1" style={{ padding: '0%' }}>
                    <Select
                      isMulti
                      name="skillsSelected"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectChange}
                      styles={customStyles}
                      value={options.filter(option => formData[currentQuestions[0]?.id]?.includes(option.value))}
                    />
                    { // Conditionally render error message if form submitted and selected options are empty
                      formSubmitted && !formData[currentQuestions[0]?.id]?.length && (
                        <div className="text-danger mt-2">
                          Please select at least one option.
                        </div>
                      )
                    }
                  </div>
                  </div>

                  {/* <div className="row forms_buttons w-100 w-sm-96" style={{ width: '96%' }}>
                 <div className="col-md-5 text-left d-none d-sm-block">

                    {/* <Link to={`/question1/${formType}`} className="btn btn-default  ">
                      Back

                    </Link> */}
                  {/* </div> 
                    <div className="col-sm-11 col-md-6 col-11 text-right forms_buttons_SaveNext mr-4 mr-md-0" >

                      <button type="submit" className="btn btn-primary wm-100 " style={{ width: '95% !important' }}>
                        Save & Next
                      </button>
                    </div>
                  </div> */}

                  <div className='w-100 bg-white d-flex justify-content-end' style={{}}>
                    <button type="submit" className="btn btn-primary wm-100 mr-2" style={{ width: '95% !important' }}>
                      Save & Next
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question2;
