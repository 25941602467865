import React, { useState, useEffect , useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { setAlert } from "../../actions/alert";
import { useDispatch } from "react-redux";
import { Close, Delete, KeyboardBackspaceOutlined } from '@material-ui/icons';
import Logo from '../../components/logo/Logo';
import useSweetAlert from '../../hooks/useSweetAlert';
import { uploadFile } from "../../actions/file-crud";

const Question1 = () => {
  const { formType } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useSweetAlert();


  // Define the storage key based on the form type
  const storageKey = `answers1-${formType}`;

  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem(storageKey);
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [image, setImage] = useState(null); // State to hold the uploaded image

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Store answers with the appropriate storage key
    localStorage.setItem(storageKey, JSON.stringify(answers));
  }, [answers, storageKey]); // Include storageKey in the dependency array

  useEffect(() => {
    // Retrieve answers with the appropriate storage key
    const savedAnswers = localStorage.getItem(storageKey);
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, [storageKey]); // Include storageKey in the dependency array

  const questionRefs = useRef({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true); // Set formSubmitted to true when form is submitted

    // Check if there are any unanswered questions
    const unansweredQuestions = currentQuestions.filter(question => {
      const answer = answers[question.id] || '';
      return !answer.trim() && answer.length === 0;
    });

    

    if (unansweredQuestions.length === 0 ) {
      // All questions are answered , move to the next page
      history.push(`/question2/${formType}`);
    }else {
      // showToast('Please fill all the required fields!', 'error');
      const firstUnansweredQuestionId = unansweredQuestions[0].id;
      if (questionRefs.current[firstUnansweredQuestionId]) {
        questionRefs.current[firstUnansweredQuestionId].scrollIntoView({ behavior: 'smooth' });
      }
      const errorMessage = unansweredQuestions.map(question => {
        const fieldName = question.question;
        return `${fieldName} is required.`;
      }).join(' ');

      //dispatch(setAlert(errorMessage, 'danger'));
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  // useEffect(() => {
  //   debugger
  //   const savedImageUrl = localStorage.getItem('1.4');
  //   if (savedImageUrl) {
  //     setAnswers({ ...answers, '1.4': savedImageUrl });

  //   }
  // }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result;
      setAnswers({ ...answers, '1.4': base64Data });
      // localStorage.setItem('1.4', base64Data);
    };
    //console.log("File",file)
    reader.readAsDataURL(file);

    const formData = new FormData();

    if (file.name.toLowerCase().endsWith('.png') || file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg')) {

      formData.append('file', file);
      Promise.all([dispatch(uploadFile(formData))]).then(async (res) => {
        //console.log(res[0].location);
        localStorage.setItem('photo_link', JSON.stringify(res[0].location));
        setAnswers({ ...answers, '1.4': res[0].location });
      })

    } else {
      setAlert("File is not a PNG, JPG, or JPEG", "danger", 3000);
      //console.log('File is not a PNG, JPG, or JPEG');
    }
    }
  };

  const handleRemoveImage = () => {
    setAnswers({ ...answers, '1.4': '' });
  };

  const placeholders = {
    '1.1': {
      individual: "Your Full Name",
      company: "Company Name"
    },
    '1.2': {
      individual: "Your Occupation or Role",
      company: "Company's Core Business"
    },
    '1.3': {
      individual: "Brief Overview of your Cybersecurity Knowledge and Experience",
      company: "Number of Employees (select)"
    },
    '1.4': {
      individual: "Upload your profile photo",
      company: "Upload your company logo"
    }
  };

  const questions = {
    individual: [
      { id: 1.1, question: "What is your full name?", key: 'fullName' },
      { id: 1.2, question: "What is your current occupation or role?", key: 'occupation' },
      { id: 1.3, question: "Can you provide a brief overview of your cybersecurity knowledge and experience?", key: 'cybersecurityExperience' },
      { id: 1.4, question: "Upload your profile photo", key: 'profilePhoto' }
    ],
    company: [
      { id: 1.1, question: "What is the name of your company?", key: 'companyName' },
      { id: 1.2, question: "Describe your company's core business and industry focus.", key: 'businessDescription' },
      { id: 1.3, question: "How many employees does your company currently have?", key: 'employeeCount', options: ['< 10', '10 - 50', '51 - 200', '201 +'] },
      { id: 1.4, question: "Upload your company logo", key: 'companyLogo' }
    ]
  };
  const currentQuestions = questions[formType] || [];
  
  const handleSwitch = () => {
    const newFormType = formType === 'individual' ? 'company' : 'individual';
    history.push(`/question1/${newFormType}`);
  };

  /*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setContainerClass('p-1');
      } else {
        setContainerClass('p-5');
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);*/

  return (
    <section className="main-page page-dashboard" style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102vh',
      backgroundColor: '#f4f4f4', maxWidth: "100vw", overflowX: "hidden"
    }}>
      <div className="container" style={{ maxWidth: '1200px' }}>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className='w-100 row row justify-content-center d-flex d-sm-none ml-0'>


              <Logo />
            </div>

            <div className="bg-white rounded shadow-sm sidebar-page-right p-4 " >
              <Link to={`/request-service?role=client&type=login`} className=" text-dark d-flex mb-2"
              style={{alignItems : "center" , gap: "3px"}}
              >
                <KeyboardBackspaceOutlined />  Back

              </Link>
              <div className="p-3">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <h4>{formType === 'individual' ? 'Individual' : 'Company'}</h4>
                  {/* <button type="button" className="btn btn-secondary" onClick={handleSwitch}>
                    {formType === 'individual' ? 'Switch to Company' : 'Switch to Individual'}
                  </button> */}
                </div>
                <h3 className="text-primary">{formType === 'individual' ? 'Personal Information' : 'Company Information'}</h3>
                <p >
                  <span /*style={{ textDecoration: "underline" }}*/ className="font-weight-bold">01</span> of 10
                </p>
                <form onSubmit={handleSubmit}>
                <div className="scrollable">
                    {currentQuestions.map((question) => (
                      <div key={question.id} ref={(el) => (questionRefs.current[question.id] = el)}>
                        <h6 className="font-weight-normal mt-4">{question.question}</h6>
                        {question.key === 'profilePhoto' || question.key === 'companyLogo' ? (
                          <div 
                            className="form-group d-flex flex-column align-items-center"
                            onDrop={(e) => {
                              e.preventDefault();
                              const file = e.dataTransfer.files[0];
                              if (file) {
                                setAnswers({ ...answers, '1.4': URL.createObjectURL(file) });
                              }
                            }}
                            onDragOver={(e) => e.preventDefault()}
                          >
                            {!answers['1.4'] ? (
                              <div className="image-upload-box p-3 border rounded w-100" style={{ marginTop: "20px",marginBottom : "10px", padding: "30px", border: "2px dashed #ccc", textAlign: "center" }}>
                                                 <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageUpload}
                                  style={{ display: 'none' }}
                                  id="imageUploadInput"
                                />
                                <label htmlFor="imageUploadInput" className="d-block" style={{ cursor: 'pointer' }}>
                                  <p>Drag & drop an image here, or click to select an image</p>
                                </label>
                              </div>
                            ) : (
                              <div className="d-flex flex-column align-items-center">
                                <img src={answers['1.4']} alt="Uploaded" style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }} />
                             <div className='py-2 px-2 ' onClick={handleRemoveImage} >

                                <Close  style={{color: "red"}} />
                           </div>
                              </div>
                            )}
                            {formSubmitted && !answers['1.4'] && (
                              <div className="text-danger mt-2">
                                {question.question} is required.
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="form-group" style={{ marginBottom: "30px" }}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={"Enter " + placeholders[question.id][formType]}
                              value={answers[question.id] || ''}
                              onChange={(e) => { 
                                const value = e.target.value;
                                const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                                handleAnswerChange(question.id, capitalizedValue);}}
                            />
                            { // Add conditional rendering for error message
                              formSubmitted && !answers[question.id]?.trim() && (
                                <div className="text-danger mt-2">
                                   {question.question} is required.
                                </div>
                              )
                            }
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  

                  {/* <div className="row forms_buttons w-100 w-sm-96" style={{ width: '96%' }}>

                    <div className="col-11  text-right forms_buttons_SaveNext">
                      <button type="submit" className="btn btn-primary wm-100">
                        Save & Next
                      </button>
                    </div>
                  </div> */}
                   <div className='w-100 bg-white d-flex justify-content-end' style={{  }}>
                    <button type="submit" className="btn btn-primary wm-100 mr-2" style={{ width: '95% !important' }}>
                        Save & Next
                      </button>
                    </div>

                </form>
              </div>
            </div>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                handleSwitch();
              }}
              className=""
            >
              Not {formType !== 'individual' ? 'a Company' : 'an Individual'} ? {formType === 'individual' ? 'Switch to Company' : 'Switch to Individual'}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question1;