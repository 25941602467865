import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { setAlert } from "../../actions/alert";
import { MHregistration, get_user_check } from "../../actions/enterprise";
import { Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { Icon } from '@iconify/react';
import remove from '@iconify/icons-mdi/close';
import CheckMark from '../../lottie-gif/checkmark-animation.json';
import { useDispatch } from "react-redux";
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import Logo from '../../components/logo/Logo';
import useSweetAlert from '../../hooks/useSweetAlert';

const Question10 = () => {
  const { formType } = useParams();
  const [showModal, setShowModal] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useSweetAlert();


  // Define the storage key based on the form type
  const storageKey = `answers10-${formType}`;

  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem(storageKey);
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorField, setErrorField] = useState(null); // Track the field with error

  const scrollToField = (fieldId) => {
    setTimeout(() => {
      const element = document.getElementById(fieldId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100); // Adjust timeout as needed
  };


  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    scrollToTop();
  }, []);



  /*const handleSubmit = (e) => {
    debugger
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{10}$/; // Simplified international phone number pattern

    const emailAnswer = answers[10.2];
    const phoneAnswer = answers[10.3];



    setFormSubmitted(true);
    const unansweredQuestions = questions[formType].filter(question => !answers.hasOwnProperty(question.id) || answers[question.id].trim() === '');

    if (unansweredQuestions.length === 0) {
      const incompleteQuestions = [];


      // Validate the answers
      const isEmailValid = emailRegex.test(emailAnswer);
      const isPhoneValid = phoneRegex.test(phoneAnswer);

      if (!isEmailValid || !isPhoneValid) {
        // alert("Phone or email is not valid.");
        dispatch(setAlert(`Phone or email is not valid.`, 'danger'));

        return; // Exit the function if email or phone is not valid
      }


      const allAnswers = {};

      const localStorageKeys = formType === 'individual' ?
        Array.from({ length: 10 }, (_, i) => `answers${i + 1}-individual`) :
        Array.from({ length: 10 }, (_, i) => `answers${i + 1}-company`);

      localStorageKeys.forEach((key, index) => {
        const answer = localStorage.getItem(key);
        if (!answer || answer === '{}' || (typeof answer === 'string' && JSON.parse(answer) === '{}')) {
          incompleteQuestions.push(index + 1);
        } else {

          allAnswers[key] = JSON.parse(answer);
        }
      });

      if (incompleteQuestions.length > 0) {

        dispatch(setAlert(`Question(s) ${incompleteQuestions.join(", ")} is/are not completed`, 'danger'));
      } else {

        dispatch(MHregistration(allAnswers, formType, emailAnswer)).then((res) => {
          if (res) {
            history.push('/');
          }
        });
        //setShowModal(true); 
      }
    } else {
      const errorMessage = unansweredQuestions.map(question => {
        const fieldName = question.question;

        return `Field is required.`;
      }).join(' ');

    }

  };*/

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[0-9]{10,15}$/; // Adjusted to allow more flexible phone numbers
  
    const emailAnswer = answers[10.2]?.trim(); // Trim the email input
    const phoneAnswer = answers[10.3]?.trim(); // Trim the phone input
  
    setFormSubmitted(true);
  
    const isEmailValid = emailRegex.test(emailAnswer);
    const isPhoneValid = phoneRegex.test(phoneAnswer);
  
    if (!isEmailValid && !isPhoneValid) {
      dispatch(setAlert('Both phone number and email are not valid.', 'danger'));
    } else if (!isEmailValid) {
      dispatch(setAlert('Email is not valid.', 'danger'));
    } else if (!isPhoneValid) {
      dispatch(setAlert('Phone number is not valid.', 'danger'));
    } else {
      // Continue with form submission if both are valid
      const unansweredQuestions = questions[formType].filter(
        (question) => !answers.hasOwnProperty(question.id) || answers[question.id].trim() === ''
      );
  
      if (unansweredQuestions.length === 0) {
        const incompleteQuestions = [];
  
        const allAnswers = {};
        const localStorageKeys = formType === 'individual'
          ? Array.from({ length: 10 }, (_, i) => `answers${i + 1}-individual`)
          : Array.from({ length: 10 }, (_, i) => `answers${i + 1}-company`);
  
        localStorageKeys.forEach((key, index) => {
          const answer = localStorage.getItem(key);
          if (!answer || answer === '{}' || (typeof answer === 'string' && JSON.parse(answer) === '{}')) {
            incompleteQuestions.push(index + 1);
          } else {
            allAnswers[key] = JSON.parse(answer);
          }
        });
  
        if (incompleteQuestions.length > 0) {
          dispatch(setAlert(`Question(s) ${incompleteQuestions.join(", ")} is/are not completed`, 'danger'));
      showToast('Please fill all the required fields!', 'error');

        } else {
          dispatch(MHregistration(allAnswers, formType, emailAnswer)).then((res) => {
            if (res) {
              history.push('/');
            }
          });
        }
      } else {
      showToast('Please fill all the required fields!', 'error');
        const firstUnanswered = unansweredQuestions[0];
        setErrorField(firstUnanswered.id);
        scrollToField(`question_${firstUnanswered.id}`);
        //console.log(`Scrolling to: question_${firstUnanswered.id}`);
  
        const errorMessage = unansweredQuestions.map((question) => {
          return `${question.question} is required.`;
        }).join(' ');
  
        // dispatch(setAlert(errorMessage, 'danger'));
      }
    }
  };


  useEffect(() => {
    // Store answers with the appropriate storage key
    localStorage.setItem(storageKey, JSON.stringify(answers));
  }, [answers, storageKey]); // Include storageKey in the dependency array


  useEffect(() => {
    // Retrieve answers with the appropriate storage key
    const savedAnswers = localStorage.getItem(storageKey);
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, [storageKey]); // Include storageKey in the dependency array


  const handleCloseModal = () => {
    setShowModal(false);
    history.push('/'); // Adjust the route as needed
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  const UserCompleteProfileModal = (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header>
        <Modal.Title>Form Completed</Modal.Title>
        <Icon className="mx-2 icon-hover" icon={remove} onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: CheckMark,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            },
          }}
          height={180}
          width={180}
        />
        <div className='text-center'>
          <p style={{ fontSize: '17px' }}>{formType === 'individual' ? "Thank you for providing this information. Your responses will help us connect you with the right cybersecurity expert to meet your individual cybersecurity needs and objectives." : "Thank you for taking the time to complete this questionnaire. Your responses will help us find suitable cybersecurity experts for your company's needs."}</p>
        </div>
      </Modal.Body>
    </Modal>
  );

  const placeholders = {
    '10.1': {
      individual: "Best way to reach you for further Discussion or Follow-up",
      company: "Name of the primary contact person for recruitment inquiries"
    },
    '10.2': {
      individual: "Email Address",
      company: "Email Address of the Primary Contact Person"
    },
    '10.3': {
      individual: "Phone number",
      company: "Phone number of the Primary Contact Person"
    }
  };


  const questions = {
    individual: [
      { id: 10.1, question: "What is the best way to reach you for further discussion or follow-up?" },
      { id: 10.2, question: "What is your email address?" },
      { id: 10.3, question: "What is your phone number?" }
    ],
    company: [
      { id: 10.1, question: "What is the name of the primary contact person for recruitment inquiries?" },
      { id: 10.2, question: "What is the email address of the primary contact person?" },
      { id: 10.3, question: "What is the phone number of the primary contact person?" }
    ]
  };

  const currentQuestions = questions[formType] || [];

  return (
    <section className="main-page page-dashboard" style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      height: '100vh', backgroundColor: '#f4f4f4', maxWidth: "100vw", overflowX: "hidden"
    }}>
      <div className="container" style={{ maxWidth: '1200px' }}>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className='w-100 row row justify-content-center d-flex d-sm-none'>


              <Logo />
            </div>
            <div className="bg-white rounded shadow-sm sidebar-page-right" style={{ padding: '2rem' }}>
              <Link to={`/question9/${formType}`} className=" text-dark d-flex mb-2"
                style={{ alignItems: "center", gap: "3px" }}
              >
                <KeyboardBackspaceOutlined />  Back

              </Link>
              <div className="p-3">
                <h4> {formType === 'individual' ? 'Individual' : 'Company'}</h4>
                <h3 className="text-primary">Contact Information</h3>
                <p >
                  <span /*style={{ textDecoration: "underline" }}*/ className="font-weight-bold">10</span> of 10
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="scrollable">
                  {currentQuestions.map((question, index) => (
                    <div key={index} id={`question_${question.id}`}>
                      <h6 className="font-weight-normal mt-4">{question.question}</h6>
                      {question.options ? (
                        <div className="form-group">
                          {question.options.map((option, optionIndex) => (
                            <div key={optionIndex}
                              onClick={() => handleAnswerChange(question.id, option)}
                              className={`option-item ${answers[question.id] === option ? 'selected' : ''}`}
                              style={{ marginBottom: "10px", cursor: "pointer", padding: "10px", border: "1px solid #ccc", borderRadius: "5px", backgroundColor: answers[question.id] === option ? '#007bff' : 'transparent', color: answers[question.id] === option ? 'white' : 'black' }}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="form-group" style={{ marginBottom: "30px" }} >
                          <input
                            type={question.id === 10.2 ? "email" : question.id === 10.3 ? "tel" : "text"} // Email, Phone, or Text type based on question ID
                            className={`form-control ${formSubmitted && !answers[question.id]?.trim() ? 'p-1' : ''}`}
                            placeholder={`Enter ${placeholders[question.id][formType]}`}
                            value={answers[question.id] || ''}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (question.id === 10.1) {
                                // Capitalize the first letter for the name field
                                value = value.charAt(0).toUpperCase() + value.slice(1);
                              } else if (question.id === 10.2) {
                                // Keep email input lowercase
                                value = value.toLowerCase();
                              } else if (question.id === 10.3) {
                                // Only allow numbers for the phone number field
                                value = value.replace(/\D/g, ''); // Remove non-numeric characters
                              }
                              handleAnswerChange(question.id, value);
                            }}
                            inputMode={question.id === 10.3 ? "numeric" : question.id === 10.2 ? "email" : "text"} // Numeric for phone, email for email field, text otherwise
                            autoCapitalize={question.id === 10.2 ? "none" : "sentences"} // No capitalization for email, sentences for others
                          />
                          {formSubmitted && !answers[question.id]?.trim() && (
                            <div className="text-danger mt-2">
                                  {question.question} is required.

                              {/* Please fill the required field. */}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}

                  </div>
                  {/** <div className="row justify-content-center complete_profile_buttons"> 
                    <div className="text-right">
                      <Link to={`/question9/${formType}`} className="btn btn-default mr-3">Back</Link>
                      <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                  </div>
                  
                  <div className="row justify-content-center forms_buttons">
                    <div className="text-right mm-2">
                        <Link to={`/question9/${formType}`}  className="btn btn-default mr-3">Back</Link>
                        {/*<Link to="/education" className="c-btn c-fill-color-btn ">Next: Education</Link>
                        
                          <button type="submit" className="btn btn-primary">
                            Save & Next
                          </button>
                    </div>
                    </div>*/}

                  <div className='w-100 bg-white d-flex justify-content-end' style={{}}>
                    <button type="submit" className="btn btn-primary wm-100 mr-2" style={{ width: '95% !important' }}>
                      Save & Next
                    </button>
                  </div>
                </form>
                {UserCompleteProfileModal}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question10;

