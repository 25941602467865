import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { getAllDisputes } from '../../actions/dispute';
import { Icon } from 'react-icons-kit';
import { arrowLeft, arrowRight } from 'react-icons-kit/fa'; 
import PropTypes from "prop-types";

import DisputeOffcanvas from './disputecanvas';
import { CircularProgress } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";

const Dispute = ({ auth: { isAuthenticated, user }, getAllDisputes, dispute: { disputes } }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [disputesPerPage] = useState(5); 
  const [loading, setLoading] = useState(true);
  
  const [showDisputeOffcanvas, setShowDisputeOffcanvas] = useState(false);
  const [currentDispute, setCurrentDispute] = useState(null);

 // Wrapper function to handle loading state
 const fetchDisputes = ( ) => {
  setLoading(true); 
     getAllDisputes(setLoading);
  
    // setLoading(false); // Set loading to false after the API call completes
  }


useEffect(() => {
  fetchDisputes();
}, []);





  //console.log("disputes: ", disputes)

  const indexOfLastDispute = currentPage * disputesPerPage;
  const indexOfFirstDispute = indexOfLastDispute - disputesPerPage;
  const currentDisputes = disputes.slice(indexOfFirstDispute, indexOfLastDispute);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(disputes.length / disputesPerPage);

  return (
    <div className="main-page page-dashboard">
      <Helmet>
        <title>Disputes</title>
      </Helmet>
      <div className="container-fluid">
        <section className="talent-list">
        {loading ? 
          // <div className="message-not-available w-100">
          // <div className="text-center">
          // <CircularProgress />
          // </div>
          // </div>
          <div className="message-not-available w-100">
          {[...Array(3)].map((_, index) => (
            <div className="freelancer-list talent-block w-100">
            <div className="row" style={{ display: 'flex', flexWrap: 'wrap', marginRight: '0%', marginLeft: '-4%' }}>
              <div className="col-md-1 mt-0"> 
                {/* <Skeleton circle={true} height={50} width={50} /> */}
              </div>
              <div className="col-md-7 mt-0"> 
        <div style={{ display: "flex", alignItems: "center" }}>
          <Skeleton width={150} height={30} />
        </div>
        <p style={{ textAlign: 'left' }}><Skeleton width={150} /></p>
        <span className="badge bg-secondary mr-2 my-1" style={{ display: 'inline-block', textAlign: 'left' }}>
          <Skeleton width={80} />
        </span>
        <p className="my-1" style={{ textAlign: 'left' }}><Skeleton width={`50%`} /></p>
        <div className="mb-3" style={{ textAlign: 'left' }}>
          <p className="font-weight-bold" style={{ fontSize: "0.75rem", paddingTop: "10px", textAlign: 'left' }}>
            <Skeleton width={`30%`} />
          </p>
          <p className="font-weight-bold" style={{ fontSize: "0.75rem", paddingTop: "10px", textAlign: 'left' }}>
            <Skeleton width={`40%`} />
          </p>
        </div>
      </div>
              <div className="col-md-3 mt-0 button-group">
                <Skeleton width={100} height={40} />
              </div>
            </div>
          </div>
          ))}
        </div>
          :
        
        currentDisputes.length > 0 ? (
          currentDisputes.map((dispute, index) => (
            <div key={dispute._id} className="freelancer-list talent-block">
              
              <div className="row" style={{ display: 'flex', flexWrap: 'wrap', marginRight: '0%', marginLeft: '-4%' }}>
                <div className="col-md-1 mt-0"> 
                </div>
                <div className="col-md-7 mt-0"> 
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3 className="text-primary mb-0">{`Dispute: ${index + 1}`}</h3>
                  </div>
                  <p className="font-weight-bold mb-0">{dispute.description}</p>
                  <span className="badge bg-secondary mr-2 my-1">Status: {dispute.status}</span>
                  <p className="my-1">Reason: {dispute.reason}</p>
                  <div className="mb-3">
                    <p className="font-weight-bold" style={{ fontSize: "0.75rem", paddingTop: "10px" }}>
                      Created At: {new Date(dispute.created_at).toLocaleDateString()}
                    </p>
                    <p className="font-weight-bold" style={{ fontSize: "0.75rem", paddingTop: "10px" }}>
                      Created By: {dispute.disputeuser.first_name} {dispute.disputeuser.last_name} ({dispute.disputeuser.type === "Freelancer" ? "Expert" : dispute.disputeuser.type})
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mt-0 button-group">
                  <>
                  <button className="btn btn-default btn-sn col-md-4 mb-2" 
                    onClick={() => { setShowDisputeOffcanvas(true); setCurrentDispute(dispute); }}>
                    Resolve
                  </button>

                  <DisputeOffcanvas
                    show={showDisputeOffcanvas}
                    onHide={() => setShowDisputeOffcanvas(false)}
                    disputeData={currentDispute} 
                    userId={user._id}
                  />

                  </>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="p-5">
              {/** <h6 className="text-primary text-center mb-0">
                No Search Result Found
              </h6>
              */}
              <div className="p-5">
              <div className="message-not-available w-100">
              <div className="text-center">
                <img src="assets/images/no-data-found.png" width={'200px'} alt="message" className="mb-4"/>
                <h1>Sorry! No Data Found</h1>
                <p className="land">we couldn't find any disputes.</p>
              </div>
              </div>
              </div>
            </div>
        )}

        </section>
        {totalPages > 1 && (
          <nav>
            <ul className="talent-pagination pagination justify-content-center">
              <div
                className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() =>
                  setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)
                }
              >
                {/** <Icon icon={arrowLeft} />*/}
                <PaginationArrowLeft/>
              </div>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
              <div
                className={`icon ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                onClick={() =>
                  setCurrentPage(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
              >
                {/** <Icon icon={arrowRight} />*/}
                <PaginationArrowRight/>
              </div>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );  
};

Dispute.propTypes = {
  getAllDisputes: PropTypes.func.isRequired,
  dispute: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dispute: state.dispute,
});

export default connect(mapStateToProps, { getAllDisputes })(Dispute);
