import React from "react";
import { useEffect, useState } from "react";
import { connect , useDispatch} from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  contractDelete,
  contractGet,
  contractVerify,
  contractGetbyId,
  revokeContract,
} from "../../actions/proposalAndContract";
import Alert from "../../components/alert/Alert";
import Skeleton from "./../../components/skeleton";
// import "./ContractList.scss";

import Modal from "react-bootstrap/Modal";
import { remove } from "react-icons-kit/fa/remove";
import { Icon } from "react-icons-kit";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { budget_status } from "../../actions/enterprise";

import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";

import classes from "./contract.module.css";
import { driver } from "driver.js";
import { Help } from "@material-ui/icons";
import { QuestionLg } from "react-bootstrap-icons";
const ContractList = ({
  auth,
  auth: { user },
  proposalAndContract: { ContractsItemData, ContractData },
  contractGet,
  contractVerify,
  contractDelete,
  contractGetbyId,
  revokeContract,
}) => {
  const [paginatedDataItem, setPaginatedDataItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [showPay, setShowPay] = useState(true);
  //const totalPages = Math.ceil(ContractsItemData?.length / resultsPerPage);

  const dispatch = useDispatch();
  
  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
  };

  const history = useHistory();

  useEffect(() => {
    document.title = "Contract List";
    if (ContractsItemData) {
      setPaginatedDataItem([...ContractsItemData.reverse()]);
    }
  }, [ContractsItemData]);

  useEffect(() => {
    dispatch(budget_status(user.email)).then((res)=>{
      //console.log(res)
      if(res === 'Paid'){
        setShowPay(false);
      }   
});
  }, []);

  //console.log("ContractsItemData: ", ContractsItemData);

  const [filter, setFilter] = useState("All");

  useEffect(() => {
    if (ContractsItemData) {
      let filteredData = ContractsItemData;

      if (filter !== "All") {
        filteredData = ContractsItemData.filter(
          (contract) => contract.contract_status === filter
        );
      }

      setPaginatedDataItem([...filteredData]);
      setCurrentPage(1);
    }
  }, [ContractsItemData, filter]);

  const totalPages = paginatedDataItem
    ? Math.ceil(paginatedDataItem.length / resultsPerPage)
    : 0;

  useEffect(() => {
    contractGet();
  }, []);

  const handleVerify = (id, status) => {
    let data = {
      contract_id: id,
      status: status,
      user_name: user.user_name,
      first_name: user.first_name,
      last_name: user.last_name
    };
    contractVerify(data);
  };

  const handleContract = (id) => {
    contractGetbyId(id)
      .then((res) => {
        if (res) {
          if (ContractData) {
            //console.log(ContractData);
            history.push("/contractDetails");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        //yahan error handle karo
      });
  };

  // New state to control the visibility of the MilestoneDetails modal
  const [showMilestoneDetail, setShowMilestoneDetail] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(null);

  // Handler to open the MilestoneDetails modal
  const handleOpenMilestoneDetail = (milestone) => {
    setSelectedMilestone(milestone);
    setShowMilestoneDetail(true);
  };

  // Handler to close the MilestoneDetails modal
  const handleCloseMilestoneDetail = () => {
    setSelectedMilestone(null);
    setShowMilestoneDetail(false);
  };

  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentResults = paginatedDataItem
    ? [...paginatedDataItem].slice(startIndex, endIndex)
    : null;

    const startTour = () => {
      const steps = [];
    
      // Add the first step only if the user is a freelancer
      // if (user.type === "Freelancer") {
      //   steps.push({
      //     element: '#edit',
      //     popover: {
      //       title: 'Edit Profile',
      //       description: 'As an expert, you can go to edit profile.',
      //       side: "left",
      //       align: 'start',
      //     },
      //   });
      // }
    
      // Add the remaining steps with descriptions based on user type
      steps.push(
     
        {
          element: '#contracts',
          popover: {
            title: 'Contract List',
            description: 'here you can see all your contracts.',
            side: "left",
            align: 'start',
          },
         
        },
        {
          element: '#nodata',
          popover: {
            title: 'Find ',
            description: user.type === "Client" ? 'If no contracts are here you can find talents from this button':'If no contracts are here you can find job from this button.',
            side: "left",
            align: 'start',
          },
        }
      );
    
      const driverObj = new driver({
        showProgress: true,
        steps: steps, // Use the dynamically created steps
      });
    
      driverObj.drive();
    };

  return (
    <section className="main-page page-dashboard">
      <div 
        onClick={startTour}
          className="tour" >
        <QuestionLg />
        </div>
      <div  className="container-fluid">
        <div id="contracts" className="panel-box">
          <header
            className="panel-box--header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="panel-box--title">Contract List</h1>

            <div
              className="block sort-block"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ marginRight: "8px" }}>Filter by:</div>
              <select onChange={(e) => setFilter(e.target.value)}>
                <option value="All">All</option>
                <option value="Active">Active</option>
                <option value="Finished">Finished</option>
                <option value="Revoked">Revoked</option>
                <option value="Not Started">Not Started</option>
              </select>
            </div>
          </header>

          <main className="panel-box--body overflow-scroll">
            <Alert />

            {ContractsItemData === null && (
              <div style={{ overflowX: "scroll" }}>
                <table className="table table-layout-fixed table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>
                        <Skeleton width={120} height={25} />
                      </th>
                      <th>
                        <Skeleton width={180} height={25} />
                      </th>
                      <th>
                        <Skeleton width={100} height={25} />
                      </th>
                      <th>
                        <Skeleton width={80} height={25} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                      <tr key={i}>
                        <td>
                          <Skeleton height={25} />
                        </td>
                        <td>
                          <Skeleton height={25} />
                        </td>
                        <td>
                          <Skeleton height={25} />
                        </td>
                        <td>
                          <Skeleton height={25} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {currentResults && currentResults.length > 0 ? (
              <div style={{ overflowX: "scroll" }}>
                <table
                  className={`table table-layout-fixed table-striped ${classes.customeTable}`}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      {auth.user.type !== "Freelancer" ? (
                        <th>Freelancer</th>
                      ) : (
                        <th>Hire Manager</th>
                      )}

                      <th>Job</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...currentResults].map((contract) => (
                      <tr key={contract._id}>
                        <td>
                          {new Date(contract.createdAt).toLocaleDateString()}
                        </td>
                        {auth.user.type !== "Freelancer" ? (
                          <td>
                            {contract?.freelancer_id?.user_id?.first_name +
                              " " +
                              contract?.freelancer_id?.user_id?.last_name}
                          </td>
                        ) : (
                          <td>
                            {contract.hire_manager_id.first_name +
                              " " +
                              contract.hire_manager_id.last_name}
                          </td>
                        )}

                        <td>{contract.job_id.headline}</td>
                        <td>{contract.contract_status}</td>
                        <td className="action-data">
                          {auth.user.type === "Freelancer" &&
                            contract.contract_status === "Pending" && (
                              <>
                                <Modal
                                  show={showMilestoneDetail}
                                  onHide={handleCloseMilestoneDetail}
                                  centered
                                  size="xl"
                                  className={showMilestoneDetail ? "xl" : ""}
                                >
                                  <Modal.Header>
                                    <Modal.Title>
                                      NDA for Technical Expert at Cyber To
                                      Cyber:
                                    </Modal.Title>
                                    <Icon
                                      className="mx-2 icon-hover"
                                      icon={remove}
                                      onClick={handleCloseMilestoneDetail}
                                    />
                                  </Modal.Header>
                                  <Modal.Body>
                                    <ol>
                                      <li>
                                        Cyber To Cyber may conduct appropriate
                                        personnel background checks on Technical
                                        Experts prior to authorizing access to
                                        the platform, data, service, or
                                        infrastructure. The background check may
                                        include criminal and credit record
                                        verification, validation of personal
                                        reference from previous experiences and
                                        or employers, part from academic and
                                        other qualifications.
                                      </li>
                                      <li>
                                        Technical Experts and other individuals
                                        delivering services to Cyber To Cyber
                                        must sign a Non-Disclosure Agreement
                                        (“NDA”).
                                      </li>
                                      <li>
                                        The Technical Experts shall not access
                                        or collect any Personal Data or
                                        sensitive data/ information (financial,
                                        information, account number, financial
                                        statement, etc.) of the customers of the
                                        Cyber To Cyber without the prior written
                                        consent from customer and approval of
                                        the Cyber To Cyber
                                      </li>
                                      <li>
                                        All individuals working at or for Cyber
                                        To Cyber are to comply with Cyber To
                                        Cyber Policies, Procedures and
                                        Standards, as well as the laws and
                                        regulations of the Kingdom of Saudi
                                        Arabia.
                                      </li>
                                      <li>
                                        Confidential Information shall not be
                                        disclosed to any third party without the
                                        express written approval of Cyber To
                                        Cyber, except if such disclosure is
                                        required by law or regulation, in which
                                        case Cyber To Cyber shall promptly be
                                        informed of such disclosure, where this
                                        is legally permissible. The Cyber To
                                        Cyber data shall never be used or shared
                                        for any secondary purpose by the
                                        Technical Experts.{" "}
                                      </li>
                                      <li>
                                        The customer related data shall not be
                                        retained by the Technical Experts longer
                                        than is required for fulfilment of the
                                        service and is to be returned or
                                        destroyed at the completion of the
                                        engagement.{" "}
                                      </li>
                                      <li>
                                        All electronic data shall be permanently
                                        deleted and removed from the Technical
                                        Experts’ electronic system and any
                                        physical copies Data shall be disposed.
                                      </li>
                                      <li>
                                        The Technical Experts shall maintain
                                        compliance with all applicable Kingdom
                                        of Saudi Arabia rules and regulations
                                        for information security and data
                                        protection.
                                      </li>
                                      <li>
                                        The data received by the Technical
                                        Experts through the Cyber To Cyber
                                        platform shall only be used for the
                                        intended purpose and shall never be used
                                        or shared for any secondary purpose,
                                        unless Cyber To Cyber has given written
                                        authority to the Technical Experts.
                                      </li>
                                      <li>
                                        The Technical Experts agrees that the
                                        intellectual property rights of the
                                        Cyber To Cyber including service and the
                                        authorship, systems, ideas, methods of
                                        operation, documentation, are
                                        proprietary intellectual property and
                                        the valuable trade secrets of the Cyber
                                        To Cyber. Any pre-existing proprietary
                                        or Confidential Information of the Cyber
                                        To Cyber used to render the service, or
                                        included into the results or
                                        deliverables of the service provision
                                        including but not limited to software,
                                        appliances, methodologies, code,
                                        templates, tools, policies, records,
                                        working papers, know-how, Data or other
                                        intellectual property, written or
                                        otherwise shall remain exclusive
                                        property of the Cyber To Cyber.
                                      </li>
                                      <li>
                                        The ultimate responsibility of the
                                        client/customer data lies with the
                                        technical expert offering the services,
                                        the experts will be held accountable and
                                        responsible for the protection of the
                                        customer data against leakages,
                                        exposures, and unauthorized disclosures.
                                        Cyber To Cyber cannot be held liable,
                                        accountable, or responsible for any
                                        exposures or infringements.
                                      </li>
                                      <li>
                                        {" "}
                                        The Technical Experts shall be liable
                                        for any breach of obligations listed in
                                        the NDA if the customer related or Cyber
                                        To Cyber trade secrets, financial, or
                                        intellectual properly related
                                        information is disclosed.
                                      </li>
                                      <li>
                                        Upon completion, closure, continuation,
                                        or novation of the project, the data
                                        protection liability of technical
                                        experts shall continue.{" "}
                                      </li>
                                      <li>
                                        The Technical Experts shall not
                                        subcontract the performance of the
                                        services to its 3rd parties without
                                        prior written consent from Cyber To
                                        Cyber. In the event Cyber To Cyber
                                        authorizes the Technical Expertsto
                                        subcontract to a third party the
                                        Technical Expertsshall be held
                                        responsible and accountable for the
                                        activities of the sub-contracting
                                        expert.
                                      </li>
                                    </ol>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <button
                                      onClick={() => {
                                        handleVerify(contract._id, "Accepted");
                                        handleCloseMilestoneDetail();
                                      }}
                                      className="btn btn-primary"
                                    >
                                      Accept
                                    </button>

                                    <button
                                      className="btn btn-secondary"
                                      onClick={handleCloseMilestoneDetail}
                                    >
                                      Close
                                    </button>
                                  </Modal.Footer>
                                </Modal>

                                <button
                                  className="btn btn-primary btn-sn mx-1"
                                  onClick={() =>
                                    handleOpenMilestoneDetail(contract)
                                  }
                                >
                                  Accept
                                </button>

                                <button
                                  onClick={() =>
                                    handleVerify(contract._id, "Rejected")
                                  }
                                  className="btn btn-danger btn-sn mx-1"
                                >
                                  Reject
                                </button>
                              </>
                            )}

                          {auth.user.type === "Client" &&
                            contract.hire_manager_id._id === auth.user._id &&
                            ["Accepted", "Pending"].indexOf(
                              contract.contract_status
                            ) >= 0 && (
                              <button
                                onClick={() => revokeContract(contract._id)}
                                className="btn btn-danger btn-sn mx-1"
                              >
                                Revoke
                              </button>
                            )}

                          <button
                            className="btn btn-primary btn-sn mx-1"
                            onClick={() => handleContract(contract._id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
            {totalPages > 1 && (
              //FIXED PAGINATION
              <nav>
                <ul className="talent-pagination pagination justify-content-center">
                  <div
                    className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                    onClick={() =>
                      handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                    }
                  >
                {/** <Icon icon={arrowLeft} />*/}
                <PaginationArrowLeft/>
                  </div>
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  })}
                  <div
                    className={`icon ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    onClick={() =>
                      handlePageChange(
                        currentPage === totalPages
                          ? totalPages
                          : currentPage + 1
                      )
                    }
                  >
                {/** <Icon icon={arrowRight} />*/}
                <PaginationArrowRight/>
                  </div>
                </ul>
              </nav>

              /*
                <nav>
                  <ul className="pagination justify-content-center">
                    {[...Array(totalPages)].map((_, index) => {
                      const pageNumber = index + 1;
                      return (
                        <li
                          key={pageNumber}
                          className={`page-item ${
                            pageNumber === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
                 */
            )}
          </main>
        </div>
        {currentResults && currentResults.length > 0 ? null : (
          <>
            {/** <h4>No Record Found</h4>*/}
            <div className="mt-5 p-5">
                      <div className="p-5">
                        <div className="message-not-available w-100">
                          <div className="text-center">
                            {user.type === "Enterprise Client" && showPay? (
                              <>
                              <div className="mt-5">
                                <h1>Welcome</h1>
                                <p className="land">Define your budget to proceed</p>
                                <Link to="/wallet" style={{ marginTop: '5px' }} className="btn btn-primary">
                                    Pay
                                </Link>
                                </div>
                              </>
                            ) : (
                              <div  className="mt-5">
                                <img
                                  src="assets/images/no-data-found.png"
                                  width={"200px"}
                                  alt="message"
                                  className="mb-4"
                                />
                                <h1>Sorry! No Data Found</h1>
                                <p className="land">
                                  Sorry, we couldn't find any jobs matching your
                                  search criteria
                                </p>
                                {user.type === "Freelancer" ? (
                                  <Link id="nodata" to="/job" className="btn btn-primary mt-2">
                                    GO TO FIND WORK
                                  </Link>
                                ) : user.type === "Client" ? (
                                  <Link id="nodata" to="/talent" className="btn btn-primary mt-2">
                                    GO TO FIND TALENT
                                  </Link>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

          </>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  proposalAndContract: state.proposalAndContract,
});

export default connect(mapStateToProps, {
  contractGet,
  contractVerify,
  contractDelete,
  contractGetbyId,
  revokeContract,
})(ContractList);
