import { connect } from "react-redux";
import { getWallet, paypalapi } from "../../actions/wallet";
import {
  getTapsCharge,
  addToWallet,
  getTapsstatus,
  updateTapsstatus,
} from "../../actions/checkout";
import { getTransaction } from "../../actions/transaction";
import { useDispatch, useSelector } from "react-redux";
import store from "../../store";
import Input from "../../components/input/input";
import React, { useEffect, useState , useRef  } from "react";
import { useLocation } from "react-router-dom";

import GoSellDemo from "./checkout";
import PayPalButton from "./paypal";
import Modal from "react-bootstrap/Modal";
import Alert from "../../components/alert/Alert";
import { setAlert } from "../../actions/alert";
import { useErrorBoundary } from "use-error-boundary";
import Skeleton from "./../../components/skeleton";
import { ErrorBoundary } from "react-error-boundary";
import { remove } from "react-icons-kit/fa/remove";
import { Icon } from "react-icons-kit";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "./Loading";
import { FileUploader } from "react-drag-drop-files";
import { SVGWidgetLogo} from "../../components/SVG";
import { uploadFile } from "../../actions/file-crud";
import { AddSlip} from "../../actions/enterprise";

const Wallet = ({
  auth: { isAuthenticated, user },
  paypalapi,
  getTapsCharge,
  getTapsstatus,
  updateTapsstatus,
  setAlert,
  addToWallet,
}) => {
  const dispatch = useDispatch();
  const amount = useSelector((state) => state.wallet);
  const [isLoading, setIsLoading] = useState(false);
  const [slipImage, setSlipImage] = useState(''); 
  const [taps_auth, setTapsAuth] = useState(false);
  const [showInput, setShowInput] = useState(false);

  const popupWindowRef = useRef(null);

  // Solution 2 //
  /*const location = useLocation();
  const history = useHistory();
  const homepage_query = useLocation();
  const searchParams = new URLSearchParams(homepage_query.search);
  let id = searchParams.get("tap_id");

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const res = await getTapsCharge(id);
          if (res) {
            // Handle different response scenarios
            if (res.http_code === "403") {
              return "Session expired try again";
            }

            if (res.status === "CAPTURED") {
              setAlert("Payment Successful", "success");
              const addToWalletRes = await addToWallet(
                res.amount / 3.75,
                id,
                res.customer.email,
                user.type
              );
              if (addToWalletRes) {
                localStorage.removeItem("charge_id");
                updateTapsstatus("approved", id);
                dispatch(getWallet());
                scrollToTop();
                searchParams.delete("tap_id");
                history.replace({
                  pathname: location.pathname,
                  search: searchParams.toString()
                });
                id = null; // Reset id to null after completing the process
                return true;
              }
            } else if (res.response.code === "100") {
              // Handle code 100 specific logic if necessary
              return false;
            } else if (res.response.code !== "000" && res.response.code !== "100") {
              setAlert("Payment " + res.response.message, "danger", 3000);
              localStorage.removeItem("charge_id");
              updateTapsstatus("rejected", id);
              searchParams.delete("tap_id");
              history.replace({
                pathname: location.pathname,
                search: searchParams.toString()
              });
              id = null; // Reset id to null after completing the process
              return "rejected";
            }
          } else {
            // No response found handling
            return false;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error as needed
        }
      }
    };

    fetchData();

    fetchData();
  }, [id]); // Dependencies for useEffect*/
  
 // Solution 2 //

  //const transactions = store.getState().transaction
  //const history = useHistory();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const [fileArray, setFileArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const [taps, setTaps] = useState({
    taps_checkout: false,
  });

  const handleCloseTaps = () => setTaps({ taps_checkout: false });

  const [paypal, setPaypal] = useState({
    paypal_checkout: false,
  });

  const [manual, setManual] = useState({
    manual_checkout: false,
  });

  const handleCloseTapsAuth = () => {
    setTapsAuth(false);
    setTaps({ taps_checkout: false });
  };

  const addSlip = () => {
   if(slipImage){
    dispatch(AddSlip(slipImage , user.email));
   }
  };

  const fileTypes = ["JPEG", "PNG", "JPG", "PDF", "DOC", "DOCX", "XLS", "XLSX", "PPT", "PPTX"];

  const handleChange = async (e) => {
    
    const formData = new FormData();
    if (e.target.files[0].name.toLowerCase().endsWith('.png') || e.target.files[0].name.toLowerCase().endsWith('.jpg') || e.target.files[0].name.toLowerCase().endsWith('.jpeg')) {

      formData.append('file', e.target.files[0]);
      Promise.all([setLoading(true), dispatch(uploadFile(formData))]).then(async (res) => {
      //console.log(res[1].location);
      setSlipImage(res[1].location)

    })

    } else {
      setAlert("File is not a PNG, JPG, or JPEG", "danger", 3000);
    }

  }

 // Solution 1 //

  const tapsPaymentResponse = async (id) => {
    try {
      const res = await getTapsCharge(id);
      if (res) {
        //console.log("get-charge status", res.http_code);
        if (res.http_code === "403") {
          return "Session expired try again";
        }
        //console.log("get-charge status", res.status);

        if (res.status === "CAPTURED") {
          setAlert("Payment Successful", "success");
          const addToWalletRes = await addToWallet(
            res.amount / 3.75,
            id,
            res.customer.email,
            user.type
          );
          if (addToWalletRes) {
            localStorage.removeItem("charge_id");
            updateTapsstatus("approved", id);
            return true;
          }
        } else if (res.response.code === "100") {
          // Log or handle code 100 specific logic if necessary
          return false;
        } else if (res.response.code !== "000" && res.response.code !== "100") {
          setAlert("Payment " + res.response.message, "danger", 3000);
          localStorage.removeItem("charge_id");
          updateTapsstatus("rejected", id);

          return "rejected"; // Assuming you want to return false in this scenario
        }
      } else {
        //console.log("No response found");
        return false; // or return; if you don't want to explicitly return false
      }
    } catch (error) {
      //console.error("Error processing payment response:", error);
      return false; // Handle the error as needed
    }
  };

  /*useEffect(() => {
    getTapsstatus().then((res) => {
       console.log("taps_status", res)
       if(res[res.length - 1].payment_status === "Pending"){
        tapsPaymentResponse(res[res.length - 1].charge_id);
       }
      });
   }, []);*/

  useEffect(() => {
    let intervalId; // Define intervalId outside of the effect

    const fetchData = async () => {
      if (!taps_auth) {
        clearInterval(intervalId); // Clear interval if taps_auth is false
        return;
      }

      const res = await getTapsstatus();
      //console.log("taps_status", res);
      //console.log("length", res.length);
      //console.log("db response status", res[res.length - 1].payment_status);
      if (res.length > 0 && res[res.length - 1].payment_status === "Pending") {
        const response_status = await tapsPaymentResponse(
          res[res.length - 1].charge_id
        );
        //console.log(response_status);

        if (response_status) {
          //console.log("closed");
          setTapsAuth(false);
          closePopupWindow();
          setTaps({ taps_checkout: false });
          //window.location.reload();
          dispatch(getWallet());
          scrollToTop();
        }
        if (response_status === "Session expired try again") {
          setTapsAuth(false);
          closePopupWindow();
          setTaps({ taps_checkout: false });
          scrollToTop();
          setAlert(response_status, "danger", 5000);
        }
        if (response_status === "rejected") {
          //console.log("closed");
          setTapsAuth(false);
          closePopupWindow();
          setTaps({ taps_checkout: false });
          scrollToTop();
          //window.location.reload();
        }
      }

      // Add a 5-second delay before calling fetchData again
      //setTimeout(fetchData, 10000);
    };

    if (taps_auth) {
      //console.log("checker", taps_auth);
      intervalId = setInterval(fetchData, 15000);
      fetchData();
    }

    return () => clearInterval(intervalId);
  }, [taps_auth]);

   // Solution 1 //

  useEffect(() => {
    document.title = "Wallet";
  }, []);
  useEffect(() => {
    if(user.type === "Client"){
      dispatch(getWallet());
      dispatch(getTransaction());
    }
  }, [user]);

  const handleTransactionComplete = (message) => {
    //setTransactionCompleted(true);
    if (message == "Payment failed") {
      dispatch(setAlert(message, "danger"));
    } else {
      dispatch(setAlert(message, "success"));
    }
  };


  const [formData, setFormData] = useState({
    wallet_amount: "",
    paymentMethod: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  //const { wallet_amount } = formData;

  const onChange = (e) => {
    setTaps({ taps_checkout: false });
    const inputValue = e.target.value;
  
    if (/^\d*$/.test(inputValue)) {
      if (parseInt(inputValue, 10) === 0) {
        dispatch(setAlert("Invalid amount", "danger", 3000));
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.type === "radio") {
          setFormData({ ...formData, paymentMethod: e.target.value });
        } else {
          setFormData({ ...formData, [e.target.name]: e.target.value });
        }
      }
    } else {
      dispatch(setAlert("Amount must be in digits", "danger", 3000));
    }
  };
  

  const onSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (formData.wallet_amount && formData.paymentMethod == "taps") {
      setTaps({ taps_checkout: true });
    } else if (formData.wallet_amount && formData.paymentMethod == "paypal") {
      setPaypal({ paypal_checkout: true });
      handleShow();
      localStorage.setItem(
        "paypal_walletAmount",
        JSON.stringify(formData.wallet_amount)
      );
      //dispatch(paypalapi(formData.wallet_amount));
    } 
    else if (formData.paymentMethod == "manual"){
      setManual({ manual_checkout: true });
      handleShow();
    }
    else if (formData.paymentMethod == "taps" && user.type === "Enterprise Client" ){
      setTaps({ taps_checkout: true });
    }
    else {
      dispatch(setAlert("Please select Payment method", "danger", 3000));
    }
  };

  const handleDivClick = (method) => {
    if(method === 'taps'){
     setShowInput(true)
    }
    else{
      setShowInput(false)
    }
    setFormData((prevState) => ({ ...prevState, paymentMethod: method }));
  };

  {/** const openPopup = () => {
      const url = "google.com"
        if (url) {
        const popupWindow = window.open(url, 'popupWindow', 'width=800,height=600,scrollbars=yes');

        if (!popupWindow) {
          console.error("Popup window could not be opened");
          alert('Please disable your pop-up blocker and refresh the page.');
          // Handle state or other logic based on popup opening failure
        } else {
          // Optionally, store a reference to the popup window if needed
          // popupWindowRef.current = popupWindow;
        }
      } else {
        console.log("No URL provided");
      }
  };*/}

  const handleRedirect = (url) => {
    setTapsAuth(true); 

    /*const iframe = document.getElementById("paymentIframe");
    if (iframe) {
      iframe.src = url; 
    iframe.onload = () => {
      try {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        const iframeBody = iframeDocument.body;
        console.log("Helllooooo")
        console.log("iframe errors", iframeDocument)
        if (iframeBody) {
          const errorElement = iframeBody.querySelector('body');
          if (errorElement && errorElement.innerText.includes("is blocked") || errorElement.innerText.includes("refused to connect") ) {
            console.error("Error occurred: The page is blocked");
          } else {
            console.log("Page loaded successfully");
          }
        } else {
          console.error("Unable to access iframe content");
        }
      } catch (e) {
        console.error("Error accessing iframe content", e);
      }
    };
  } else {
    console.error("Iframe not found");
  }*/
  
  if(url){
    const popupWindow = window.open(url, 'popupWindow', 'width=800,height=600,scrollbars=yes');

    if (!popupWindow) {
      console.error("Popup window could not be opened");
      alert('Please disable your pop-up blocker and refresh the page.');
      setTaps({ taps_checkout: false });
    }else {
      popupWindowRef.current = popupWindow;
    }

    setIsLoading(false); 

  }else{
    console.log("no url")
  }

  //window.location.href = url;


  };

  const closePopupWindow = () => {
    if (popupWindowRef.current) {
      try {
        popupWindowRef.current.close();
      } catch (error) {
        console.error("Error closing popup window:", error);
      }
      popupWindowRef.current = null;
    } else {
      console.log("Popup window is already closed or not opened yet.");
    }
  };


  const errorhandle = (msg) => {
    dispatch(setAlert(msg, "danger", 3000));
  };


  return (
    <section className="main-page page-dashboard">
      <div className="container">
        <form onSubmit={(e) => onSubmit(e)}>
          <Alert />
          {/* First Row */}
          <div className="row">
            <div className="col-md-12">
              <div className="rounded">
              {user.type === "Client" ? (
                <div className="card-body1">
                  <div className="row" style={{ borderBottom: "3px dotted lightgrey" }}>
                    <div className="col-md-3 text-center border-box">
                      <p style={{ textAlign: "left" }}>Enter Amount in USD</p>
                      <Input
                        parentclass="user"
                        labelfor="Add Money to Your Wallet."
                        id="user1"
                        className="form-control"
                        group={true}
                        name="wallet_amount"
                        iconType={null}
                        required={true}
                        type="text"
                        value={formData.wallet_amount}
                        handlerOnChange={(e) => onChange(e)}
                      />
                    </div>
                    
                    <div className="col-md-3 text-center border-box" style={{ borderRight: "2px dotted lightgrey" }}></div>
                    <div className="col-md-3 text-center border-box" style={{ borderLeft: "2px dotted lightgrey" }}></div>
                    <div className="col-md-3 text-center border-box">
                      <p style={{ textAlign: "left" }}>Total Amount</p>
                      {amount.walletAmount === null ? (
                        <div className="d-flex w-100">
                          <Skeleton width={140} height={50} />
                        </div>
                      ) : (
                        <h1 className="font-weight-bold m-0 text-left">${amount.walletAmount}</h1>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-body1">
                <div className="row" style={{ borderBottom: "3px dotted lightgrey" }}>
                  <div className="col-md-3 text-center border-box">
                    {showInput && (
                      <>
                        <p style={{ textAlign: "left" }}>Enter Amount in USD</p>
                        <Input
                          parentclass="user"
                          labelfor="Add Money to Your Wallet."
                          id="user1"
                          className="form-control"
                          group={true}
                          name="wallet_amount"
                          iconType={null}
                          required={true}
                          type="text"
                          value={formData.wallet_amount}
                          handlerOnChange={(e) => onChange(e)} // Changed handlerOnChange to onChange
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              
              )}

              </div>
              
            </div>
          </div>

          {/* Second Row */}
          <div className="payment-method-title">
            Choose a payment method{" "}
            <span>(Click one of the options below)</span>
          </div>

          <div className="py-3 d-flex align-item-center join-now">
            <div className="col-md-12">
              <div className="mb-3 border-0 bg-white shadow-sm rounded ">
                <div className="card-body1">
                  <div className="row justify-content-center align-items-center">
                    <div
                      className={`col-md-3 text-center border-box ${
                        formData.paymentMethod === "taps" ? "selected1" : ""
                      }`}
                      onClick={() => handleDivClick("taps")}
                    >
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="taps"
                        checked={formData.paymentMethod === "taps"}
                        onChange={onChange}
                        style={{ display: "none" }}
                      />
                      <div style={{ maxHeight: "100%", overflow: "hidden" }}>
                        <img
                          src="assets/images/Taps_logo.png"
                          alt=" Pay with Taps"
                          style={{
                            maxWidth: "70%",
                            height: "70%",
                            width: "auto",
                          }}
                        />
                      </div>
                      <p className="newp" style={{ marginTop: "8%" }}>
                        {" "}
                        Pay with Taps
                      </p>
                    </div>

                    {/*}
                        <div className={`col-md-3 text-center border-box ${formData.paymentMethod === "paypal" ? 'selected1' : ''}`} onClick={() => handleDivClick("paypal")}>
                          <input type="radio" name="paymentMethod" value="paypal" checked={formData.paymentMethod === "paypal"} onChange={onChange} style={{display: 'none'}}/>
                          <div style={{ maxHeight: '100%', overflow: 'hidden' }}>
                            <img
                              src="assets/images/Paypal_logo.png"
                              alt="Pay with PayPal"
                              style={{ maxWidth: '70%', height: '70%', width: 'auto' }}
                            />
                          </div>
                          <p className="newp" style={{marginTop: '8%'}}>Pay with PayPal</p>
                        </div>{*/}

                    {/* <div className={`col-md-3 text-center border-box ${formData.paymentMethod === "paypal" ? 'selected1' : ''}`} onClick={() => handleDivClick("paypal")} style={formData.paymentMethod !== "paypal" ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                            <input type="radio" name="paymentMethod" value="paypal" checked={formData.paymentMethod === "paypal"} onChange={onChange} style={{ display: 'none' }} />
                            <div style={{ maxHeight: '100%', overflow: 'hidden' }}>
                                <img
                                    src="assets/images/Paypal_logo.png"
                                    alt="Pay with PayPal"
                                    style={{ maxWidth: '70%', height: '70%', width: 'auto' }}
                                />
                            </div>
                            <p className="newp" style={{ marginTop: '8%' }}>Pay with PayPal</p>
                        </div> */}

                      { user.type === "Enterprise Client" ? 
                        <div className={`col-md-3 text-center border-box ${formData.paymentMethod === "manual" ? 'selected1' : ''}`} onClick={() => handleDivClick("manual")}>
                          <input type="radio" name="paymentMethod" value="manual" checked={formData.paymentMethod === "manual"} onChange={onChange} style={{display: 'none'}}/>
                          <div style={{ maxHeight: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SVGWidgetLogo/>
                          </div>
                          <p className="newp" style={{marginTop: '8%'}}>Pay via Bank transfer</p>
                        </div> 
                        : null
                      }

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="centered-button-container">
            <p style={{ marginBottom: "20px" }}>
              Click the button below to be re-directed to the respective website
              to complete your transaction.
            </p>
            <button className="btn btn-primary btn1-block" type="submit">
              Continue
            </button>
            {/** <button id="openPopupButton" onClick={openPopup}>Open Popup</button>*/}
          </div>
        </form>
      </div>

      <div className="row">
        <div className="col-lg-12"></div>
      </div>
      {/** 
      <button
        className="btn btn-default btn-block"
        type="submit"
      >
        Create
      </button>*/}

      {/* <>
      {didCatch ? (
        <div className="bg-danger mx-auto mt-2 px-2 py-1 rounded" style={{width:"max-content"}}>
          <h4 className="text-center pt-3" style={{color: "white"}}>Tap payment is unavailable temporarily</h4>
        </div>
      ) : (
      )}
      </> */}

      <Modal
        show={taps.taps_checkout}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseTaps}
      >
        <Modal.Header>
          <Modal.Title>Taps</Modal.Title>
          <Icon
            className="mx-2 icon-hover"
            icon={remove}
            onClick={handleCloseTaps}
          />
        </Modal.Header>

        <Modal.Body>
          {isLoading && <LoadingOverlay />}
          <GoSellDemo
            walletAmount={formData.wallet_amount}
            email={user.email}
            first_name={user.first_name}
            user_type={user.type}
            last_name={user.last_name}
            handleRedirect={handleRedirect}
            errorhandle={errorhandle}
          />
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>

      {/** <Modal
        show={taps_auth}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Please wait while you are being processed. Please do not click
            refresh or close button.
          </Modal.Title>
          <Icon
            className="mx-2 icon-hover"
            icon={remove}
            onClick={handleCloseTapsAuth}
          />
        </Modal.Header>

        <Modal.Body>
          {/** <iframe
            id="paymentIframe"
            style={{ width: "100%", height: "500px", border: "none" }}
          ></iframe>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>*/}

      {paypal.paypal_checkout && (
        <>
          {show == true ? (
            <Modal
              show={show}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={handleClose}
            >
              <Modal.Header>
                <Modal.Title>PayPal</Modal.Title>
                <Icon
                  className="mx-2 icon-hover"
                  icon={remove}
                  onClick={handleClose}
                />
              </Modal.Header>

              <Modal.Body>
                <PayPalButton
                  walletAmount={formData.wallet_amount}
                  email={user.email}
                  onTransactionComplete={handleTransactionComplete}
                />
              </Modal.Body>

              <Modal.Footer></Modal.Footer>
            </Modal>
          ) : null}
        </>
      )}

      {manual.manual_checkout && (
        <>
          {/*console.log("show" ,  show)*/}
          {show == true ? (
            <Modal
              show={show}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={handleClose}
            >
              <Modal.Header>
                <Modal.Title>Bank Details</Modal.Title>
                <Icon
                  className="mx-2 icon-hover"
                  icon={remove}
                  onClick={handleClose}
                />
              </Modal.Header>

              <Modal.Body>
               <div>
                <div className="mb-3">
                  <h6 className="font-weight-bold mb-1">Account number:</h6>
                  <p className="mb-0">501000010006086167938</p>
                </div>

                <div className="mb-3">
                  <h6 className="font-weight-bold mb-1">IBAN number:</h6>
                  <p className="mb-0">SA6180000501608016167938</p>
                </div>

                <div className="mb-3">
                  <h6 className="font-weight-bold mb-1">SWIFT code:</h6>
                  <p className="mb-0">RJHISARI</p>
                </div>
              </div>

              <br />
              <div className="mb-2">
                <p className="font-weight-bold">Allowed file types: PNG, JPG, JPEG</p>
              </div>
                  <p className="font-weight-bold mb-1">Send contract funds to the above mentioned bank account and upload the reciept for confirmation</p>
                  <input type="file" className="form-control" onChange={handleChange}/>
                </Modal.Body>

              <Modal.Footer>
            <button className="btn btn-primary" onClick={addSlip}>
              Submit
            </button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </>
      )}

      {/*console.log("endpoint" , check_open )*/}
      {/*console.log("show" , taps.taps_checkout )*/}
      {/*console.log("user" , user)*/}
      {/*check_open ?
      <GoSellDemo walletAmount={"0"} email={user.email} first_name={user.first_name} last_name={user.last_name} onTransactionComplete={handleTransactionCompleteTaps}/>:
          null*/}
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  paypalapi,
  getTapsCharge,
  getTapsstatus,
  updateTapsstatus,
  setAlert,
  addToWallet
})(Wallet);
