import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Tab, Tabs, Form } from "react-bootstrap";

import Skeleton from "./../../components/skeleton";

import { getWallet } from "../../actions/wallet";
import { getTransactionHistory } from "../../actions/transaction";
import { getWithdrawalRequests, getWithdrawalSummary } from "../../actions/withdrawal";
import { deleteBank, getBanks } from "../../actions/bank";

import "./style.css";

import TransactionList from "./TransactionList";
import WithdrawalModal from "./WithdrawalModal";
import WithdrawalList from "./WithdrawalList";

const Finance = ({ auth: { isAuthenticated, user, token }, transaction, withdrawal, bank }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const wallet = useSelector((state) => state.wallet);

  const [tab, setTab] = useState("transaction");
  const [bankId, setBankId] = useState("");

  const [loading, setLoading] = useState(false);

  const handleDeleteBank = async () => {
    setLoading(true);
    await dispatch(deleteBank(bankId));
    setBankId("");
    setLoading(false);
  }

  useEffect(() => {
    if (!token) {
      history.push("/login-register?role=pro&type=login");
    }
    dispatch(getWallet());
    dispatch(getBanks());
    dispatch(getTransactionHistory());
    dispatch(getWithdrawalSummary());
    dispatch(getWithdrawalRequests());
  }, []);
  
  const Pending = withdrawal?.withdrawalSummary?.filter(w => w._id === "Pending");
  const Approved = withdrawal?.withdrawalSummary?.filter(w => w._id === "Approved");
  const Fee = withdrawal?.withdrawalSummary?.filter(w => w._id === "Fee");

  return (
    <section className="main-page page-dashboard">
      <Helmet>
        <title>Earnings</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">

          
            <div className="mb-3 border-0 bg-white shadow-sm rounded ">
              <div className="card-body">
                <div
                  className="d-flex flex-wrap justify-content-between align-items-center mb-3"
                >
                  <div className="d-flex align-items-center">
                    <h2 className="heading mb-0">Revenue Earned</h2>
                  </div>

                  {user?.type === 'Freelancer' && (
                    <>
                      <div className="d-flex flex-column align-items-end">
                        <div className="d-flex flex-wrap justify-md-content-end" style={{ gap: '8px' }}>
                          <Link to="/bank-details" className="d-inline-flex">
                            <Button
                              title="Add bank details"
                              disabled={loading}
                            >
                              <i className="fa fa-plus"></i>
                            </Button>
                          </Link>
                          <Link
                            to={{
                              pathname: "/bank-details",
                              state: { bankId }
                            }}
                            className="d-inline-flex"
                          >
                            <Button
                              title="Edit selected bank"
                              variant="primary"
                              disabled={!bankId || loading}
                            >
                              <i className="fa fa-pen-square"></i>
                            </Button>
                          </Link>
                          <Button
                            title="Delete selected bank"
                            variant="secondary"
                            disabled={!bankId || loading}
                            onClick={handleDeleteBank}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                    
                          <Form.Control
                            as="select"
                            className="destination-select"
                            value={bankId}
                            onChange={(e) => setBankId(e.target.value)}
                          >
                            <option value="">Destination Bank</option>
                            {bank.banks?.map((bank) => (
                              <option
                                key={bank._id}
                                value={bank._id}
                              >
                                {/*bank.bank_name} - {bank.iban} - {bank.beneficiary_name*/}
                                {bank.beneficiary_name}
                              </option>
                            ))}
                          </Form.Control>

                         
                          <WithdrawalModal
                            walletAmount={wallet.walletAmount}
                            selectedBankId={bankId}
                          />
                        </div>
                        <p className="text-muted mt-2">Use these buttons to add, edit, or delete your bank details and request a withdrawal.</p>
                      
                        <p className="text-muted mt-2">All payments displayed on the platform are in USD</p>
                      </div>
                    </>
                  )}

                </div>


                <div
                  className="d-flex flex-wrap py-2"
                  style={{
                    gap: 30,
                  }}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    <p>
                      {user?.type === "Admin" ? "Escrow Amount" : "Available Income"}
                    </p>
                    {wallet.walletAmount === null ? (
                      <div className="d-flex w-100">
                        <Skeleton width={150} height={50} />
                      </div>
                    ) : (
                      <h1 className="font-weight-bold m-0 text-left">
                        ${wallet.walletAmount || 0}
                      </h1>
                    )}
                  </div>

                  <div className="d-flex flex-column justify-content-start align-items-center">
                    <p>
                      {user?.type === "Admin" ? "Earnings" : "Pending Clearance"}
                    </p>
                    {withdrawal.withdrawalSummary === null ? (
                      <div className="d-flex w-100">
                        <Skeleton width={150} height={50} />
                      </div>
                    ) : (
                      <h1 className="font-weight-bold m-0 text-left">
                        ${user?.type === "Admin" ? (
                          Fee?.[0]?.totalAmount || 0
                        ) : (
                          Pending?.[0]?.totalAmount || 0
                        )}
                      </h1>
                    )}
                  </div>

                  {user?.type === 'Freelancer' && (
                    <div className="d-flex flex-column justify-content-start align-items-center">
                      <p>Withdrawals</p>
                      {withdrawal.withdrawalSummary === null ? (
                        <div className="d-flex w-100">
                          <Skeleton width={150} height={50} />
                        </div>
                      ) : (
                        <h1 className="font-weight-bold m-0 text-left">
                          ${Approved?.[0]?.totalAmount || 0}
                        </h1>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Tabs
              activeKey={tab}
              onSelect={(t) => setTab(t)}
              id="earnings-tab"
            >
              <Tab
                eventKey="transaction"
                title="Transactions"
              >
                <TransactionList
                  transactionHistory={transaction.transactionHistory}
                  user={user}
                />
              </Tab>
              <Tab
                eventKey="withdrawal"
                title="Withdrawal"
              >
                <WithdrawalList
                  withdrawals={withdrawal.withdrawals}
                  user={user}
                />
              </Tab>
            </Tabs>
          </div>
        </div>

      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  transaction: state.transaction,
  withdrawal: state.withdrawal,
  bank: state.bank,
});

export default connect(mapStateToProps)(Finance);
