import axios from "axios";
import { AI_EXPERT_RECOMENDATIONS , GET_INDIVIDUAL , GET_COMPANY, CLEAR_RECOMMENDATIONS , GET_CHAT } from "./types";
import { setAlert } from "./alert";

import setAuthToken from "../utils/setAuthToken";



export const Hire = (email , description , headline ,level ,duration ,skills , amount , freelancer_id) => async dispatch => {
  const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

if(!amount){
  return dispatch(setAlert("Invalid Amount", 'danger'));
 }

const data = JSON.stringify({
  email : email,
  description : description,
  headline : headline,
  level : level,
  duration : duration,
  skills : skills,
  amount : amount,
  freelancer_id : freelancer_id
  });


  try {
    const response = await axios.post('/api/enterprise/hire', data, config);

    dispatch(setAlert(response.data.message || 'Success!', 'success'));
    return response.data
  }
  catch (error) {
    const errorMessage = error.response?.data?.message || 'Danger!';
    dispatch(setAlert(errorMessage, 'danger'));
  }
}




export const MHregistration = (formdata, formType , email) => async dispatch => {
   
  const config = {
      headers: {
          'Content-Type': 'application/json'
      }
  };

  /*if (localStorage.token) {
		setAuthToken(localStorage.token);
	}*/

  const data = JSON.stringify({
		form : formdata,
		type: formType,
    email: email
	  });

  
  try {
      const response = await axios.post('/api/enterprise/registration', data, config);
        
      if(response.status === 200){
        localStorage.removeItem("answers1-"+formType)
        localStorage.removeItem("answers2-"+formType)
        localStorage.removeItem("answers3-"+formType)
        localStorage.removeItem("answers4-"+formType)
        localStorage.removeItem("answers5-"+formType)
        localStorage.removeItem("answers6-"+formType)
        localStorage.removeItem("answers7-"+formType)
        localStorage.removeItem("answers8-"+formType)
        localStorage.removeItem("answers9-"+formType)
        localStorage.removeItem("answers10-"+formType)
        dispatch(setAlert(response.data.message, 'success'));
      }
      return response.data

      // Handle response data here
  } catch (err) {
      console.log(err);
    if (err.response.status === 400) {
      dispatch(setAlert(err.response.data.message, 'danger'));
    }
  }
}



export const Recomendations = (formdata, formType) => async dispatch => {
   
  const config = {
      headers: {
          'Content-Type': 'application/json'
      }
  };

  /*if (localStorage.token) {
		setAuthToken(localStorage.token);
	}*/

  const data = JSON.stringify({
		form : formdata,
		type: formType,
	  });

  try {
    const response = await axios.post('/api/enterprise/recommend', data, config);

    dispatch({
      type: AI_EXPERT_RECOMENDATIONS,
      payload: response.data,
    });

    return response.data; // Return the data for further use if needed
  } catch (error) {
    console.error("Error fetching recommendations:", error);
    throw error;
  }
}


export const getAllCompany = () => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
    const res = await axios.get('/api/enterprise/get_company');

		dispatch({
			type: GET_COMPANY,
			payload: res.data
		});

	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(err.response.data.message, 'danger')));
		}
	}
}


export const getChat = (email) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

  const config = {
    headers: {
        'Content-Type': 'application/json'
    }
  };

	try {
    const res = await axios.get(`/api/enterprise/get_chat?email=${encodeURIComponent(email)}`, config);

    dispatch({
			type: GET_CHAT,
			payload: res.data
		});

	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(err.response.data.message, 'danger')));
		}
	}
}



export const get_user_check = (email) => async dispatch => {
  const config = {
    headers: {
        'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.get(`/api/enterprise/userCheck?email=${encodeURIComponent(email)}`, config);
    return res.data.message;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.errors) {
      const errors = err.response.data.errors;
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
  }
}


export const budget_status = (email) => async dispatch => {
  const config = {
    headers: {
        'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.get(`/api/enterprise/budget_check?email=${encodeURIComponent(email)}`, config);
    return res.data.message;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.errors) {
      const errors = err.response.data.errors;
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
  }
}


export const getAllIndividual = (setLoading) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/enterprise/get_individual');

		dispatch({
			type: GET_INDIVIDUAL,
			payload: res.data
		});
    setLoading(false)

	} catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
		}
    setLoading(false)

	}
}


export const AddSlip = (photolink , email) => async dispatch => {
   
  const config = {
      headers: {
          'Content-Type': 'application/json'
      }
  };



  const data = JSON.stringify({
		photo: photolink,
    email: email
	  });

  try {
    const response = await axios.post('/api/enterprise/add_slip', data, config); 

    dispatch(setAlert(response.data.message, 'success'));

    return response.data; 


  } catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.message, 'danger')));
		}
  }
}

export const AddtoAdminWallet = (amount , email) => async dispatch => {
   
  const config = {
      headers: {
          'Content-Type': 'application/json'
      }
  };

  if(!amount){
    return dispatch(setAlert("Invalid Amount", 'danger'));
   }

   const data = JSON.stringify({
		amount: amount,
    email: email
	  });

  try {
    const response = await axios.post('/api/enterprise/add_to_wallet', data, config); 

    dispatch(setAlert(response.data.message, 'success'));

    return response.data; 


  } catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.message, 'danger')));
		}
  }
}



export const PaymentRequest = (id , email) => async dispatch => {
   
  const config = {
      headers: {
          'Content-Type': 'application/json'
      }
  };

   const data = JSON.stringify({
		id: id,
    email: email
	  });

  try {
    const response = await axios.post('/api/enterprise/payment_request', data, config); 

    dispatch(setAlert(response.data.message, 'success'));

    return response.data; 


  } catch (err) {
		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.message, 'danger')));
		}
  }
}

export const clearRecommendations = () => ({
  type: CLEAR_RECOMMENDATIONS
});

export const PaymentRelease = (id) => async dispatch => {
   
  const config = {
      headers: {
          'Content-Type': 'application/json'
      }
  };

   const data = JSON.stringify({
		id: id
	  });

  try {
    const response = await axios.post('/api/enterprise/payment_release', data, config); 

    dispatch(setAlert(response.data.message, 'success'));

    return response.data; 


  } catch (err) {
		//console.log(err.response)
    const errors = err.response.data.message;
		if (errors) {
			dispatch(setAlert(errors, 'danger'));
		}
  }
}



