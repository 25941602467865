import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from "react-redux";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useHistory, Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Skeleton from 'react-loading-skeleton';
import Icon from 'react-icons-kit';
import {close} from 'react-icons-kit/ikons/close';
import Modal from 'react-bootstrap/esm/Modal';
import Input from '../../components/input/input';
import { adminSendEmail, adminSendMessage, banTheUser, unbanTheUser } from '../../actions/manageUsers';



function UserViewOffcanvas({show, handleClose, user}) {

  var educationIsArray = Array.isArray(user?.details?.education);
  var employmentIsArray = Array.isArray(user?.details?.employment);
  var certificateIsArray = Array.isArray(user?.details?.certification);
  const dispatch = useDispatch();

  const [sendingMessage, setSendingMessage] = useState(false);
  const [sendingTextMessage, setSendingTextMessage] = useState("");

  const [sendingEmail, setSendingEmail] = useState(false);
  const [sendingTextEmail, setSendingTextEmail] = useState("");

  const [banningUser, setBanningUser] = useState(false);
  const [unbanningUser, setUnbanningUser] = useState(false);


  const onMessageToUserSubmit = async(e)=>{
    // setSendingTextMessage(e.target.value);
    e.preventDefault();
    await dispatch(adminSendMessage({message_text: e.target.message.value, user_id: user._id}));
    onCloseSendingMessage();
    setSendingTextMessage("");
    handleClose();
  }

  function onCloseSendingMessage(){
    setSendingMessage(false);
  }
  
  
  const SendMessageModal = ()=>{
    return(
      <Modal show={sendingMessage} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header className='mt-1'>
                <Modal.Title>Send Message</Modal.Title>
            </Modal.Header>
            <form onSubmit={onMessageToUserSubmit}>
              <Modal.Body className='mt-0'>
                    <Input name={"message"} type={"textarea"} placeholder={"Write your message..."} />
              </Modal.Body>
              <Modal.Footer>
                  <Button type='submit' variant="secondary">
                      Send
                  </Button>
                  <Button variant="secondary" onClick={onCloseSendingMessage} >
                      Close
                  </Button>
              </Modal.Footer>
            </form>
      </Modal>
    )
  }

  function onCloseSendingEmail(){
    setSendingEmail(false);
  }

  const onEmailToUserSubmit = async(e)=>{
    // setSendingTextMessage(e.target.value);
    e.preventDefault();
    await dispatch(adminSendEmail({email_text: e.target.email.value, user_email: user.email}));
    onCloseSendingEmail();
    setSendingTextEmail("");
    handleClose();
  }


  const SendEmailModal = ()=>{
    return(
      <Modal show={sendingEmail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header className='mt-1'>
                <Modal.Title>Send Email</Modal.Title>
            </Modal.Header>
            <form onSubmit={onEmailToUserSubmit}>
              <Modal.Body className='mt-0'>
                    <Input name={"email"} type={"textarea"} placeholder={"Write your email..."} />
              </Modal.Body>
              <Modal.Footer>
                  <Button type='submit' variant="secondary">
                      Send
                  </Button>
                  <Button variant="secondary" onClick={onCloseSendingEmail} >
                      Close
                  </Button>
              </Modal.Footer>
            </form>
      </Modal>
    )
  }




  function onCloseBanningUser(){
    setBanningUser(false);
  }

  function onCloseUnBanningUser(){
    setUnbanningUser(false);
  }

  const onSubmitBanUser = async(e)=>{
    e.preventDefault();
    await dispatch(banTheUser({ban_user_id: user._id}));
    onCloseBanningUser();
    handleClose();
  }
  

  const onSubmitUnBanUser = async(e)=>{
    e.preventDefault();
    await dispatch(unbanTheUser({unban_user_id: user?._id}));
    onCloseUnBanningUser();
    handleClose();
  }

  const BanUserConfirmation = ()=>{
    return(
      <Modal show={banningUser} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header className='mt-1'>
                <Modal.Title>Ban the user</Modal.Title>
            </Modal.Header>
            <form onSubmit={onSubmitBanUser}>
              <Modal.Body className='mt-0 ml-2'>
                    <p><strong>Are you sure you want to banned this user?</strong></p>
                    <ul className='mt-2'>
                       <li>After ban user can't able to login the account</li>
                       <li>Every user details will be privated</li>
                    </ul>
              </Modal.Body>
              <Modal.Footer>
                  <Button type='submit' className='ml-2' variant="danger">
                      Ban
                  </Button>
                  <Button variant="secondary" className='ml-auto' onClick={onCloseUnBanningUser} >
                      Close
                  </Button>
              </Modal.Footer>
            </form>
      </Modal>
    )
  }

  const UnBanUserConfirmation = ()=>{
    return(
      <Modal show={unbanningUser} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header className='mt-1'>
                <Modal.Title>Unban the user</Modal.Title>
            </Modal.Header>
            <form onSubmit={onSubmitUnBanUser}>
              <Modal.Body className='mt-0 ml-2'>
                    <p><strong>Are you sure you want to unban this user?</strong></p>
                    <ul className='mt-2'>
                       <li>After unbanning user can able to login the account and access his details</li>
                    </ul>
              </Modal.Body>
              <Modal.Footer>
                  <Button type='submit' className='ml-2' variant="danger">
                      Unban
                  </Button>
                  <Button variant="secondary" className='ml-auto' onClick={onCloseBanningUser} >
                      Close
                  </Button>
              </Modal.Footer>
            </form>
      </Modal>
    )
  }

  const FreelancerDetailsView = ()=>{

    return(
      <div className="col-lg-12 right">
          <div className="seller-profile">
              <div className="description">
                  <h3>Description</h3>
                  <ul className="mt-2">
                      <li className="interactive-bullet">{user?.details?.titleAndOverview?.professional_overview ?? 'No Record Found'}</li>
                  </ul>
              </div>
              {/* <div className="languages">
                  <h3 className="mt-3">Languages</h3>
                  <ul className="mt-2">
                  {languageVal.length > 0 ? languageVal.map((itemoflanguage,index) => (
                      <li className="interactive-bullet" key={index}>
                          
                          <p> {itemoflanguage.language}&nbsp;&nbsp;- <span>{itemoflanguage.proficiency}</span></p>
                      </li>
                      )) : 
                      <li className="interactive-bullet">
                          No Record Found
                      </li>
                  }
                  </ul>
              </div> */}
              <div className="skills">
                  <h3>Main Service User Offer</h3>
                  <ul className="mt-2">
                  <li className="interactive-bullet">
                  <p>{user?.details?.expertise?.category ? user?.details?.expertise?.category : "No Record Found"}</p>
                  </li>
                  </ul>
              </div>
              <div className="skills">
                  <h3>Skills</h3>
                  <ul className="mt-2">
                      <li className="interactive-bullet">{user?.details?.expertise?.skills ? user?.details?.expertise?.skills :"No Record Found"}</li>
                  </ul>
              </div>
              <div className="education-list list">
                  <h3>Education</h3>
                  <ul className="mt-2">
                    {
                      educationIsArray?
                        user?.details?.education.length > 0 ? user?.details?.education.map((itemOfEducation, index) => (
                            <li key={index} className="interactive-bullet">
                                <p>{itemOfEducation?.degree}</p>
                                <p>{itemOfEducation?.provider}, Graduated {itemOfEducation?.to ? itemOfEducation?.to?.split('-')[0] : ''}</p>
                            </li>
                        )) :
                        <li className="interactive-bullet">
                            No Record Found
                        </li>
                      :
                        user?.details?.education ?
                        <li className="interactive-bullet">
                            <p>{user?.details?.education?.degree}</p>
                            <p>{user?.details?.education?.provider}, Graduated {user?.details?.education?.to ? user?.details?.education?.to?.split('-')[0] : ''}</p>
                        </li>
                        :
                        <li className="interactive-bullet">
                            No Record Found
                        </li>
                    }
                  </ul>
              </div>

              <div className="education-list list">
                  <h3>Certificate</h3>
                  <ul className="mt-2">
                    {
                      certificateIsArray?
                        user?.details?.certification?.length > 0 ? user?.details?.certification?.map((itemofcertification,index) => (
                          <li className="interactive-bullet" key={index}> 
                              <p>{itemofcertification.certification_name}</p>
                              <p>{itemofcertification.provider}</p>
                          </li>
                        )):
                        <li className="interactive-bullet">
                          No Record Found
                        </li>
                      :
                      user?.details?.certification?
                        <li className="interactive-bullet" > 
                            <p>{user?.details?.certification?.certification_name}</p>
                            <p>{user?.details?.certification?.provider}</p>
                        </li>
                      :
                      <li className="interactive-bullet">
                          No Record Found
                      </li>
                    }
                  </ul>
              </div>
              <div className="education-list list">
                  <h3>Employment</h3>
                  <ul className="mt-2">
                      {
                        employmentIsArray?
                          user?.details?.employment.length > 0 ? user?.details?.employment.map((itemofemployment,index) => (
                            <li className="interactive-bullet" key={index}>
                                <p>{itemofemployment?.company_name} ( <strong>{itemofemployment?.from?.split('T')[0]} - {itemofemployment?.to?.split('T')[0]}</strong> )</p>
                                <p>{itemofemployment?.city}, {itemofemployment?.country}</p>
                                <p>{itemofemployment?.title}</p>
                            </li>
                          ))
                        :
                        <li className="interactive-bullet">
                            No Record Found
                        </li>
                      :
                        user?.details?.employment ?
                        <li className="interactive-bullet" >
                            <p>{user?.details?.employment?.company_name} ( <strong>{user?.details?.employment?.from?.split('T')[0]} - {user?.details?.employment?.to?.split('T')[0]}</strong> )</p>
                            <p>{user?.details?.employment?.city}, {user?.details?.employment?.country}</p>
                            <p>{user?.details?.employment?.title}</p>
                        </li>
                        :
                        <li className="interactive-bullet">
                            No Record Found
                        </li>
                      }
                  </ul>
              </div>
              <div>
                <h3>location</h3>
                <ul className="mt-2">
                    <li className="interactive-bullet">
                      <p>{
                        user?.details?.location ? 
                        `${user?.details?.location?.country && user?.details?.location?.country}` 
                        : "No Record Found"}
                      </p>
                    </li>
                </ul>
              </div>
              <div>
                <h3>Verfication</h3>
                <ul className="mt-2">
                    <li className="interactive-bullet">
                      <p>
                        {!(user?.details?.verification === false && user?.details?.isCompleted === false)?
                         user?.details?.verification?
                         "Verified"
                         :
                         "Waiting for Verification"
                        :
                          "Profile not completed yet"
                        }
                      </p>
                    </li>
                </ul>
              </div>
              <div>
                <h3>Verification Date</h3>
                <ul className="mt-2">
                    <li className="interactive-bullet">
                      <p>
                        {user?.details?.verification_date ?
                          `${new Date(user?.details?.verification_date)?.getDate()} - 
                          ${new Date(user?.details?.verification_date)?.getMonth()+1} - 
                          ${new Date(user?.details?.verification_date)?.getFullYear()}`
                        :
                          "Not available"
                        }
                      </p>
                    </li>
                </ul>
              </div>
          </div>
      </div>
    )
  }

  const ClientDetailsView = ()=>{
    return(
      <div className="col-lg-12 right">
        <div className='seller-profile'>
          <h3>location</h3>
          <ul className="mt-2">
              <li className="interactive-bullet">
              <p>{user?.details?.location ? user?.details?.location : "No Record Found"}</p>
              </li>
          </ul>
        </div>
      </div>
    )
  }

  //console.log(user);


  const handleOnSendingMessage =()=>{
    setSendingMessage(true);
  }

  const handleOnSendingEmail =()=>{
    setSendingEmail(true);
  }

  return (
    <>
      <SendEmailModal />
      <SendMessageModal />
      <BanUserConfirmation />
      <UnBanUserConfirmation />
      <Offcanvas show={show} backdropClassName='offcanvas-drop' placement='end' onHide={handleClose}>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <div className='d-flex justify-content-center'>
                <div className='w-100'>
                  <h3 className="text-center">User Details</h3>
                </div>
                <button className='btn btn-sm' style={{backgroundColor: 'transaprent', boxShadow: 'none'}}  onClick={handleClose}><Icon size={30} icon={close} /></button>
              </div>
              <hr/>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='manage-user-view-outer'>
              <div className='d-flex justify-content-end'>
                <button className='btn btn-default' onClick={()=>handleOnSendingMessage()}>Send Message</button>
                <button className='btn btn-primary ml-4' onClick={()=>handleOnSendingEmail()}>Send Email</button>
              </div>
              <div className='row'>
                <div className="col-lg-4 left manage-user-view">
                    <div className="user-profile-info">
                      <div className="clearfix">
                      {user.type === "Freelancer" ?
                          <div className={`user-profileCompleted-indicator ${user?.details?.isCompleted ? "is-completed" : "not-completed"}`}
                              style={{ color: user?.details?.isCompleted ? "green" : "#e31818" }}>
                              {user?.details?.isCompleted ? "completed" : "in complete"}
                          </div>
                          :
                          <div className={`user-profileCompleted-indicator ${user?.email? "is-completed" : "not-completed"}`}
                              style={{ color: user?.email ? "green" : "#e31818" }}>
                              {user?.email ? "completed" : "in complete"}
                          </div>
                          }
                          <div className="ambassadors-badge"
                              style={{backgroundColor: !user?.type
                                ? "transparent"
                                : user.type === "Freelancer" ? "#40ADDB" : "#38c244", }}>
                                {!user?.type ? (
                                  <Skeleton width={100} height={25} />
                              ) : user?.type === "Freelancer" ? (
                                  "Expert"
                              ) : (
                                  user?.type
                              )}
                          </div>
                      </div>
                      <div className="user-profile-info">
                          <div>
                              <div className="user-profile-image">
                                  <label className="user-pict rounded-circle">
                                      {/* <img
                                          src="assets/images/BilalAhmedProfile.jpg"
                                          className="img-fluid user-pict-img" alt="Cyber2Cyber" /> */}
                                      <div className="user-badge user-badge-round user-badge-round-med locale-en-us top-rated-seller">
                                          {
                                              user?.details?.profilePhoto?.photo_link ?
                                              <img src={user?.details?.profilePhoto?.photo_link} alt="Profile picture" />
                                              :
                                              <h3 className="text-uppercase profile-avatar">{user?.first_name?.slice(0,1)}{user?.last_name?.slice(0,1)}</h3>
                                          }  
                                      </div>
                              </label>
                              </div>
                          </div>
                          <div className="user-profile-label">
                              <div className="username-line">
                                  <div className="seller-link">{user?.first_name} {user?.last_name}</div>
                                  {user?.verification == true ?  <img src="assets\images\checkmark.png" className="img-fluid user-pict-img" alt="Cyber2Cyber" title="Cyber to Cyber Verified Expert" style={{width: "5%", height: "4%", position: "relative", top: "-3px", left: "3px"}} /> : <></>  }
                              </div>
                              <div className="oneliner-wrapper">
                                  {/* <small className="oneliner">{user?.details?.titleAndOverview?.title}</small> */}
                                  {user?.type === "Freelancer" &&<div className="ratings-wrapper">
                                      <p className="rating-text"><strong>${user?.details?.hourlyRate?.hourly_rate}</strong>/hr</p>
                                  </div>}
                              </div>
                          </div>
                          <section className="p-4">
                              <div className="border-bottom pb-4">
                              </div>
                              <div className="pt-4">
                              </div>
                          </section>
                      </div>
                    </div> 
                </div>
                <div className='col-lg-8'>
                  <div className='col-lg-12 right'>
                    <div className='seller-profile'>
                      <h3>Username</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                          <p>{user?.user_name}</p>
                          </li>
                      </ul>
                      <h3>Email</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                          <p>{user?.email}</p>
                          </li>
                      </ul>
                      <h3>Approval Status</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                          <p>{user?.approval}</p>
                          </li>
                      </ul>
                      <h3>Is Banned</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                            <p>{user?.banned ? "true" : "false"}</p>
                          </li>
                      </ul>
                      <h3>Is Active</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                            <p>{user?.active? "true" : "false"}</p>
                          </li>
                      </ul>
                      <h3>Is visible</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                            <p>{user?.visibility? "true" : "false"}</p>
                          </li>
                      </ul>
                      <h3>Is anonymous</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                            <p>{user?.anonymousMode? "true" : "false"}</p>
                          </li>
                      </ul>
                      <h3>Join date</h3>
                      <ul className="mt-2">
                          <li className="interactive-bullet">
                            <p>{
                                `${new Date(user?.date).getDate()} - ${new Date(user?.date).getMonth()+1} - ${new Date(user?.date).getFullYear()}`
                                }</p>
                          </li>
                      </ul>
                    </div>
                  </div>
                  {user?.type === "Freelancer"?
                  <FreelancerDetailsView />
                  :
                  <ClientDetailsView />
                  }
                </div>
                
              </div>
              {!user.banned ?
                <div className='mt-auto flex-1'>
                  <button onClick={()=>setBanningUser(true)} className='btn btn-danger'>Ban User</button>
                </div>
                :
                <div className='mt-auto flex-1'>
                  <button onClick={()=>setUnbanningUser(true)} className='btn btn-danger'>Unban User</button>
                </div>
              }
            </div>
          </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default UserViewOffcanvas;