import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import UserProfileNavLink from '../../components/userProfileNavLink/userprofilenavlink';
import Alert from '../../components/alert/Alert';
import { addHourlyRate } from "../../actions/freelancerProfile";
import InputRange from 'react-input-range';
import { KeyboardBackspaceOutlined } from "@material-ui/icons";

const HourlyRate = ({ freelancerProfile: { hourlyRate, freelancerprofile }, setAlert, addHourlyRate, isAuthenticated }) => {
  // Save hourly rate to localStorage
  useEffect(() => {
    if (hourlyRate?.hourly_rate) {
      localStorage.setItem('hourlyRate', hourlyRate.hourly_rate);
    }
  }, [hourlyRate]);

  // Calculate initial value for youReceiveVal
  const calculateInitialYouReceive = (rate) => rate - (rate * 0.2);

  const initialRate = hourlyRate?.hourly_rate || 50;
  const initialYouReceive = calculateInitialYouReceive(initialRate);

  // State initialization
  const [state, setState] = useState({
    value: initialRate,
  });

  const [formData, setFormData] = useState({
    hourly_rate: initialRate.toString(),
  });

  const [youReceiveVal, setYouReceiveVal] = useState(initialYouReceive);

  // Handle input change
  const handleInputChange = (value) => {
    setState({ value });
    setFormData({ ...formData, hourly_rate: String(value) });
    const calculatedYouReceive = value * 0.2;
    setYouReceiveVal(value - calculatedYouReceive);
  };

  // Handle change in input field
  const onChange = (e) => {
    const inputValue = e.target.value;
    const extractedValue = parseFloat(inputValue.split(" ")[0]);
    if (!isNaN(extractedValue)) {
      handleInputChange(extractedValue);
    } else {
      handleInputChange(50);
      setFormData({ ...formData, [e.target.name]: "" });
      setYouReceiveVal(0);
    }
  };

  // Set document title
  useEffect(() => {
    document.title = 'Hourly Rate';
  }, []);

  // Get hourly rate from localStorage on component mount
  useEffect(() => {
    const storedRate = localStorage.getItem('hourlyRate');
    if (storedRate) {
      const rate = parseFloat(storedRate);
      handleInputChange(rate);
    }
  }, []);

  let history = useHistory();
  const regex = /^[0-9]+$/;

  const { hourly_rate } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();

    const effectiveHourlyRate = hourly_rate || '50';

    if (isNaN(youReceiveVal)) {
      return;
    }

    if (!String(effectiveHourlyRate).match(regex)) {
      setAlert("Hourly rate must be a valid number", "danger");
      return;
    }

    addHourlyRate({
      hourly_rate: effectiveHourlyRate
    });

    history.push("/titleandoverview");
  };

  if (!isAuthenticated) {
    return <Redirect to="/login-register?role=pro&type=login" />;
  }

  if (typeof freelancerprofile === "string") {
    return <Redirect to="/expert-email-verify" />;
  }
  return (
    <section className="main-page page-dashboard">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <UserProfileNavLink />
          </div>
          <div className="col-lg-9">
            <div className="bg-white rounded shadow-sm sidebar-page-right">

              <div className="p-5 border-bottom">
                <Link to={`/languages`} className=" text-dark d-flex mb-2"
                  style={{ alignItems: "center", gap: "3px" }}
                >
                  <KeyboardBackspaceOutlined />  Back

                </Link>
                <h3 className="text-primary">Hourly Rate</h3>
                <p className="font-weight-bold">05 of 10</p>
                <Alert />
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="scrollable">

                    <p className="font-weight-bold">Our customers will see these rates when your profile matches their requirements. Make sure you create an impression.</p>
                    <div className="row d-flex  form-group" style={{ minHeight: "55vh" }}>
                      <div className="col-md-12 col-12">
                        <p className="text-dark font-weight-bold mb-2">Hourly Rate</p>
                        <p className="text-muted mb-2">Total amount the client will see</p>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="form-group cyber-form-control">
                          <div className="mt-2">
                            <InputRange
                              maxValue={500}
                              minValue={10}
                              value={`${isNaN(state.value) ? 0 : state.value}`}
                              onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="50"
                            name="hourly_rate"
                            value={`${isNaN(hourly_rate) ? 0 : hourly_rate} USD`}
                            onChange={(e) => onChange(e)}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">USD</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 mt-3">
                        <p className="text-dark font-weight-bold mb-1">CybertoCyber Service Fee</p>
                        <p className="text-muted mb-2">The CybertoCyber Service Fee is 20% when you begin a contract with a new client.</p>
                      </div>
                      <div className="col-md-12 col-12">
                        <input type="text" className="form-control" placeholder="20%" readOnly />
                      </div>
                      <div className="col-md-12 col-12 mt-3">
                        <p className="text-dark font-weight-bold mb-1">You'll receive</p>
                        <p className="text-muted mb-2">The estimated amount you'll receive after service fees</p>
                      </div>
                      <div className="col-md-12 col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="$ 0.00 / hr"
                          readOnly
                          name="youReceiveVal"
                          value={`${isNaN(youReceiveVal) ? 0 : youReceiveVal} USD`}
                        />
                      </div>
                      {/*<div className="col-md-8 col-12 pt-4">
                              <p className="font-weight-bold">What skills do you offer clients?</p>
                              <input type="text" className="form-control" placeholder="Enter at least 1 skill e.g Cyber Security, Mobile Cyber Security" />
                            </div>*/}
                      <div className="col-md-12 col-12 mt-4">

                      </div>
                    </div>

                  </div>

                  <div className="row justify-content-center p-2">
                    <div className="col-md">
                      {/*<Link to="/certificate" className="btn btn-normal">Skip this step</Link>*/}
                    </div>
                    <div className="text-right wm-100">
                      <button type="submit" className="btn btn-primary wm-100" >Save & Next</button>
                    </div>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HourlyRate.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  freelancerProfile: state.freelancerProfile
});


export default connect(mapStateToProp, { setAlert, addHourlyRate })(HourlyRate, Alert);