import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect, useDispatch } from 'react-redux';
import { postJobGet } from '../../actions/postJob';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import { proposalAdd, proposalCheckbyId } from '../../actions/proposalAndContract';
import { SETCONTRACTINFORMATION } from "../../actions/types";
import { toast } from "react-toastify";
import { Link} from "react-router-dom";
import { remove } from 'react-icons-kit/fa/remove'
import { Icon } from 'react-icons-kit';
import { contractAdd } from "../../actions/proposalAndContract";

const InvitationModal = ({ show, Hirecheck, freelancer, handleClose, proposalAndContract: { ProposalCheckItemData }, postJob: { postJobData }, auth: { isAuthenticated, user } }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedJob, setSelectedJob] = useState(null)
    const [propCheck, setPropCheck] = useState(false)

    const fixed_hour_schema = {
        fixed_price: 0,
        deposit_type: "Complete",
        milestone: [],
      };
      

    const [fixed_hour_formData, set_fixed_hour_formData] = useState({
        ...fixed_hour_schema,
      });


    const [formData, setFormData] = useState({
        client_comment: null
    });

    //console.log(freelancer._id);

    const { client_comment } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        dispatch(postJobGet())
    }, [])

    useEffect(() => {
        //debugger;
        //console.log(selectedJob)
        if (selectedJob) {
            //debugger;
            if(Hirecheck==true)
            {
                //debugger;
                //console.log(freelancer._id);
                //console.log(selectedJob.hire_manager_id);

                if (selectedJob.payment_type_id) {
                    set_fixed_hour_formData({ ...fixed_hour_schema })
                }
        
                if(!freelancer._id || !selectedJob.hire_manager_id || !selectedJob._id){
                    toast.error("Something went wrong, please try again later");
                }
                let data = {
                    //proposal_id: activeProposal.proposal_id,
                    freelancer_id: freelancer._id,
                    hire_manager_id: selectedJob.hire_manager_id,
                    job_id: selectedJob._id,
                };
                /*dispatch({
                    type: SETCONTRACTINFORMATION,
                    payload: data,
                })*/

                var job_id = selectedJob._id;
                var freelancer_id = freelancer._id;
                var hire_manager_id = selectedJob.hire_manager_id;
                var payment_type_id = selectedJob.payment_type_id;
                var payment_amount = selectedJob.payment_amount;
                var current_proposal_status = "6173d8be9b109e2dd8457268";
                var client_grade = user.type;
                var freelancer_comment = `Hello, I hope this message finds you well. I would like to discuss a potential job opportunity with you. Are you interested?`;
                var freelancer_grade = "";
                var user_name = user.user_name;
                var read = false;
                var sender_type = user.type;
                dispatch(proposalAdd({
                    job_id,
                    freelancer_id,
                    hire_manager_id,
                    payment_type_id,
                    payment_amount,
                    current_proposal_status,
                    client_grade,
                    freelancer_comment,
                    user_name,
                    freelancer_grade,
                    client_comment,
                    read,
                    sender_type
                }, () => history.push('/messages')))

                var types = 'Fixed';
                
                if (selectedJob.payment_type_id === '659ea2b0361d1c67ed1c1763'){
                    types = 'Hourly'
                }

                
                let Fixeddata = {
                    ...data,
                    payment_type_id: selectedJob.payment_type_id,
                    fixed_contract: fixed_hour_formData,
                    contract_type: types,
                    contract_status: "Pending"
                  };
                 
                  //console.log("fixed object", Fixeddata);
              
                dispatch(contractAdd(Fixeddata, () => history.push("/dashboard")));
                //contractAdd(Hourlydata, () => history.push("/dashboard"));

                
            }
            else
            {
                //console.log(propCheck)
                dispatch(proposalCheckbyId(selectedJob._id, { freelancer_id: freelancer._id, hire_manager_id: selectedJob.hire_manager_id })).then((response) => {
                    //console.log("Proposal check response:", response.proposalSubmitted );
                    if(response.proposalSubmitted){
                        //console.log("condition set");
                        setPropCheck(true);
                    }
                  })
            }
           
        }
        
    }, [selectedJob])

    const onSubmit = async (e) => {
        e.preventDefault();
        var job_id = selectedJob._id;
        var freelancer_id = freelancer._id;
        var hire_manager_id = selectedJob.hire_manager_id;
        var payment_type_id = selectedJob.payment_type_id;
        var payment_amount = selectedJob.payment_amount;
        var current_proposal_status = "6173d8be9b109e2dd8457268";
        var client_grade = user.type;
        var freelancer_comment = "";
        var freelancer_grade = "";
        var user_name = user.user_name;
        var read = false;
        var sender_type = user.type;
        dispatch(proposalAdd({
            job_id,
            freelancer_id,
            hire_manager_id,
            payment_type_id,
            payment_amount,
            current_proposal_status,
            client_grade,
            freelancer_comment,
            user_name,
            freelancer_grade,
            client_comment,
            read,
            sender_type
        }, () => history.push('/messages')))
        setFormData({});
    };

    const handleCloseModal = () => {
        setFormData({ ...formData, ["client_comment"]: '' });
        setSelectedJob(null);
        handleClose();
    }


    return (
        <Modal show={show} Hirecheck={Hirecheck} size="md" className={'modal-invitation'} aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title>{!selectedJob || Hirecheck ? "Select Job" : "Invite for a job"}</Modal.Title>
                <Icon className="mx-2 icon-hover" icon={remove}  onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    {selectedJob && !Hirecheck? <>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-3 text-justify overflow-hidden">{selectedJob.description}</p>
                                {
                                    propCheck
                                        ?
                                        <p className="text-danger font-weight-bold">Proposal has already been submitted...!</p>
                                        :
                                        (
                                            <>
                                                <textarea className="form-control mb-5"
                                                    name="client_comment"
                                                    value={client_comment}
                                                    onChange={(e) => onChange(e)}
                                                    rows="10" placeholder="Add cover letter" disabled={ProposalCheckItemData.proposalSubmitted}></textarea>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                    </> : (
                        postJobData?.length == 0 ?
                                <div className="mt-5 border border-primary p-5 d-flex flex-column align-items-center">
                                    <h6 className="text-primary text-center mb-2">You have no jobs</h6>
                                    <Link to="/postjobclient" className="btn btn-sn btn-primary">Post Job</Link>
                                </div>

                            : (
                                postJobData?.filter(job => job.status === 'Approved').map((jobDetailData, index) => (
                                    <div className="card my-2 p-3" onClick={() => {setSelectedJob(jobDetailData); setPropCheck(false);}}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4>Description</h4>
                                                <p className="text-muted text-justify overflow-hidden">{jobDetailData.description}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6>Budget Type</h6>
                                                <p className="text-muted">{jobDetailData.payment_type_id == "659ea2be361d1c67ed1c1764" ? "Fixed" : "Hourly"}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <h6>Budget Amount</h6>
                                                <p className="text-muted">{jobDetailData.payment_amount}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    handleClose()
                    setSelectedJob(null)
                }}>
                    Cancel
                </Button>
                {postJobData?.length > 0 && <Button onClick={(e) => onSubmit(e)} disabled={!client_comment} variant="primary" >
                    Invite
                </Button>}
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProp = (state) => ({
    auth: state.auth,
    postJob: state.postJob,
    proposalAndContract: state.proposalAndContract
});

export default connect(mapStateToProp)(InvitationModal);