import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Bars } from "react-loader-spinner";
import Skeleton from "./../../components/skeleton";
import { adminGetUsers } from "../../actions/manageUsers";
import UserViewOffcanvas from "./UserViewOffcanvas";
import Input from '../../components/input/input';
import { Table, TextField } from "@material-ui/core";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { Icon } from "react-icons-kit";
import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";
import { VisibilityOff } from "@material-ui/icons";


const ManageUsers = ({
  auth: { isAuthenticated, user, token },
  users,
  usersCount,
}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedUserOption, setSelectedUserOption] = useState("All");
  const [viewingUser, setViewingUser] = useState({});
  const [showCanvas, setShowCanvas] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [hideBanUsers, setHideBanUsers] = useState(false);
  const [onlyBanUsers, setOnlyBanUsers] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [sortOption, setSortOption] = useState("alphabetical");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(12);
  const [showTable, setShowTable] = useState(true);

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  useEffect(() => {
    if (!token) {
      history.push("/login-register?role=client&type=login");
    }
    dispatch(adminGetUsers(selectedUserOption, currentPage, usersPerPage, searchQuery, onlyBanUsers ? true : hideBanUsers ? false : null));
  }, [dispatch, token, history, selectedUserOption, searchQuery, hideBanUsers, onlyBanUsers,]);


  useEffect(() => {
    setFilteredUsers(filterTheUsers());
  }, [searchQuery, users, hideBanUsers, onlyBanUsers, selectedUserOption, sortOption]);

  useEffect(() => {
    setSortedUsers(filteredUsers);
  }, [filteredUsers]);

  // useEffect(() => {
  //   console.log(showCanvas)
  // }, [showCanvas]);
  //console.log("usersss total", usersCount)
  const totalPages = Math.ceil(usersCount / usersPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);

    // Use a callback to ensure the state update has been processed before dispatching
    setTimeout(() => {
      dispatch(adminGetUsers(selectedUserOption, newPage, usersPerPage));
      //console.log(newPage);
    }, 0);
  };

  const filterTheUsers = () => {
    // const newUsers = users.filter((user) => {
    //   const formattedSearchQuery = searchQuery.toLowerCase().replace(/\s/g, '');
    //   const includesSearchQuery =
    //     user?.user_name?.toLowerCase()?.includes(formattedSearchQuery) ||
    //     user?.first_name?.toLowerCase()?.includes(formattedSearchQuery) ||
    //     user?.last_name?.toLowerCase()?.includes(formattedSearchQuery) ||
    //     (user?.first_name?.toLowerCase() + user?.last_name?.toLowerCase())?.includes(formattedSearchQuery) ||
    //     user?.email?.toLowerCase()?.includes(formattedSearchQuery) ||
    //     user?.type?.toLowerCase()?.includes(formattedSearchQuery) ||
    //     user?.details?.hourlyRate?.hourly_rate?.toLowerCase()?.includes(formattedSearchQuery) ||
    //     user?.details?.expertise?.category?.toLowerCase()?.includes(formattedSearchQuery);

    //   const isFreelancerAndExpert =
    //     formattedSearchQuery?.includes("exp") ||
    //     formattedSearchQuery?.includes("expe") ||
    //     formattedSearchQuery?.includes("exper") ||
    //     formattedSearchQuery?.includes("expert");

    //   const isBanned = user?.banned;

    //   if (hideBanUsers && isBanned) {
    //     return false;
    //   }

    //   if (onlyBanUsers && !isBanned) {
    //     return false;
    //   }

    //   if (selectedUserOption === "Freelancer" && user?.type?.toLowerCase() !== "freelancer") {
    //     return false;
    //   }

    //   if (selectedUserOption === "Client" && user?.type?.toLowerCase() !== "client") {
    //     return false;
    //   }

    //   return includesSearchQuery || (isFreelancerAndExpert && user?.type?.toLowerCase() === "freelancer");
    // });

    // Apply sorting based on the selected sort option
    const sorted = [...users];
    switch (sortOption) {
      case "alphabetical":
        sorted.sort((a, b) => a.first_name.localeCompare(b.first_name));
        break;
      case "newlyCreated":
        sorted.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;
      case "oldCreated":
        sorted.sort((a, b) => new Date(a.date) - new Date(b.date));
        break;
      default:
        break;
    }

    return sorted;
  };

  useEffect(() => {
    setFilteredUsers(filterTheUsers());
  }, [searchQuery, users]);

  useEffect(() => {
    setSortedUsers(filteredUsers); // Initialize sortedUsers with filteredUsers
  }, [filteredUsers]);

  const pageLoaded = true;

  const userOptions = [
    {
      label: "All",
      value: "all"
    },
    {
      label: "Clients",
      value: "Client"
    },
    {
      label: "Experts",
      value: "Freelancer"
    }
  ]

  const sortOptions = [
    {
      label: "By Alphabets",
      value: "alphabetical"
    },
    {
      label: "Recently Created",
      value: "newlyCreated"
    },
    {
      label: "Older Created",
      value: "oldCreated"
    }
  ]

  const onUserTypeChange = (e) => {
    setSelectedUserOption(e.target.value);
  };

  if (user.type !== "Admin") {
    return (
      <div className="main page-dashboard">
        <div className="container">
          <div className="panel-box">
            <div className="panel-box--body h-100">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h3>You are not allowed to visit this page!</h3>
                <Link className="btn" to={"/"} >Go to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  const userDetails = (data) => {
    setViewingUser(data);
    setShowCanvas(!showCanvas);
  };


  const options = [
    {
      name: 'Enable backdrop (default)',
      scroll: false,
      backdrop: true,
    },
    {
      name: 'Disable backdrop',
      scroll: false,
      backdrop: false,
    },
    {
      name: 'Enable body scrolling',
      scroll: true,
      backdrop: false,
    },
    {
      name: 'Enable both scrolling & backdrop',
      scroll: true,
      backdrop: true,
    },
  ];

  const handleOnCloseCanvas = () => {
    setShowCanvas(false);
    setViewingUser({});
  }


  const sortUsers = (e) => {
    const sortOption = e.target.value;
    setSortOption(sortOption);
  };

  const handleHideBanUsersChange = (e) => {
    setHideBanUsers(e.target.checked);
    if (onlyBanUsers) {
      setOnlyBanUsers(false);
    }
  };

  const handleOnlyBanUsersChange = (e) => {
    setOnlyBanUsers(e.target.checked);
    if (hideBanUsers) {
      setHideBanUsers(false);
    }
  };



  return (
    <>
      <UserViewOffcanvas handleClose={handleOnCloseCanvas} show={showCanvas} user={viewingUser} />
      <section className="main-page page-dashboard">
        <Helmet>
          <title>Manage Users</title>
        </Helmet>
        <div className="container-fluid">
          <div className="panel-box">
            <header className="panel-box--header d-flex justify-content-between align-items-center">
              <h1 className="panel-box--title">Manage Users</h1>
              <div className="d-flex">
                {/* Toggle button */}
                {/* <button
                  onClick={toggleTable}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                  // className="btn btn-primary"
                >
                  {showTable ? (
                    <>
                      <VisibilityOff style={{ marginRight: "8px" }} /> Hide Table
                    </>
                  ) : (
                    <>
                      <Table style={{ marginRight: "8px" }} /> Show Table
                    </>
                  )}
                </button> */}
                  
                   <div onClick={toggleTable} className="d-flex align-items-center border mr-2 justify-content-center" style={{ minWidth: '160px', borderRadius: '4px' }} >
                  <label htmlFor="TABLE" style={{ marginBottom: '0px', marginRight: '8px' }}>{showTable ? " Cards View" : "Table View"}</label>
                  {/* <input type="checkbox" id="hideBanUsers" checked={hideBanUsers} onChange={handleHideBanUsersChange} /> */}
                </div> 
                <div className="d-flex align-items-center border mr-2 justify-content-center" style={{ minWidth: '160px', borderRadius: '4px' }}>
                  <label htmlFor="hideBanUsers" style={{ marginBottom: '0px', marginRight: '8px' }}>Hide Ban Users</label>
                  <input type="checkbox" id="hideBanUsers" checked={hideBanUsers} onChange={handleHideBanUsersChange} />
                </div>
                <div className="d-flex align-items-center border mr-2 justify-content-center" style={{ minWidth: '160px', borderRadius: '4px' }}>
                  <label htmlFor="onlyBanUsers" style={{ marginBottom: '0px', marginRight: '8px' }}>Only Ban Users</label>
                  <input type="checkbox" id="onlyBanUsers" checked={onlyBanUsers} onChange={handleOnlyBanUsersChange} />
                </div>
                <select className="form-control"
                  style={{ minWidth: '120px' }}
                  name="userOptions"
                  onChange={(e) => onUserTypeChange(e)}
                >
                  {
                    userOptions.map(option => <option selected={option.value === "all" ? true : false} value={option.value} >{option.label}</option>)
                  }
                </select>
                <select className="form-control ml-3" style={{ minWidth: '180px' }} name="userOptions" onChange={(e) => sortUsers(e)}>
                  <option disabled selected>Sort Users</option>
                  {sortOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
                </select>
                <br></br>
              </div>
            </header>
            <div className="panel-box--header">
              <TextField
                placeholder="Search users by writing name, email, type, expertise, hours..."
                className="w-100"
                type="serach"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <main className="panel-box--body">
            {!showTable ?  <div className="row flex-wrap">
                {sortedUsers?.map((user, index) => {
                  return (
                    <>
                      <div className="col-lg-4 col-md-4 col-sm-6" style={{ position: 'relative' }} onClick={() => userDetails(user)}>
                        {user.banned &&
                          <div className="d-flex flex-column justify-content-center align-items-center" style={{ position: 'absolute', right: '0px', left: '0px', bottom: '0px', top: '0px', zIndex: '10' }}>
                            <img src="https://cdn-icons-png.flaticon.com/512/7596/7596657.png" height={100} />
                            <p style={{ color: '#f74354', fontSize: '28px', fontWeight: 'bold' }}>Banned</p>
                          </div>
                        }
                        <div className="bg-white rounded shadow-sm" style={{ opacity: user?.banned ? ".4" : '1' }}>
                          <div className="manage-user-card-outer">
                            <div className="user-profile-info">
                              <div className="clearfix">
                                {user.type === "Freelancer" ?
                                  <div className={`user-profileCompleted-indicator ${user?.details?.isCompleted ? "is-completed" : "not-completed"}`}
                                    style={{ color: user?.details?.isCompleted ? "green" : "#e31818" }}>
                                    {user?.details?.isCompleted ? "completed" : "in complete"}
                                  </div>
                                  :
                                  <div className={`user-profileCompleted-indicator ${user?.email? "is-completed" : "not-completed"}`}
                                    style={{ color: user?.email ? "green" : "#e31818" }}>
                                    {user?.email? "completed" : "in complete"}
                                  </div>
                                }
                                <div
                                  className="ambassadors-badge"
                                  style={{
                                    backgroundColor: !user?.type
                                      ? "transparent"
                                      : user.type === "Freelancer" ? "#40ADDB" : "#38c244",
                                  }}
                                >
                                  {!user?.type ? (
                                    <Skeleton width={100} height={25} />
                                  ) : user.type === "Freelancer" ? (
                                    "Expert"
                                  ) : (
                                    user.type
                                  )}
                                </div>
                              </div>
                              <div className="user-profile-image">
                                <label className="user-pict rounded-circle">
                                  <div to="" className="user-badge">
                                    {!user?.first_name ? (
                                      <Bars
                                        height="50"
                                        width="50"
                                        color="#ffffff"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          height: "100%",
                                        }}
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    ) : user?.details?.profilePhoto?.photo_link ? (
                                      <img
                                        src={user?.details?.profilePhoto?.photo_link}
                                        alt="Profile picture"
                                      />
                                    ) : (
                                      <h3 className="text-uppercase profile-avatar">
                                        {user.first_name.slice(0, 1)}
                                        {user.last_name.slice(0, 1)}
                                      </h3>
                                    )}
                                  </div>
                                </label>
                              </div>
                              <div className="user-profile-label">
                                <div className="username-line">
                                  <div to="" className="seller-link">
                                    {!user.first_name ? (
                                      <Skeleton width={150} height={25} />
                                    ) : (
                                      <>
                                        {user.first_name} {user.last_name}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="oneliner-wrapper">
                                  <small className="oneliner">
                                    {user?.expertise}
                                  </small>
                                </div>
                              </div>
                              <hr />
                              {
                                user?.type === "Freelancer" &&
                                <div className="d-flex justify-content-between align-items-center px-3" style={{ minHeight: "18px" }}>
                                  {user?.details?.expertise?.category && <p>{user?.details?.expertise?.category}</p>}
                                  {user?.details?.hourlyRate?.hourly_rate ? <p><strong>${user?.details?.hourlyRate?.hourly_rate}/hr</strong></p> : <p><strong>N/A</strong></p>}
                                </div>
                              }
                              {
                                user?.type === "Client" &&
                                <div className="d-flex justify-content-between align-items-center px-3" style={{ minHeight: "18px" }}>
                                  {<p>Experience</p>}
                                  {user?.details?.experience_level ? <p><strong>{user?.details?.experience_level}</strong></p> : <p><strong>N/A</strong></p>}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
              :
                <div id="orders" className="overflow-scroll">
                  {sortedUsers && sortedUsers.length > 0 ? (
                    <table className="table table-layout-fixed table-striped">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          {/**<th>Expertise</th> */}
                          <th>Status</th>
                          <th>Hourly Rate</th>
                          <th>Banned</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...sortedUsers].map((user, index) => (
                          <tr key={index} onClick={() => userDetails(user)}>
                            <td>
                              {user.first_name} {user.last_name}
                            </td>
                            <td>{user.type === "Freelancer" ? "Freelancer" : user.type}</td>

                            {/* Expertise 
                            <td>
                              {user.type === "Freelancer"
                                ? user?.expertise || "N/A"
                                : "N/A"}
                            </td>*/}

                            {/* Profile Completion Status */}
                            <td>
                              {user.type === "Freelancer" ? user?.details?.isCompleted  ? "Completed" : "Incomplete":
                              "Completed"}
                            </td>

                            {/* Hourly Rate for Freelancers */}
                            <td>
                              {user.type === "Freelancer" && user?.details?.hourlyRate?.hourly_rate
                                ? `$${user.details.hourlyRate.hourly_rate}/hr`
                                : "N/A"}
                            </td>

                            {/* Banned Status */}
                            <td>
                              {user.banned ? (
                                <img
                                  src="https://cdn-icons-png.flaticon.com/512/7596/7596657.png"
                                  height={25}
                                  alt="Banned"
                                />
                              ) : (
                                "Active"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="mt-5 p-5">
                      <div className="p-5">
                        <div className="message-not-available w-100">
                          <div className="text-center">
                            <img
                              src="assets/images/no-data-found.png"
                              width={"200px"}
                              alt="message"
                              className="mb-4"
                            />
                            <h1>Sorry! No Data Found</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              }


              {totalPages > 1 && (
                <nav>
                  <ul className="talent-pagination pagination justify-content-center">
                    <div
                      className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                      onClick={() =>
                        handlePageChange(currentPage === 1 ? 1 : currentPage - 1)
                      }
                    >
                      {/** <Icon icon={arrowLeft} />*/}
                      <PaginationArrowLeft />
                    </div>
                    {[...Array(totalPages)].map((_, index) => {
                      const pageNumber = index + 1;
                      return (
                        <li
                          key={pageNumber}
                          className={`page-item ${pageNumber === currentPage ? "active" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      );
                    })}
                    <div
                      className={`icon ${currentPage === totalPages ? "disabled" : ""
                        }`}
                      onClick={() =>
                        handlePageChange(
                          currentPage === totalPages ? totalPages : currentPage + 1
                        )
                      }
                    >
                      {/** <Icon icon={arrowRight} />*/}
                      <PaginationArrowRight />
                    </div>
                  </ul>
                </nav>
              )}
            </main>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.adminGetAllUser.users,
    usersCount: state.adminGetAllUser.usersCount,
  }
  // users: state.manageUsers.adminGetUsers
};
const mapDispatchToProps = (dispatch) => {
  return {
    adminGetUsers: () => dispatch(adminGetUsers("Client"))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
