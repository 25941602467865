import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, NavLink, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";

import Dropdown from "react-bootstrap/Dropdown";
import { SVGSearchMagnifine, SVGArrowDown } from "../SVG";
import Services from "../Services/services";
import Search from "./search";
import { skillsGet } from "../../actions/clientProfile";

import logo from "./cyper-logo.png";

import image1 from "./image-slide1.png";
import image2 from "./image-slide2.png";
import image3 from "./image-slide3.png";
import image4 from "./image-slide4.png";

import capsule from "./capsule.png";
import capsule1 from "./capsule2.png";
import classes from "./main-slider.module.css";
const sliderContent = [
  {
    //heading1: "Protect your digital world by verified & trustworthy",
    heading1: "",
    heading2: "Cybersecurity Professionals",
    heading2color: "#002D3F",

    heading3: "Search + Hire = ",
    heading4: "Work done!",
    bg: "#B4EDF5",
    capsule: { img: capsule, transform: "translate(-30%, 57%)" },
    image: image1,
    imagePos: { top: 0 },
  },
  {
    //heading1: "Post your project and get it done by a top-rated ",
    heading1: "",
    heading2: "Cybersecurity Client",
    heading2color: "#002D3F",
    heading3: "Best talent pool = ",
    heading4: "Best outcome",
    bg: "#F1F1F1",
    capsule: { img: capsule1, transform: "translate(-20%, 45%)" },
    image: image2,
    imagePos: { bottom: 0 },
  },
  {
    //heading1: "Want to offer your services? ",
    heading1: "",
    heading2: "Cybersecurity Expert",
    heading2color: "#004866",

    heading3: "Build your profile + Provide a service = ",
    heading4: "Make extra money",
    bg: "#C0DDFE",
    capsule: { img: capsule1, transform: "translate(-10%, 35%)" },
    image: image3,
    imagePos: { top: 0 },
    join: "Join as a ",
  },
  {
    //heading1: "Got a cybersecurity problem or an incident? ",
    heading1: "",
    heading2: "Request a Service and",
    heading2color: "#004866",

    //heading3: "Connect you with top cyber talent using our ",    
    heading3: "Connect with top cyber talent with ", 
    heading4: "AI-powered algorithm",
    bg: "#D8EEF5",
    capsule: { img: capsule1, transform: "translate(-10%, 35%)" },
    image: image4,
    imagePos: { top: 0 },
    join: "Join as a ",
  },
];

const MainSlider = ({
  auth: { isAuthenticated, user },
  clientProfile: { skills },
  skillsGet,
}) => {
  const [searchLocation, setSearchLocation] = useState("Talent");
  const history = useHistory();
  const [value, setValue] = useState("");
  const [indicator, setIndicator] = useState(true);

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setValue(e.target.innerText);
  };

  useEffect(() => {
    skillsGet();
  }, []);

  const Search_input = () => {
    localStorage.setItem("search", JSON.stringify(value));
    if (searchLocation === "Talent") {
      history.push(`/talent?value=${value}`);
    } else {
      history.push(`/job?value=${value}`);
    }
  };

  // ==============================
  const [active, setActive] = useState(0);

  const curr = sliderContent[active];
  function handleNextSlide(index) {
    setActive(index);
  }

  let btnSlider = sliderContent.map((_, index) => (
    <button
      style={{ background: index === active ? "red" : "" }}
      key={index}
      onClick={() => handleNextSlide(index)}
    ></button>
  ));

  useEffect(() => {
    const id = setTimeout(() => {
      if (active === 3) {
        setActive(0);
      } else {
        setActive((pre) => pre + 1);
      }
    }, [5000]);

    return () => clearTimeout(id);
  }, [active]);
  return (
    <section style={{ background: curr.bg }} className={`${classes.ourSlider}`}>
      <div className={classes.slideImages}>
        <div
          className={classes.capsule}
          style={{ transform: curr.capsule.transform }}
        >
          <img src={curr.capsule.img} alt="slide" />
        </div>

        <div key={active} className={classes.sliderImage} style={curr.imagePos}>
          <img src={curr.image} alt="slider" />
        </div>
      </div>

      <div className={`${classes.container}  main-slider`}>
        <div className={classes.info} style={{marginTop:"5px"}}>
          <div className={classes.logo}>
            {/** <img src={logo} alt="logo" />*/}
          </div>

          <div key={active} className={classes.headings}>
            <div>{curr.heading1}</div>
            
            <div style={{ color: curr.heading2color }}>
              <span className={classes.join}>{curr.join}</span>
              {curr.heading2}
            </div>
            <div>
              {curr.heading3}
              <b>{curr.heading4} </b>
            </div>
          </div>
          <div className={classes.search}>
            <div className="homepage-search-form">
              <form className="form-noborder">
                <div className="form-row row">
                  <div className="col-lg-3 col-md-3 col-xs-12">
                    <div className="search-location-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" variant="default">
                        <span style={{ fontSize: "14px", color: "#01131D" }}>
                          {searchLocation}
                        </span>{" "}
                        <SVGArrowDown />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <React.Fragment>
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => setSearchLocation("Talent")}
                          >
                            Talent
                          </Dropdown.Item>
                          {user.type === "Freelancer" || !isAuthenticated ? (
                            <Dropdown.Item
                              eventKey="2"
                              onClick={() => setSearchLocation("Job")}
                            >
                              Job
                            </Dropdown.Item>
                          ) : <Dropdown.Item
                          eventKey="2"
                          disabled
                          onClick={() => setSearchLocation("Job")}
                        >
                          Job
                        </Dropdown.Item>}
                        </React.Fragment>
                      </Dropdown.Menu>
                    </Dropdown>

                    </div>
                  </div>

                  <div className="col-lg-7 col-md-6 col-xs-12">
                    <div className="search-input">
                      <SVGSearchMagnifine />
                      <Search
                        suggestions={
                          skills.length > 0
                            ? skills.map((item) => item.skill_name)
                            : []
                        }
                        setInput={setValue}
                        setIndicator={setIndicator}
                        pre_value={value}
                        onEnterPress={Search_input}
                      />
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-3 col-xs-12">
                    <div className="search-btn">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary p-0"
                        style={{
                          backgroundColor: "#01131d",
                          color: "white",
                        }}
                        onClick={Search_input}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="popular">
              <span
                className="popular-heading"
                style={{ color: "#01131d", fontSize: "1rem" }}
              >
                Popular:
              </span>
              <ul>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Network Security
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Cyber Risk Management
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Malware Protection
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Data Security
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLinkClick}>
                    Penetration Testing
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.groupBtn}>{btnSlider}</div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  clientProfile: state.clientProfile,
});

export default connect(mapStateToProps, { skillsGet })(MainSlider);
