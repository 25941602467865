import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { setAlert } from "../../actions/alert";
import { useDispatch } from "react-redux";
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import Logo from '../../components/logo/Logo';
import useSweetAlert from '../../hooks/useSweetAlert';

const Question9 = () => {
  const { formType } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useSweetAlert();


  // Define the storage key based on the form type
  const storageKey = `answers9-${formType}`;

  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem(storageKey);
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const scrollToField = (fieldId) => {
    const element = document.getElementById(fieldId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    // Store answers with the appropriate storage key
    localStorage.setItem(storageKey, JSON.stringify(answers));
  }, [answers, storageKey]); // Include storageKey in the dependency array


  useEffect(() => {
    // Retrieve answers with the appropriate storage key
    const savedAnswers = localStorage.getItem(storageKey);
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, [storageKey]); // Include storageKey in the dependency array

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const unansweredQuestions = questions[formType].filter(question => !answers.hasOwnProperty(question.id) || answers[question.id].trim() === '');

    if (unansweredQuestions.length === 0) {
      history.push(`/question10/${formType}`);
    } else {
      // showToast('Please fill all the required fields!', 'error');
      unansweredQuestions.forEach(question => scrollToField(`question_${question.id}`));
      const errorMessage = unansweredQuestions.map(question => {
        const fieldName = question.question;
        return `${fieldName} is required.`;
      }).join(' ');

      //dispatch(setAlert(errorMessage, 'danger'));
    }
  };


  const placeholders = {
    '9.1': {
      individual: "Specific Tools, Software, or Technologies",
      company: "Additional Services or Support"
    },
    '9.2': {
      individual: "Specific Preferences or Requirements regarding the Approach or Methodology",
      company: "Specific Expectations or Requirements for Ongoing Collaboration"
    }
  };

  const questions = {
    individual: [
      { id: 9.1, question: "Are there any specific tools, software, or technologies that you expect the cybersecurity expert to use or be familiar with?" },
      { id: 9.2, question: "Do you have any specific preferences or requirements regarding the cybersecurity expert's approach or methodology?" }
    ],
    company: [
      { id: 9.1, question: "Are there any additional services or support that you require from cybersecurity experts, such as training, consulting, or ongoing support?" },
      { id: 9.2, question: "Do you have any specific expectations or requirements for ongoing collaboration with cybersecurity experts?" }
    ]
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };


  const currentQuestions = questions[formType] || [];

  return (
    <section className="main-page page-dashboard" style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      height: '100vh', backgroundColor: '#f4f4f4', maxWidth: "100vw", overflowX: "hidden"
    }}>
      <div className="container" style={{ maxWidth: '1200px' }}>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className='w-100 row row justify-content-center d-flex d-sm-none'>


              <Logo />
            </div>
            <div className="bg-white rounded shadow-sm sidebar-page-right" style={{ padding: '2rem' }}>
              <Link to={`/question8/${formType}`} className=" text-dark d-flex mb-2"
                style={{ alignItems: "center", gap: "3px" }}
              >
                <KeyboardBackspaceOutlined />  Back

              </Link>
              <div className="p-3">
                <h4> {formType === 'individual' ? 'Individual' : 'Company'}</h4>
                <h3 className="text-primary">{formType === 'individual' ? 'Additional Requirements' : 'Additional Services'}</h3>
                <p >
                  <span /*style={{ textDecoration: "underline" }}*/ className="font-weight-bold">09</span> of 10
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="scrollable">
                    {currentQuestions.map((question, index) => (
                      <div key={index} id={`question_${question.id}`}>
                        <h6 className="font-weight-normal mt-4">{question.question}</h6>
                        {question.options ? (
                          <div className="form-group">
                            {question.options.map((option, optionIndex) => (
                              <div key={optionIndex}
                                onClick={() => handleAnswerChange(option)}
                                className={`option-item ${answers[question.id] === option ? 'selected' : ''}`}
                                style={{ marginBottom: "10px", cursor: "pointer", padding: "10px", border: "1px solid #ccc", borderRadius: "5px", backgroundColor: answers[question.id] === option ? '#007bff' : 'transparent', color: answers[question.id] === option ? 'white' : 'black' }}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="form-group" style={{ marginBottom: "30px" }} >
                            <input type="text" className="form-control" placeholder={"Enter " + placeholders[question.id][formType]} value={answers[question.id] || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                                handleAnswerChange(question.id, capitalizedValue);
                              }} />
                            { // Add conditional rendering for error message
                              formSubmitted && !answers[question.id]?.trim() && (
                                <div className="text-danger mt-2">
                                  {question.question} is required.
                                  {/* Please fill the required field. */}
                                </div>
                              )
                            }
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {/**<div className="d-flex justify-content-end pb-3">
                    <div className="text-right">
                      <Link to={`/question8/${formType}`} className="btn btn-default mr-3">Back</Link>
                      <button type="submit" className="btn btn-primary">Save & Next</button>
                    </div>
                  </div>
                   */}
                  <div className='w-100 bg-white d-flex justify-content-end' style={{}}>
                    <button type="submit" className="btn btn-primary wm-100 mr-2" style={{ width: '95% !important' }}>
                      Save & Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question9;


