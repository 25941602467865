import React, { useEffect, useState } from "react";
import { Recomendations, getAllCompany, getAllIndividual } from "../../actions/enterprise";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import DisputeOffcanvas from "./enterprisecanvas";
import { Icon } from "react-icons-kit";
import { arrowLeft, arrowRight } from "react-icons-kit/fa";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { PaginationArrowRight } from "../../components/SVG";
import { PaginationArrowLeft } from "../../components/SVG";
import image from "./default_Avatar.jpg"

const Enterprise = ({ auth: { isAuthenticated, user } , enterprise: { companies, individuals } }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [combinedData, setCombinedData] = useState([]);

  let history = useHistory();

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCompany());
    dispatch(getAllIndividual(setLoading));
  }, [dispatch]);

  useEffect(() => {
    if (companies && individuals) {
      const combined = [...companies, ...individuals];
      combined.sort((a, b) => {
        const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0); // Date(0) for items without updatedAt
        const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
        return dateB - dateA;
      });
      setCombinedData(combined);
      //console.log("Sorted data: ", combined);

    }
    

  }, [companies, individuals]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);

  //   // Clean up the timer if the component unmounts or if the timer changes
  //   return () => clearTimeout(timer);
  // }, []);

  

  const [currentPage, setCurrentPage] = useState(1);
  const [compAndIndPerPage] = useState(5);

  const [showDisputeOffcanvas, setShowDisputeOffcanvas] = useState(false);
  const [currentDispute, setCurrentDispute] = useState(null);

  const indexOfLastDispute = currentPage * compAndIndPerPage;
  const indexOfFirstDispute = indexOfLastDispute - compAndIndPerPage;
  const currentDisputes = combinedData.slice(indexOfFirstDispute, indexOfLastDispute);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(combinedData.length / compAndIndPerPage);

  const handleResolveClick = (dispute) => {
    setCurrentDispute(dispute);
    setShowDisputeOffcanvas(true);
  };

  // if(!isAuthenticated){
  //   history.push("/login-register?role=client&type=login");
  // }



  return (
    <div className="main-page page-dashboard page-talent">
      <Helmet>
        <title>Enterprise</title>
      </Helmet>
      <div className="container-fluid">
        <section className="talent-list">
          {loading ? 
            <div className="message-not-available w-100">
            {[...Array(3)].map((_, index) => (
              <div className="freelancer-list talent-block w-100">
              <div className="row" style={{ display: 'flex', flexWrap: 'wrap', marginRight: '0%', marginLeft: '-4%' }}>
                <div className="col-md-1 mt-0"> 
                  {/* <Skeleton circle={true} height={50} width={50} /> */}
                </div>
                <div className="col-md-7 mt-0"> 
          <div style={{ display: "flex", alignItems: "center" }}>
            <Skeleton width={150} height={30} />
          </div>
          <p style={{ textAlign: 'left' }}><Skeleton width={150} /></p>
          <span className="badge bg-secondary mr-2 my-1" style={{ display: 'inline-block', textAlign: 'left' }}>
            <Skeleton width={80} />
          </span>
          <p className="my-1" style={{ textAlign: 'left' }}><Skeleton width={`50%`} /></p>
          
        </div>
                <div className="col-md-3 mt-0 button-group">
                  <Skeleton width={100} height={40} />
                </div>
              </div>
            </div>
            ))}
          </div>
          :
        
          currentDisputes.length > 0 ? (
            currentDisputes.map((dispute) => (
              <div key={dispute._id} className="freelancer-list talent-block">
            <div className="row align-items-center">
              <div
                className="col-md-3 pr-md-0 col-lg-2 mt-0" //col-md-3 col-lg-2 mt-0
              >
                <div
                  className="rounded-circle border border-dark overflow-hidden"
                  style={{
                    height: "135px",
                    width: "135px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    className="img-fluid"
                    src={dispute?.answers[0]["answers1-company"]?.["1_4"]? dispute?.answers[0]["answers1-company"]?.["1_4"]: image}
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                    alt={dispute?.answers[0]["answers1-company"]?.["1_1"]}
                  />
                </div>
              </div>

              <div className="col-md-7 px-1 pl-md-0 mt-0">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: dispute?.verification === true ? "-20px" : "-5px"
                  }}
                  className="profile-display mt-3 mt-md-0"
                >
                  <div className="d-flex profile-display">
                    <h3 className="mb-0">
                      {`Name: ${
                        dispute?.answers[0]["answers1-company"]?.["1_1"] ||
                        dispute?.answers[0]["answers1-individual"]?.["1_1"] ||
                        "N/A"
                      }`}
                    </h3>
                  </div>
                </div>
                
                <div className="tags-row mb-0">
                <span className="badge">
                  {`Email: ${
                    dispute?.answers[0]["answers10-company"]?.["10_2"] ||
                    dispute?.answers[0]["answers10-individual"]?.["10_2"] ||
                    "N/A"
                  }`}
                </span>
                </div>
                <p className="description">
                  Type: {dispute?.answers[0]["answers1-company"] ? "company" : "individual"}
                </p>

                
              </div>

              <div className="col-md-2 col-lg-3 mt-0 button-group d-flex flex-column justify-content-center align-items-end">
                <button
                  className="btn text-nowrap btn-default btn-sn mt-3 mt-md-auto col-sm-12 col-md-auto mb-0 direct-hire-button"
                  onClick={() => handleResolveClick(dispute)}
                >
                  Open
                </button>
              </div>

            </div>
          </div>

            ))
          ) : (
            <div className="p-5">
              <div className="p-5">
                <div className="message-not-available w-100">
                  <div className="text-center">
                    <img
                      src="assets/images/no-data-found.png"
                      width={"200px"}
                      alt="message"
                      className="mb-4"
                    />
                    <h1>Sorry! No Data Found</h1>
                    <p className="land">we couldn't find any disputes.</p>
                  </div>
                </div>
              </div>
            </div>
          )
          }
        </section>
        {totalPages > 1 && (
          <nav>
            <ul className="talent-pagination pagination justify-content-center">
              <div
                className={`icon ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
              >
                {/** <Icon icon={arrowLeft} />*/}
                <PaginationArrowLeft/>
              </div>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
                  >
                    <button className="page-link" onClick={() => setCurrentPage(pageNumber)}>
                      {pageNumber}
                    </button>
                  </li>
                );
              })}
              <div
                className={`icon ${currentPage === totalPages ? "disabled" : ""}`}
                onClick={() =>
                  setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1)
                }
              >
                {/** <Icon icon={arrowRight} />*/}
                <PaginationArrowRight/>
              </div>
            </ul>
          </nav>
        )}
      </div>
      <DisputeOffcanvas
        show={showDisputeOffcanvas}
        onHide={() => setShowDisputeOffcanvas(false)}
        enterpriseData={currentDispute}
        enterprise={{ recomendations: [] }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  enterprise: state.enterprise,
  auth: state.auth,
});

export default connect(mapStateToProps)(Enterprise);